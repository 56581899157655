//#region imports
import React, { FC, forwardRef, useEffect, useCallback } from 'react';
import { useFirstMountState, useGetSet, useUnmount, useIsomorphicLayoutEffect } from 'react-use';
import { useVirtual } from 'react-virtual';
import { useForkRef } from '@material-ui/core';
import { isMobile } from 'mobile-device-detect';
import classnames from 'classnames';
import isFunction from 'lodash/isFunction';
import isElement from 'lodash/isElement';
import isFinite from 'lodash/isFinite';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import reverse from 'lodash/reverse';
import isNaN from 'lodash/isNaN';
// import range from 'lodash/range';
// import noop from 'lodash/noop';
import map from 'lodash/map';

import { VirtualListProps, EVirtualListClassKey, IVirtualDotsProps } from './virtual.model';
import { stylesVirtualList } from './virtual.styles';
import VirtualAutoSize from './virtual.autosize';
//#endregion

//#region VirtualHorizontalList
const VirtualHorizontalList: FC<VirtualListProps<HTMLDivElement>> = forwardRef<HTMLDivElement, VirtualListProps<HTMLDivElement>>(
  (props, ref) => {
    const {
      className,
      classes,
      style,
      parentRef,
      size,
      width,
      height,
      spacing,
      overscan,
      estimateWidth,
      paddingStart,
      paddingEnd,
      fallbackHeight,
      autoHeight,
      infinite,
      loading,
      onLoad,
      renderDots: DisplayDots,
      renderItem: DisplayItem,
      renderFallback: DisplayFallback
    } = props;
    const vsize = infinite ? size + 1 : size;
    const isFirstMount = useFirstMountState();
    const styles = stylesVirtualList({ classes });

    const handleRef = useForkRef(parentRef, ref);

    const virtualizer = useVirtual({
      horizontal: true,
      parentRef,
      size: vsize,
      estimateSize: useCallback(
        index => {
          const w = isFunction(estimateWidth) ? estimateWidth(index) : width;
          return index === vsize - 1 ? w : w + spacing;
        },
        [vsize, width, spacing]
      ),
      paddingStart,
      paddingEnd,
      overscan
    });

    const [dragged, setDragged] = useGetSet(false);
    const [dynamicHeight, setDynamicHeight] = useGetSet(0);
    const [dots, setDots] = useGetSet<Pick<IVirtualDotsProps, 'length' | 'index'>>({ length: 0, index: -1 });

    const applyDynamicHeight = useCallback(
      // debounce(h => setDynamicHeight(h), 100),
      throttle((h: number) => setDynamicHeight(h), 100),
      []
    );
    const handleDynamicHeight = useCallback((h: number) => {
      if (h > dynamicHeight()) applyDynamicHeight(h);
    }, []);
    // const dynamicHeightRef = useCallback((element: HTMLElement) => {
    //   const h = element?.firstElementChild?.clientHeight ?? element?.firstElementChild?.scrollHeight ?? element?.scrollHeight;
    //   if (h > dynamicHeight()) applyDynamicHeight(h);
    // }, []);

    useEffect(() => {
      if (!infinite || loading || isFirstMount || !isFunction(onLoad)) return;

      const [lastItem] = reverse(virtualizer.virtualItems);
      if (lastItem && lastItem.index === vsize - 1) onLoad({ detail: lastItem.index });
    }, [infinite, loading, isFirstMount, virtualizer.virtualItems, vsize, onLoad]);

    useIsomorphicLayoutEffect(() => {
      const rootElement = parentRef?.current;
      if (isElement(rootElement) && !isMobile) {
        let isDragging = false;
        let startX;
        let scrollLeft;

        const handleMouseDown = debounce(e => {
          setDragged(true);
          startX = e.pageX - rootElement.offsetLeft;
          scrollLeft = rootElement.scrollLeft;
        }, 100);

        const handleMouseUp = debounce(e => {
          isDragging = false;
          setDragged(false);
        }, 100);

        const handleMouseLeave = e => {
          isDragging = false;
          setDragged(false);
        };

        const handleMouseMove = debounce(e => {
          if (!dragged()) return;
          e.preventDefault();
          const x = e.pageX - rootElement.offsetLeft;
          const step = (x - startX) * 0.67;
          rootElement.scrollLeft = scrollLeft - step;
          if (!isDragging) isDragging = true;
        }, 0);

        const handleCaptureClick = e => {
          if (isDragging) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            return false;
          }
        };

        rootElement.addEventListener('mousedown', handleMouseDown);
        // rootElement.addEventListener('mouseleave', handleMouseLeave);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);
        rootElement.addEventListener('click', handleCaptureClick, true);

        return () => {
          handleMouseDown?.cancel();
          handleMouseUp?.cancel();
          handleMouseMove?.cancel();

          rootElement.removeEventListener('mousedown', handleMouseDown);
          // rootElement.removeEventListener('mouseleave', handleMouseLeave);
          document.removeEventListener('mouseup', handleMouseUp);
          document.removeEventListener('mousemove', handleMouseMove);
          rootElement.removeEventListener('click', handleCaptureClick, true);
        };
      }
    }, [parentRef?.current, isMobile]);

    useIsomorphicLayoutEffect(() => {
      const rootElement = parentRef?.current;
      if (isElement(rootElement)) {
        const computeDots = () => {
          const rootWidth = rootElement?.clientWidth || rootElement?.offsetWidth;
          const rootScrollX = rootElement?.scrollLeft;
          const rootScrollWidth = rootElement?.scrollWidth;

          const ratioScroll = rootScrollX / rootScrollWidth;
          const ratioWidth = rootWidth / (width + spacing);
          const length = Math.round(ratioWidth);
          const ratioDot = 1 / ratioWidth;
          const ratioSize = ratioDot * size;
          const ratioLength = ratioDot * length;

          const div = size / length;
          const amplitude = (rootScrollX + spacing) / ((width + spacing) * length) + 1;

          let nDots = Math.ceil(div);
          let iCurrentDot = -1;
          if (isNaN(nDots) || !isFinite(nDots)) nDots = 0;
          if (rootScrollX + spacing + rootWidth >= rootScrollWidth) iCurrentDot = nDots - 1;
          else if (nDots === 2 && div < 2) iCurrentDot = amplitude >= ratioDot * ratioWidth + ratioDot ? 1 : 0;
          else iCurrentDot = Math.round(amplitude - 1);

          setDots({ length: nDots, index: iCurrentDot });
        };
        const handleScroll = debounce(computeDots, 100);
        const handleResize = throttle(computeDots, 100);

        computeDots();
        rootElement.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
          handleScroll?.cancel();
          handleResize?.cancel();

          rootElement.removeEventListener('scroll', handleScroll);
          window.removeEventListener('resize', handleResize);
        };
      }
    }, [parentRef?.current, size, width, spacing]);

    useUnmount(() => applyDynamicHeight.cancel());

    const handleDragCancel = useCallback(e => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }, []);

    const computeHeight = () => {
      const vheight = autoHeight ? dynamicHeight() : height;
      if (fallbackHeight !== undefined && vheight < fallbackHeight) return fallbackHeight;
      return vheight;
    };

    const handleSetCurrentDot = (activeDot: number) => {
      setDots({ ...dots(), index: activeDot });
      const rootElement = parentRef?.current;
      rootElement.scrollLeft = Math.round(size / dots().length) * activeDot * (width + spacing);
    };

    return (
      <>
        <div
          ref={ handleRef }
          className={ classnames(className, styles[EVirtualListClassKey.root], { [styles[EVirtualListClassKey.dragged]]: dragged() }) }
          style={ { width: `100%`, height: fallbackHeight ?? computeHeight() , overflowX: 'auto', ...style } }
        >
          <div
            className={ styles[EVirtualListClassKey.container] }
            style={ { position: 'relative', width: virtualizer.totalSize, height: '100%' } }
            draggable={ false }
            onDragStartCapture={ handleDragCancel }
          >
            { map(virtualizer.virtualItems, virtualItem => {
              const isLastItem = virtualItem.index === vsize - 1;
              const isVirtualFallback = infinite && isLastItem;
              const vkey = !isVirtualFallback ? virtualItem.index : 'fallback';
              // const vref = !isVirtualFallback && autoHeight ? dynamicHeightRef : noop;
              const vwidth = isLastItem ? virtualItem.size : virtualItem.size - spacing;
              return (
                <div
                  key={ vkey }
                  // ref={vref}
                  className={ styles[EVirtualListClassKey.item] }
                  style={ {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: vwidth,
                    transform: `translateX(${virtualItem.start}px)`
                  } }
                  draggable={ false }
                  onDragStartCapture={ handleDragCancel }
                >
                  { isVirtualFallback ? (
                    <DisplayFallback width={ vwidth } height={ computeHeight() } />
                  ) : (
                    <VirtualAutoSize handleWidth={ false } handleHeight={ autoHeight } onResize={ (w, h) => handleDynamicHeight(h) }>
                      <DisplayItem index={ virtualItem.index } width={ vwidth } />
                    </VirtualAutoSize>
                  ) }
                </div>
              );
            }) }
          </div>
        </div>

        { DisplayDots && <DisplayDots { ...dots() } setCurrentDot={ handleSetCurrentDot } /> }
      </>
    );
  }
);
VirtualHorizontalList.displayName = 'VirtualHorizontalList';

VirtualHorizontalList.defaultProps = { width: 0, height: 0, spacing: 8, overscan: 1, autoHeight: false, infinite: false, loading: false };
//#endregion

export default VirtualHorizontalList;
