import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

interface IProps {
  isMobile?: boolean;
}

export const useStylesModalIndividualProgram = makeStyles<{}, IProps>((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minWidth: 300,
      transform: 'translateZ(0)'
    },
    autocomplete: {
      '& .MuiFilledInput-root': {
        backgroundColor: configTheme.colors.background.light.default,
        borderRadius: '8px',
        border: '1px solid #D5D8DD'
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:hover:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none'
      }
    },
    paper: {
      width: ({ isMobile }) => isMobile ? 335 : 373,
      maxHeight: '100vh',
      backgroundColor: configTheme.colors.background.light.default,
      boxSizing: 'border-box',
      borderRadius: 5
    },
    container: {
      padding: ({ isMobile }) => isMobile ? '0 20px' : '0 30px',
      marginTop: 10,
      fontSize: ({ isMobile }) => isMobile ? 14 : 17,
      color: configTheme.colors.text.dark.default
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: ({ isMobile }) => isMobile ? '20px 20px 15px 20px' : '23px 30px 30px 30px'
    },
    title: {
      fontSize: 24,
      lineHeight: ({ isMobile }) => isMobile ? '17px' : '30px',
      fontFamily: configTheme.fontFamily.PFBeauSansProSemiBold,
      borderRadius: '5px 5px 0 0',
      color: configTheme.colors.basic.primary
    },
    resultDesc: {
      margin: ({ isMobile }) => isMobile ? '15px 0' : '25px 0'
    },
    weightContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: ({ isMobile }) => isMobile ? 20 : 30
    },
    weightDesc: {
      maxWidth: 188,
      marginRight: 15,
      fontSize: 14,
      '& strong': {
        fontFamily: configTheme.fontFamily.PFBeauSansProRegular
      }
    },
    weightInput: {
      width: 90,
      '& .MuiFormHelperText-root.Mui-error': {
        position: 'absolute',
        top: 50,
        right: ({ isMobile }) => isMobile ? -15 : 4
      }
    },
    buttons: {
      height: ({ isMobile }) => isMobile ? 85 : 105,
      margin: ({ isMobile }) => isMobile ? '30px 0 40px 0' : '0 0 50px 0',
      fontSize: ({ isMobile }) => isMobile ? 16 : 17,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    button: {
      padding: 0
    },
    imgClose: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    telNumber: {
      fontWeight: 'bold'
    },
    btnBlock: {
      marginTop: 36
    },
    btnLinkCancel: {
      color: 'inherit',
      textDecoration: 'none'
    },
    btnCancel: {
      backgroundColor: 'inherit',
      width: 183,
      height: 60,
      color: 'inherit',
      fontFamily: configTheme.fontFamily.PFBeauSansProRegular,
      fontWeight: 'bold'
    },
    btnSubscribe: {
      marginRight: 36,
      width: 183,
      height: 60
    },
    descBlock: {
      marginTop: 36,
      color: '#8A8F97'
    },
    linkUserAgreements: {
      color: '#8A8F97'
    }
  })
);
