//#region imports
import React, { FC, memo, useRef, useMemo, useCallback } from 'react';
import { useIsomorphicLayoutEffect, useGetSet } from 'react-use';
import MuiAudioPlayer from 'material-ui-audio-player';
import { MdPlayArrow, MdPause } from 'react-icons/md';
import { isIOS } from 'mobile-device-detect';
import classnames from 'classnames';

import isElement from 'lodash/isElement';
import isFunction from 'lodash/isFunction';
import uniqueId from 'lodash/uniqueId';

import { Typography } from 'a1s-zet-react-ui/src/components/shared/typography/typography.index';

import { MediaReadyState } from 'app/models/model.media';
import { AudioPlayerProps } from './audio-player.models';
import IconCancel from 'app/assets/img/cancel.svg';

import { useAudioPlayerStyles } from './audio-player.styles';
import stylesEllipsis, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';
//#endregion

const AudioPlayer: FC<AudioPlayerProps> = memo<AudioPlayerProps>(props => {
  const { className, style, classes, id, src, icons, preload, autoplay, title, withClose, onClose } = props;
  const playerRef = useRef<HTMLDivElement>(null);

  const srcSet = useMemo(() => [src], [src]);
  const [isAutoplayComplete, setIsAutoplayComplete] = useGetSet(false);
  const [isPlaying, setIsPlaying] = useGetSet(false);
  const [haveEnoughData, setHaveEnoughData] = useGetSet(false);
  const useStyles = useCallback(() => useAudioPlayerStyles({
    classes,
    haveEnoughData: haveEnoughData()
  }), [classes, haveEnoughData()]);
  const ellipsisStyles = stylesEllipsis({});
  const [key, setKey] = useGetSet(uniqueId('audio.player.'));

  useIsomorphicLayoutEffect(() => {
    if (isElement(playerRef.current)) {
      const audioElement: HTMLAudioElement = playerRef.current?.querySelector('audio');
      if (isElement(audioElement)) {
        setKey(uniqueId('audio.player.'));
        if (autoplay && !isAutoplayComplete()) {
          audioElement.volume = 0;
          audioElement.play();
        }

        let tid;
        const handleCanPlay = e => {
          if (tid) clearTimeout(tid);
          const target: HTMLAudioElement = e?.target;
          (function checkUpEnoughData() {
            tid = setTimeout(() => {
              if (
                  ((isIOS && target?.readyState !== MediaReadyState.HAVE_NOTHING) ||
                      target?.readyState === MediaReadyState.HAVE_ENOUGH_DATA) &&
                  target?.duration > 0
              ) {
                if (!haveEnoughData()) setHaveEnoughData(true);
                tid = setTimeout(async () => {
                  if (autoplay && !isAutoplayComplete()) {
                    try {
                      if (isIOS) {
                        audioElement.volume = 0;
                        await audioElement.pause();
                      } else {
                        setIsAutoplayComplete(true);
                        await audioElement.play();
                        audioElement.volume = 1;
                      }
                      setIsAutoplayComplete(true);
                    } catch {}
                  }
                }, 300);
                return;
              }
              checkUpEnoughData();
            }, 300);
          })();
        };
        audioElement.addEventListener('progress', handleCanPlay);
        return () => {
          if (tid) clearTimeout(tid);
          audioElement.removeEventListener('progress', handleCanPlay);
        };
      }
    }
  }, [srcSet, autoplay]);

  return (
    <div ref={ playerRef } className={ className } style={ style } id={ id }>
      { title &&
        <Typography type="text" className={ classnames(useStyles().title, ellipsisStyles[EEllipsisClassKey.line]) } theme="dark" tag="p">
          { title }
        </Typography>
      }
      { withClose && <img alt="icon-cancel" src={ IconCancel } onClick={ () => isFunction(onClose) && onClose() } className={ useStyles().iconClose }/> }
      <MuiAudioPlayer
        useStyles={ useStyles }
        icons={ icons ?? { PlayIcon: MdPlayArrow, PauseIcon: MdPause } }
        variation="default"
        order="reverse"
        preload={ preload as any }
        autoplay={ isIOS ? false : autoplay }
        displaySlider
        download={ false }
        loop={ false }
        src={ srcSet }
        onPlayed={ e => setIsPlaying(true) }
        onPaused={ e => setIsPlaying(false) }
      />
    </div>
  );
});

export default AudioPlayer;
