import { createStyles, makeStyles } from '@material-ui/core/styles';
import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

interface IProps {
  isMobile?: boolean;
  isNotDesktop?: boolean;
}

export default makeStyles<{}, IProps>(
  createStyles({
    root: {
      minHeight: ({ isMobile }) => isMobile ? 'calc(100vh - 315px)' : 'unset'
    },
    item: {
      '&:last-child': {
        marginBottom: 0,
        marginTop: 0
      },
      marginTop: 36,
      marginBottom: 28
    },
    lower: { marginTop: 'auto' },
    exitButton: {
      cursor: 'pointer',
      padding: '0 70px',
      marginBottom: 40,
      fontSize: ({ isMobile }) => isMobile ? 16 : 17
    },

    buttonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },

    header: {
      margin: ({ isMobile }) => isMobile ? '30px 0 15px 0' : '50px 0 30px 0',
      fontFamily: configTheme.fontFamily.PFBeauSansProSemiBold,
      fontSize: 24,
      fontWeight: 600,
      color: configTheme.colors.text.dark.default
    },

    data: {
      display: 'flex',
      flexFlow: 'row nowrap',
      fontSize: ({ isMobile }) => isMobile ? 14 : 17,
      color: configTheme.colors.text.dark.default,
      lineHeight: '22px',
      '& span': {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '22px'
      },
      '& b': {
        fontWeight: 'normal'
      }
    },

    prizeItem: {},

    mediaPlayIcon: {
      width: 45,
      height: 50,
      paddingLeft: 5,
      borderRadius: 50,
      fontSize: 22,
      lineHeight: '60px',
      textAlign: 'center',
      color: configTheme.colors.text.dark.default,
      background: configTheme.colors.background.light.neutral
    },

    primarySection: {
      marginBottom: ({ isMobile }) => isMobile ? 35 : 15
    },
    secondarySection: {},
    floorSection: {
      display: 'flex',
      justifyContent: 'center'
    },

    parametersWrapper: {
      margin: ({ isMobile }) => isMobile ? 0 : '0 0 30px 0'
    },
    parametersTable: {
      padding: 0
    },
    parametersBtn: {
      margin: ({ isMobile }) => isMobile ? '30px auto 0 auto' : '30px 0 0 0'
    },

    subscriptions: {
      paddingBottom: ({ isMobile }) => isMobile ? 45 : 70
    },
    subscriptionMobile: {
      '&:not(:last-child)': {
        marginBottom: 30
      }
    },
    subscriptionContainer: {
      margin: '0 auto'
    },
    subscriptionDesktop: {
      width: 392,
      backgroundColor: configTheme.colors.background.light.default
    }

  }), { name: 'Profile' }
);
