//#region imports
import React, { FC, memo, forwardRef, useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useHistory } from 'react-router';

import includes from 'lodash/includes';
import toString from 'lodash/toString';
import isArray from 'lodash/isArray';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import every from 'lodash/every';
import some from 'lodash/some';
import pick from 'lodash/pick';
import flow from 'lodash/flow';
import each from 'lodash/each';
import find from 'lodash/find';
import map from 'lodash/map';

import ListHorizontal from 'a1s-omobile-react-ui/src/components/shared/list/horizontal/list.horizontal.index';

import { ELoadStatus } from 'app/models/shared.model';
import { ESubcategoryLayout } from 'app/models/model.subcategory';
import { ESectionLayout } from 'app/models/model.section';
import { ECategoryClassKey, CategoryProps, ConnectedCategoryProps } from './category.models';

import { ISectionListInitialState } from 'app/entities/section/entity.section.list.reducer';
import { IContentListMainInitialState } from 'app/entities/content/content.list.main.reducer';

import { SectionListContainer } from 'app/containers/section.list.container';
import { ContainerContentListMain } from 'app/containers/container.content.list.main';

import Wrapper from 'app/components/wrapper/wrapper.index';
import useLimiter from 'app/hooks/useLimiter';
import { stylesCategory } from './category.styles';
import { CategoryBlocks } from './category.blocks';
import { CategoryMenu, useCategoryMenuAgent } from './category.menu';
import Headline from '../headline/headline.index';
import { CONTENT_LIST } from 'app/configs/route.names';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

//#region Category
export const Category: FC<CategoryProps> = memo(
  forwardRef<HTMLDivElement, CategoryProps>((props, ref) => {
    const {
      className,
      style,
      data,
      sections,
      contents,
      loading,
      uAgeType,
      onConfirm,
      onSectionsChangeBy
    } = props;
    const { isNotDesktop } = useBreakpoints();
    const styles = stylesCategory({ isNotDesktop });
    const { t } = useTranslation();
    const history = useHistory();
    const { isMobile } = useBreakpoints();

    const isSubcategoriesExist = isArray(data.subcategories) && data.subcategories.length > 0;
    const isSubcategoryLayoutMenu = data.subcategoryLayout === ESubcategoryLayout.MENU;
    const isSectionLayoutImage = data.sectionLayout === ESectionLayout.IMAGE;
    const isSectionLayoutMenu = data.sectionLayout === ESectionLayout.MENU;
    const isSectionLayoutNone = data.sectionLayout === ESectionLayout.NONE;

    const [selectedSubcategory, handleSelectSubcategory] = useCategoryMenuAgent({
      data: data?.subcategories,
      onSelect: onSectionsChangeBy
    });
    const [, selectedSubcategoryId] = selectedSubcategory();

    const listItems = sections?.map(({ name, preview, id }) => ({
      title: name,
      src: preview,
      onClick: e => { history.push(CONTENT_LIST(data?.id, id)); },
      badge: {
        visible: false
      },
      likes: {
        visible: false
      },
      button: {
        visible: false
      }
    }));

    const renderBlocks = () => {
      const view = 'box';
      if (isSubcategoriesExist) {
        if (listItems && (isSubcategoryLayoutMenu || isSectionLayoutMenu || isSectionLayoutImage)) {
          return (
            <>
              { data?.subcategories?.length > 1 &&
                <Wrapper color="default">
                  <CategoryMenu
                    data={ data?.subcategories }
                    selectedKey={ String(selectedSubcategoryId) }
                    onSelect={ handleSelectSubcategory }
                  />
                </Wrapper>
              }
                <Wrapper color="default">
                  <ListHorizontal
                    className={ styles.listWrapper }
                    size={ isMobile ? 'xxs' : 'sm' }
                    cardAlign={ 'left' }
                    cardsPadding={ isMobile ? 12 : 25 }
                    listItems={ listItems }
                  />
                </Wrapper>
              </>
          );
        }
        if (isSectionLayoutImage && sections?.length > 1) {
          return (
            <CategoryBlocks
              type="section"
              view={ view }
              category={ data }
              data={ sections }
              loading={ loading }
              uAgeType={ uAgeType }
              onConfirm={ onConfirm }
            />
          );
        }
        if ((isSectionLayoutNone || isSectionLayoutImage) && sections?.length === 1) {
          return (
            <CategoryBlocks
              type="content"
              view={ view }
              category={ data }
              data={ contents }
              loading={ loading }
              uAgeType={ uAgeType }
              onConfirm={ onConfirm }
            />
          );
        }
        if (isSectionLayoutNone && data.content) {
          return <div dangerouslySetInnerHTML={ { __html: data.content } } />;
        }
      }
      return null;
    };

    return (
      <div ref={ ref } className={ classnames(className, styles[ECategoryClassKey.root]) } style={ style } id={ toString(data?.id) }>
        <Wrapper color="default">
          { Headline &&
            <Headline
              category={ data }
              sections={ sections }
              activeSubcategoryId={ +selectedSubcategoryId }
              uAgeType={ uAgeType }
              onConfirm={ onConfirm }
            />
          }
            </Wrapper>
          { renderBlocks() }
      </div>
    );
  })
);
Category.displayName = 'CategoryMain';

Category.defaultProps = { data: null, loading: false };
//#endregion

//#region ConnectedCategory
const ConnectedCategory: FC<ConnectedCategoryProps> = memo(
  forwardRef<HTMLDivElement, ConnectedCategoryProps>((connectedProps, ref) => {
    const { sectionListContainer, containerContentListMain, ...props } = connectedProps;
    const { data, loading } = props;
    const [paging, pagingEmitter] = useLimiter(5, 0);

    const isSubcategoriesExist = isArray(data?.subcategories) && data.subcategories.length > 0;
    const isSubcategoryLayoutMenu = data?.subcategoryLayout === ESubcategoryLayout.MENU;
    const isSectionLayoutImage = data?.sectionLayout === ESectionLayout.IMAGE;
    const isSectionLayoutMenu = data?.sectionLayout === ESectionLayout.MENU;
    const isSectionLayoutNone = data?.sectionLayout === ESectionLayout.NONE;

    //#region
    const firstSubcategory = data?.subcategories?.[0];
    const initialTargetId = firstSubcategory?.id;
    const [targetId, setTargetId] = useState(initialTargetId); // subcategory id
    useUpdateEffect(() => setTargetId(initialTargetId), [initialTargetId]);

    useEffect(() => {
      if (targetId) {
        let pager = { limit: 5, offset: 0 };
        if (!isSubcategoryLayoutMenu && isSectionLayoutMenu) pager = { limit: 100, offset: 0 };
        pagingEmitter.invokeReset(pager);
        sectionListContainer.actions.fetchSectionItems({ id: targetId, pager: paging.current() });
      }
    }, [targetId]);

    const sectionList: Partial<ISectionListInitialState> = useMemo(() => {
      const thing = find(sectionListContainer.list, s => s.targetId === targetId);
      if (thing) return pick(thing, ['items', 'status', 'more']);
    }, [sectionListContainer.list, targetId]);
    //#endregion

    //#region
    const firstSection = sectionList?.items?.[0];
    const initialSubjectId = firstSection?.id;
    const [subjectId, setSubjectId] = useState(initialSubjectId); // section id
    useUpdateEffect(() => setSubjectId(initialSubjectId), [initialSubjectId]);

    useEffect(() => {
      if (sectionList?.status === ELoadStatus.ready) {
        if (
          (!isSubcategoryLayoutMenu && isSectionLayoutMenu) ||
          (!isSubcategoryLayoutMenu && isSectionLayoutNone && sectionList?.items?.length === 1 ||
           !isSubcategoryLayoutMenu && isSectionLayoutImage && sectionList?.items?.length === 1)
        ) {
          if (subjectId) {
            containerContentListMain.actions.fetchContentMain({ id: subjectId, pager: { limit: 5, offset: 0 } });
          }
        } else if (isSubcategoryLayoutMenu && isSectionLayoutMenu) {
          if (sectionList?.items?.length > 0) {
            each(sectionList.items, section => {
              if (section?.id) {
                containerContentListMain.actions.fetchContentMain({ id: section.id, pager: { limit: 5, offset: 0 } });
              }
            });
          }
        }
      }
    }, [sectionList?.status, sectionList?.items, subjectId]);

    const contentList: Pick<IContentListMainInitialState, 'items' | 'status' | 'more'> = useMemo(() => {
      if (sectionList?.status === ELoadStatus.ready) {
        if (
          (!isSubcategoryLayoutMenu && isSectionLayoutMenu) ||
          (!isSubcategoryLayoutMenu && isSectionLayoutNone) ||
          (!isSubcategoryLayoutMenu && isSectionLayoutImage)
        ) {
          if (subjectId) {
            const thing = find(containerContentListMain.list, c => c.targetId === subjectId);
            if (thing) return pick(thing, ['items', 'status', 'more']);
          }
        } else if (isSubcategoryLayoutMenu && isSectionLayoutMenu) {
          if (sectionList?.items?.length > 0) {
            const subjectIdList = map(sectionList.items, section => section.id);
            const list = filter(containerContentListMain.list, c => c?.targetId > 0 && includes(subjectIdList, c.targetId));

            let status;
            if (some(list, ['status', ELoadStatus.loading])) status = ELoadStatus.loading;
            else if (every(list, ['status', ELoadStatus.ready])) status = ELoadStatus.ready;
            else status = ELoadStatus.not_ready;
            const more = some(list, ['more', true]);

            if (status !== ELoadStatus.ready) return { items: [], status, more };
            return reduce(
              list,
              (acc, c) => {
                // @ts-ignore
                if (c?.items?.length > 0) acc.items.push(...c.items);
                return acc;
              },
              { items: [], status, more }
            );
          }
        }
      }
    }, [containerContentListMain.list, sectionList?.status, sectionList?.items, subjectId, targetId]);
    //#endregion

    const handleSectionsChangeBy = (index: number, id: number) => setTargetId(id);
    const handleContentsChangeBy = (index: number, id: number) => setSubjectId(id);

    if (!isSubcategoriesExist) return null;
    return (
      <Category
        ref={ ref }
        { ...props }
        sections={ sectionList?.items }
        contents={ contentList?.items }
        loading={ loading || sectionList?.status === ELoadStatus.loading || contentList?.status === ELoadStatus.loading }
        onSectionsChangeBy={ handleSectionsChangeBy }
        onContentsChangeBy={ handleContentsChangeBy }
      />
    );
  })
);
ConnectedCategory.displayName = 'ConnectedCategoryMain';
//#endregion

export default flow([SectionListContainer, ContainerContentListMain])(ConnectedCategory) as typeof Category;
