//#region imports
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Banner from 'a1s-omobile-react-ui/src/components/shared/banner/banner.index';
import Button from 'a1s-omobile-react-ui/src/components/shared/button/button.index';
import { Postcard } from 'a1s-zet-react-ui/src/components/shared/postcard/postcard.index';
import Typography from 'a1s-omobile-react-ui/src/components/theming/typography/typography.index';
import IconDocument from 'a1s-omobile-react-ui/src/components/shared/icon/document/icon.document';
import IconMagnifier from 'a1s-omobile-react-ui/src/components/shared/icon/magnifier/icon.magnifier';
import IconChicken from 'a1s-omobile-react-ui/src/components/shared/icon/chicken/icon.chicken';
import IconThumb from 'a1s-omobile-react-ui/src/components/shared/icon/thumb/icon.thumb';
import IconCup from 'a1s-omobile-react-ui/src/components/shared/icon/cup/icon.cup';

import { stylesDietBanner } from './diet.banner.styles';
import dietMobileImg from 'app/assets/img/diet.mobile.png';
import dietDesktopImg from 'app/assets/img/diet.desktop.jpg';
import Wrapper from 'app/components/wrapper/wrapper.index';
import useBreakpoints from 'app/hooks/use.breakpoints';
import { BannerContainer, IBannerContainer } from 'app/containers/banner.container';
import { ELoadStatus } from 'app/models/shared.model';
//#endregion

export interface IDietBannerProps {
  bannerContainer: IBannerContainer;
}

const DietBanner: FC<IDietBannerProps> = ({ bannerContainer }) => {
  const { actions, items, request: { status } } = bannerContainer;
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useBreakpoints();
  const styles = stylesDietBanner({ isMobile, isTablet, isDesktop });

  useEffect(() => {
    actions.fetchItems();
  }, []);

  const descItems: Array<{
    number: number;
    title: string;
    icon: any;
  }> = [
    { number: 1, title: t('promoBanner.params'), icon: <IconDocument /> },
    { number: 2, title: t('promoBanner.program'), icon: <IconMagnifier /> },
    { number: 3, title: t('promoBanner.menu'), icon: <IconChicken /> },
    { number: 4, title: t('promoBanner.workout'), icon: <IconThumb /> },
    { number: 5, title: t('promoBanner.result'), icon: <IconCup /> }
  ];

  return (
    <>
      <Banner
        data={ items }
        isLoading={ status?.items === ELoadStatus.loading || !items?.length }
        isMobile={ isMobile }
        hasOverlay
        className={ styles.banner }
      />
        <Wrapper>
          <div className={ !isMobile ? styles.container : styles.mobileContainer }>
            {
              isMobile &&
                <Typography fontSize={ 24 } className={ styles.header }>
                  { t('header.diet') }
                </Typography>
            }
            <div className={ isMobile ? styles.mobile : '' }>
              <Postcard
                src={ isMobile ? dietMobileImg : dietDesktopImg }
                layout={ isMobile ? 'vertical' : 'horizontal' }
                classes={ {
                  picture: styles.imageContainer,
                  picture__image: styles.image
                } }
              >
                <div className={ styles.textBlock }>
                  <div className={ styles.textContent }>
                    {
                      !isMobile &&
                        <Typography className={ styles.header } fontSize={ 24 }>
                          { t('header.diet') }
                        </Typography>
                    }
                    <Typography className={ styles.desc } fontSize={ 16 } lineHeight={ 19 }>
                      { t('banner.start.title') }&nbsp;
                      { !isMobile && !isTablet && t('banner.start.desc') }
                    </Typography>
                 </div>
                { !isMobile &&
                  <ul className={ styles.steps }>
                    { descItems.map((el, i) => (
                      <li className={ styles.step } key={ el.number }>
                        <span className={ styles.stepIcon }>{ el.icon }</span>
                        <span className={ styles.stepTitle }>{ el.title }</span>
                      </li>
                    )) }
                  </ul>
                }
                <div>
                  <Button
                    className={ styles.button }
                    onClick={ () => history.push('/diet') }
                    size={ isMobile ? 'xs' : 'lg' }
                    type="primary"
                  >
                    { t('common.startNow') }
                  </Button>
                </div>
              </div>
            </Postcard>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default BannerContainer(DietBanner);
