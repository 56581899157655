import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'a1s-omobile-react-ui/src/components/shared/button/button.index';
import IconUser from 'a1s-omobile-react-ui/src/components/shared/icon/user/icon.user';

import useBreakpoints from 'app/hooks/use.breakpoints';
import { PROFILE } from 'app/configs/route.names';
import styles from './toolbar.styles';

interface IToolbarProps {
  isAuthenticate: boolean;
  handleClick: () => void;
}

const Toolbar: FC<IToolbarProps> = ({ isAuthenticate, handleClick }) => {
  const { t } = useTranslation();
  const { isTabletMenu } = useBreakpoints();
  const classes = styles();

  if (isAuthenticate) {
    return (
      <Link className={ classes.link } to={ PROFILE }>
        { !isTabletMenu && <span>{ t('common.profile') }</span> }
        <IconUser className={ classes.iconUser } />
      </Link>
    );
  }

  return (
    <Button onClick={ handleClick } mobile={ isTabletMenu } type="secondary" size={ isTabletMenu ? 'xs' : 'md' }>
      { t('action.comeIn') }
    </Button>
  );
};

Toolbar.displayName = 'Toolbar';

export default Toolbar;
