//#region imports
import React, { FC, ReactNode, useEffect } from 'react';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';

import IconClose from 'a1s-omobile-react-ui/src/components/shared/icon/close/icon.close';

import useBreakpoints from 'app/hooks/use.breakpoints';
import modalStyles from './modal.styles';
//#endregion

export interface IModalProps {
  onClose: () => void;
  open: boolean;
  className?: string;
  withClose?: boolean;
  titleHeader?: ReactNode;
}

export const Modal: FC<IModalProps> = ({
  open,
  onClose,
  children,
  withClose = false,
  className,
  titleHeader
}) => {
  const { isMobile } = useBreakpoints();
  const classes = modalStyles({ mobile: isMobile });
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : null;
    return () => (document.body.style.overflow = null);
  }, [open]);

  return open ? (
    <div className={ classes.root }>
      <div className={ classes.backdrop } onClick={ onClose } />
      <div className={ cn(classes.inner, className) }>
        <div className={ classes.header }>
          { titleHeader && (
            <div className={ classes.title }>
              { titleHeader }
            </div>
          ) }
          { withClose && <ButtonBase onClick={ onClose }><IconClose/></ButtonBase> }
        </div>
        <div className={ classes.content }>{ children }</div>
      </div>
    </div>
  ) : null;
};
