import { IRootState } from 'app/shared/reducers/root.reducer';
import { fetchContentItemHtml5, resetContentItemHtml5 } from 'app/entities/content/html5/html5.actions';

import { Dispatch, Action } from 'redux';
import { connect } from 'app/containers/connect';

import { IContentHTML5State } from 'app/entities/content/html5/html5.reducer';
import { logView } from 'app/entities/logging/logging.actions';

export type IContainerContentItemHTML5Props = IContentHTML5State & {
  actions: {
    fetchContentItemHtml5: (id: number | string) => void;
    resetContentItemHtml5: () => void;
  }
};

const mapStateToProps = ({ contentItemHtml5 }: IRootState) => contentItemHtml5;
const mapDispatchToProps = (dispatch: Dispatch): IContainerContentItemHTML5Props['actions'] => ({
  fetchContentItemHtml5: id => dispatch(fetchContentItemHtml5(id) as unknown as Action),
  resetContentItemHtml5: () => dispatch(resetContentItemHtml5())
});

export const ContainerContentItemHTML5 = connect<IContainerContentItemHTML5Props>('containerContentItemHTML5', mapStateToProps, mapDispatchToProps);
