//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';
//#endregion

export default makeStyles<unknown, { mobile?: boolean }>(
  createStyles({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1000
    },
    backdrop: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000
    },
    inner: {
      position: 'relative',
      top: '50%',
      left: '50%',
      maxWidth: ({ mobile }) => (mobile ? 335 : 375),
      transform: 'translate(-50%, -50%)',
      boxSizing: 'border-box',
      overflowY: 'auto',
      background: 'white',
      borderRadius: 5,
      zIndex: 1000
    },
    close: {
      marginLeft: ({ mobile }) => (mobile ? 12 : 16),
      cursor: 'pointer',
      fontFamily: 'icons',
      fontSize: 20,
      '&:hover': { opacity: '0.5' }
    },
    header: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      padding: ({ mobile }) => mobile ? 20 : '23px 30px 0px 30px',
      borderRadius: '5px 5px 0px 0px',
      boxSizing: 'border-box'
    },
    title: {
      fontSize: 24,
      lineHeight: ({ mobile }) => (mobile ? '20px' : '23px'),
      color: configTheme.colors.basic.primary,
      fontFamily: configTheme.fontFamily.PFBeauSansProSemiBold
    },
    content: {
      padding: ({ mobile }) => mobile ? '0px 20px 40px 20px' : '0px 30px 30px 30px'
    },
    descr: {
      marginTop: ({ mobile }) => mobile ? 16 : 24,
      color: configTheme.colors.text.dark.default,
      fontSize: ({ mobile }) => mobile ? 14 : 17,
      lineHeight: ({ mobile }) => mobile ? '18px' : '23px',
      letterSpacing: '-0.3px',
      '& + $descr': { marginTop: ({ mobile }) => mobile ? 16 : 24 }
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: ({ mobile }) => (mobile ? 16 : 24),
      '& button': {
        height: ({ mobile }) => (mobile ? 35 : 40),
        marginBottom: ({ mobile }) => (mobile ? 16 : 20),
        fontSize: ({ mobile }) => (mobile ? 16 : 17)
      },
      '& button:last-child': { marginBottom: 0 }
    },
    modalConfirmText: {
      color: configTheme.colors.text.light.default,
      display: 'flex',
      minHeight: 18,
      lineHeight: '18px',
      marginTop: 35,
      fontSize: 14
    },
    phone: {
      fontFamily: configTheme.fontFamily.PFBeauSansProSemiBold
    },
    warning: {
      display: 'flex',
      alignItems: 'center',
      marginTop: -20,
      fontSize: 14,
      color: configTheme.colors.text.dark.neutral,
      '& svg': {
        width: ({ mobile }) => mobile ? 20 : 25
      }
    },
    icon: {
      marginRight: 10
    }
  }),
  { name: 'Modal' }
);
