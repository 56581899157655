import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

export const useStylesProgramLayout = makeStyles<{}, { isMobile: boolean }>((theme: Theme) =>
  createStyles({
    wrapper: {
      marginLeft: '162px',
      marginTop: '54px',
      width: 'auto'
    },
    titleDay: {
      fontSize: '14px',
      color: configTheme.colors.text.dark.neutral,
      marginRight: '27px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    btnNextDay: {
      marginTop: ({ isMobile }) => isMobile ? 30 : 54,
      marginBottom: '18px',
      width: 244
    },
    setReminders: {
      marginBottom: ({ isMobile }) => isMobile ? 45 : 72,
      width: 244
    },
    active: {
      color: configTheme.colors.text.dark.default
    }
  })
);
