import { createStyles, makeStyles } from '@material-ui/core/styles';

import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

export const stylesError = makeStyles<unknown, { isMobile?: boolean }>(
  createStyles({
    root: { paddingTop: ({ isMobile }) => isMobile ? 40 : 90 },

    errorIcon: {
      '&:not(&:first-child)': { marginTop: 50 }
    },
    logo: {
      cursor: 'pointer'
    },

    title: {
      display: 'block',
      color: configTheme.colors.text.dark.default,
      fontSize: ({ isMobile }) => isMobile ? 24 : 30,
      lineHeight: ({ isMobile }) => isMobile ? '30px' : '44px',
      textAlign: 'center',
      letterSpacing: '-0.3px'
    },
    descr: {
      display: 'block',
      marginTop: 20,
      color: configTheme.colors.text.dark.default,
      fontSize: ({ isMobile }) => isMobile ? 14 : 17,
      lineHeight: ({ isMobile }) => isMobile ? '17px' : '23px',
      textAlign: 'center',
      letterSpacing: '-0.3px'
    },

    content: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center'
    },

    list: {
      listStyle: 'none',
      padding: 0,
      margin: 0
    },
    item: { paddingTop: 40 },
    button: {
      padding: '0 70px'
    },
    icon: {
      width: ({ isMobile }) => isMobile ? 100 : 170,
      height: ({ isMobile }) => isMobile ? 90 : 170,
      margin: ({ isMobile }) => isMobile ? '45px 0 30px 0' : '40px 0 20px 0',
      color: configTheme.colors.background.red.default
    }
  }),
  { name: 'ComponentError' }
);
