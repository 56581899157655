//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'a1s-zet-react-ui/src/config/config.theme';
import {
  EVirtualListClassKey,
  VirtualListClasses,
  EVirtualDotsClassKey,
  VirtualDotsClasses,
  IVirtualDotsStylesProps
} from './virtual.model';
//#endregion

export const stylesVirtualList = makeStyles(
  createStyles<VirtualListClasses, {}>({
    [EVirtualListClassKey.root]: {
      userSelect: 'none',
      willChange: 'height',
      transition: 'all 0.3s',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': { display: 'none' },
      '&$dragged': { cursor: 'grabbing' }
    },
    [EVirtualListClassKey.dragged]: {},

    [EVirtualListClassKey.item]: {
      // '$dragged &': { 'pointer-events': 'none' },
    },

    [EVirtualListClassKey.container]: {}
  }),
  { name: 'VirtualList' }
);

export const stylesVirtualDots = makeStyles(
  createStyles<VirtualDotsClasses, IVirtualDotsStylesProps>({
    [EVirtualDotsClassKey.root]: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      userSelect: 'none',
      marginTop: ({ mobile, isEmpty }) => isEmpty ? 0 : mobile ? 20 : 34,
      paddingBottom: ({ mobile, isEmpty }) => isEmpty ? 0 : mobile ? 20 : 40
    },

    [EVirtualDotsClassKey.dots]: {
      '& .react-carousel-dots-dot': {
        boxSizing: 'content-box',
        width: ({ mobile }) => (mobile ? 8 : 11),
        height: ({ mobile }) => (mobile ? 8 : 11),
        margin: 2,
        border: '0 none',
        borderRadius: '50%',
        backgroundColor: configTheme.colors.background.dark.default,

        '&.active': {
          margin: 0,
          border: `2px solid ${configTheme.colors.border.primary.dark}`,
          backgroundColor: configTheme.colors.background.light.default
        }
      }
    },

    [EVirtualDotsClassKey.dot]: {
      width: ({ mobile }) => (mobile ? 8 : 11),
      height: ({ mobile }) => (mobile ? 8 : 11),
      margin: ({ mobile }) => (mobile ? '0 6px' : '0 9px'),
      borderRadius: '50%',
      backgroundColor:  configTheme.colorsNew.background.primary.default,
      cursor: 'pointer'
    },

    [EVirtualDotsClassKey.dotActive]: {
      border: `2px solid ${ configTheme.colorsNew.background.primary.default }`,
      backgroundColor: configTheme.colorsNew.background.white.default
    }
  }),
  { name: 'VirtualDots' }
);
