//#region imports
import React, { FC, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useGetSet, useGetSetState, useUnmount } from 'react-use';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import shuffle from 'lodash/shuffle';
import assign from 'lodash/assign';
import filter from 'lodash/filter';
import slice from 'lodash/slice';
import flow from 'lodash/flow';
import find from 'lodash/find';
import pick from 'lodash/pick';
import some from 'lodash/some';
import map from 'lodash/map';

import Button from 'a1s-omobile-react-ui/src/components/shared/button/button.index';
import HeadTitle from 'a1s-omobile-react-ui/src/components/layout/headTitle/head.title.index';
import Header from 'a1s-omobile-react-ui/src/components/layout/header/header.index';
import ListHorizontal from 'a1s-omobile-react-ui/src/components/shared/list/horizontal/list.horizontal.index';
import CardVertical from 'a1s-omobile-react-ui/src/components/shared/card/vertical/card.vertical.index';

import { CONTENT_ITEM } from 'app/configs/route.names';
import subscribeCheck from 'app/utils/util.subscribe.check';
import useLimiter from 'app/hooks/useLimiter';

import { CancelToken, ELoadStatus, IRouteProps } from 'app/models/shared.model';
import { EContentType } from 'app/models/model.content';
import { ESectionLayout } from 'app/models/model.section';
import { ESubscriptionStatus } from 'app/models/model.subscription';

import { ISectionListInitialState } from 'app/entities/section/entity.section.list.reducer';
import { ISectionListContainerProps, SectionListContainer } from 'app/containers/section.list.container';
import { CategoryContainer, ICategoryContainerProps } from 'app/containers/category.container';
import { ContainerSectionItem, IContainerSectionItemProps } from 'app/containers/container.section.item';
import { ContainerContentList, IContainerContentListProps } from 'app/containers/container.content.list';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { IModalsContainer, ModalsContainer } from 'app/containers/modals.container';
import { ContainerLike, IContainerLikeProps } from 'app/containers/like.container';

import Wrapper from 'app/components/wrapper/wrapper.index';
import ViewBox from 'app/components/view.box/view.box.index';
import { CategoryMenu } from '../../main/components/category/category.menu';
import CategoryButtonsMenu from '../../main/components/category/category.buttons.menu';
import { numberRegex } from 'app/configs/const';
import { stylesContentListPage } from './page.content.list.styles';
import useBreakpoints from 'app/hooks/use.breakpoints';
import { ContainerRoute, TContainerRouteProps } from 'app/containers/container.route';
import usePrevRoute from 'app/hooks/use.prev.route';
//#endregion

export interface IDesktopContentListPageProps extends IRouteProps {
  containerUser: IUserContainerProps;
  containerSubscribe: IContainerSubscribeProps;
  containerCategory: ICategoryContainerProps;
  containerSectionItem: IContainerSectionItemProps;
  containerContentList: IContainerContentListProps;
  sectionListContainer: ISectionListContainerProps;
  modalsContainer: IModalsContainer;
  containerLike: IContainerLikeProps;
  containerRoute: TContainerRouteProps;
}

const DesktopContentListPage: FC<IDesktopContentListPageProps> = props => {
  const {
    containerUser,
    containerCategory,
    containerSectionItem,
    containerContentList,
    sectionListContainer,
    containerLike,
    containerRoute
  } = props;

  const history = useHistory();
  const { isMobile, isNotDesktop } = useBreakpoints();
  const scrollRef = useRef<HTMLDivElement>();
  const { t } = useTranslation();

  const [categoryId, setCategoryId] = useState<ICategoryContainerProps['item']['id']>();
  const [sectionId, setSectionId] = useState<IContainerSectionItemProps['item']['id']>();
  const [isLikesLoading, setIsLikesLoading] = useState<boolean>(false);

  const category = containerCategory.item;
  const subcategory = containerSectionItem.subcategory;
  const sectionItem = containerSectionItem.item;
  const subsections = containerSectionItem.subsections;
  const contentType = containerSectionItem.contentType;
  const subscribe = containerSectionItem.subscribe;

  const isUserAuthenticated = containerUser?.data?.token && containerUser?.data?.authenticated;
  const isUserSubscribed = subscribe?.status === ESubscriptionStatus.ACTIVE;

  const isSubcategoriesMenu = category?.subcategories?.length === 1;
  const isSectionLayoutNone = category?.sectionLayout === ESectionLayout.NONE;
  const isContentLayoutList = !includes([EContentType.GAME_HTML5, EContentType.GAME_ANDROID, EContentType.ARTICLE], contentType);
  const isContentLayoutSheet = includes([EContentType.GAME_HTML5, EContentType.GAME_ANDROID], contentType);
  const isContentLayoutGrid = includes([EContentType.ARTICLE], contentType);

  const [selectedSubcategoryId, setSelectedSubcategoryId] = useGetSet(subcategory?.id);
  const [selectedSectionId, setSelectedSectionId] = useGetSet(sectionId);
  const [selectedSubsectionId, setSelectedSubsectionId] = useGetSet(subsections?.[0]?.id);

  const [cancelTokenMap, setCancelTokenMap] = useGetSetState({
    fetchSectionItem: CancelToken.source(),
    fetchContentList: CancelToken.source()
  });
  const [paging, pagingEmitter] = useLimiter();

  const [sectionList, isSectionListLoading] = useMemo(() => {
    const thing = find(sectionListContainer.list, s => s.targetId === selectedSubcategoryId());
    const loading: boolean = some(sectionListContainer.list, ['status', ELoadStatus.loading]);
    const fields: Partial<ISectionListInitialState> = thing ? pick(thing, ['items', 'status', 'more']) : {};
    return [fields, loading];
  }, [sectionListContainer.list, selectedSubcategoryId()]);

  const recommends = useMemo(() => {
    if (contentType === EContentType.STUDY) {
      return slice(shuffle(filter(category?.subcategories, s => s.id !== selectedSubcategoryId())), 0, 3);
    }
    return slice(shuffle(filter(sectionList?.items, s => s.id !== selectedSectionId())), 0, 3);
  }, [
    contentType === EContentType.STUDY ? category?.subcategories : sectionList?.items,
    contentType === EContentType.STUDY ? selectedSubcategoryId() : selectedSectionId()
  ]);

  const { items: sections } = sectionList;
  const {
    items: contents,
    more: isContentsMore,
    actions: contentActions,
    status: contentStatus,
    itemLikes: likes
  } = containerContentList;
  const { actions: containerRouteActions, route: containerRouteData } = containerRoute;

  usePrevRoute({ containerRouteActions });

  const styles = stylesContentListPage({ isMobile, isNotDesktop });

  useEffect(() => {
    if (props.computedMatch.params) {
      setSectionId(Number(props.computedMatch.params.sectionId));
      setCategoryId(Number(props.computedMatch.params.categoryId));
    }
  }, [props.computedMatch.params]);

  useEffect(() => {
    props.modalsContainer.actions.closeModal();
  }, [history.location]);

  useEffect(() => {
    if (sectionId) {
      containerSectionItem.actions.fetchSectionItem(sectionId, {
        uncancelled: true,
        cancelToken: cancelTokenMap().fetchSectionItem?.token
      });
    }
    return () => {
      cancelTokenMap().fetchSectionItem?.cancel('Cancel fetchStream [containerSectionItem.actions.fetchSectionItem]');
      setCancelTokenMap({ fetchSectionItem: CancelToken.source() });
      containerSectionItem.actions.resetSectionItem();
    };
  }, [sectionId]);

  useEffect(() => {
    if (categoryId) {
      if (!numberRegex.test(props.computedMatch.params.sectionId)) {
        history.replace('/error');
      }
      containerCategory.actions.fetchCategoryItem(categoryId);
    }
    return () => {
      sectionListContainer.actions.resetSectionItems();
    };
  }, [categoryId]);

  useEffect(() => {
    if (subcategory?.id && subcategory.id !== selectedSubcategoryId()) {
      setSelectedSubcategoryId(subcategory.id);
      sectionListContainer.actions.fetchSectionItems({ id: subcategory.id, pager: { limit: 100, offset: 0 } });
    }
  }, [subcategory?.id]);

  useEffect(() => {
    if (sectionItem?.id && sectionItem.id !== selectedSectionId()) {
      setSelectedSectionId(sectionItem.id);
    }

    const firstSubsectionId = subsections?.[0]?.id;
    if (firstSubsectionId && firstSubsectionId !== selectedSubsectionId()) {
      setSelectedSubsectionId(firstSubsectionId);
    }
  }, [sectionItem?.id, subsections]);

  useEffect(() => {
    if (contents?.length) {
      contentActions.getItemLikes(contents);
    }
  }, [contents]);

  useEffect(() => {
    if (
      sectionItem?.id === selectedSectionId() &&
      ((!sectionItem?.hasSubsections && selectedSectionId()) || (sectionItem?.hasSubsections && selectedSubsectionId()))
    ) {
      let limit;
      switch (contentType) {
        case EContentType.BOOK:
        case EContentType.VIDEO:
        case EContentType.AUDIO:
        case EContentType.IMAGE:
          limit = 5;
          break;
        case EContentType.OLYMPIAD:
        case EContentType.STUDY:
          limit = 1000;
          break;
        case EContentType.ARTICLE:
          limit = isMobile ? 5 : 6;
          break;
        case EContentType.GAME_HTML5:
        case EContentType.GAME_ANDROID:
        default:
          limit = 5;
          break;
      }
      pagingEmitter.invokeReset({ limit, offset: 0 });
      contentActions.fetchContentList({
        id: !sectionItem?.hasSubsections ? selectedSectionId() : selectedSubsectionId(),
        pager: paging.current(),
        cancelToken: cancelTokenMap().fetchContentList?.token
      });
    }
    return () => {
      cancelTokenMap().fetchContentList?.cancel('Cancel fetchStream [containerContentList.actions.fetchContentList]');
      setCancelTokenMap({ fetchContentList: CancelToken.source() });
      contentActions.resetContentList();
    };
  }, [
    sectionItem?.id,
    sectionItem?.hasSubsections,
    contentType,
    isUserAuthenticated,
    isUserSubscribed,
    selectedSectionId(),
    selectedSubsectionId(),
    isMobile
  ]);

  const validateSubscribe = callback => {
    subscribeCheck({
      subscribe: props.containerSubscribe.subscriptions.find(sub => sub.id === subscribe.id),
      callback,
      containerUser,
      containerModals: props.modalsContainer,
      history
    });
  };

  const handleSelectSubcategory = useCallback(
    debounce(async (targetId: number) => {
      const result = await sectionListContainer.actions.fetchSectionItems({ id: targetId, pager: { limit: 100, offset: 0 } });
      const data = result?.value?.data;
      const firstSectionId = data?.sections?.[0]?.id;
      cancelTokenMap().fetchContentList?.cancel('Cancel fetchStream [containerContentList.actions.fetchContentList]');
      cancelTokenMap().fetchSectionItem?.cancel('Cancel fetchStream [containerSectionItem.actions.fetchSectionItem]');
      setSelectedSubcategoryId(targetId);
      setSelectedSectionId(firstSectionId);
      setSelectedSubsectionId(undefined);
      contentActions.resetContentList();
      if (firstSectionId) history.push({ pathname: firstSectionId });
    }, 300),
    []
  );

  const handleSelectSection = useCallback(
    debounce(async (targetId: number) => {
      cancelTokenMap().fetchContentList?.cancel('Cancel fetchStream [containerContentList.actions.fetchContentList]');
      cancelTokenMap().fetchSectionItem?.cancel('Cancel fetchStream [containerSectionItem.actions.fetchSectionItem]');
      setSelectedSectionId(targetId);
      setSelectedSubsectionId(undefined);
      contentActions.resetContentList();
      history.push({ pathname: String(targetId) });
    }, 300),
    []
  );

  const handleSelectSubsection = useCallback(
    debounce(async (targetId: number) => {
      cancelTokenMap().fetchContentList?.cancel('Cancel fetchStream [containerContentList.actions.fetchContentList]');
      setSelectedSubsectionId(targetId);
      contentActions.resetContentList();
    }, 300),
    []
  );

  const handleLoadContent = useCallback(
    debounce(() => {
      pagingEmitter.invokeNext();
      contentActions.fetchContentList({
        id: !sectionItem?.hasSubsections ? selectedSectionId() : selectedSubsectionId(),
        more: isContentsMore,
        pager: paging.current(),
        cancelToken: cancelTokenMap().fetchContentList?.token
      });
    }, 300),
    [sectionItem?.hasSubsections, isContentsMore]
  );

  useUnmount(() => {
    if (handleSelectSubcategory?.cancel) handleSelectSubcategory.cancel();
    if (handleSelectSection?.cancel) handleSelectSection.cancel();
    if (handleLoadContent?.cancel) handleLoadContent.cancel();
    contentActions.resetContentList();
    containerSectionItem.actions.resetSectionItem();
  });

  const handleClickLike = (contentId, liked) => {
    if (isUserAuthenticated) {
      if (contentStatus?.likeStatus === ELoadStatus.ready && !isLikesLoading) {
        try {
          setIsLikesLoading(true);
          if (liked) {
            containerLike.actions.removeLikeItem(contentId);
          } else {
            containerLike.actions.createLikeItem(contentId);
          }
        } finally {
          if (contents?.length) {
            setTimeout(() => {
              contentActions.getItemLikes(contents);
              setIsLikesLoading(false);
            }, 300);
          }
        }
      }
    } else {
      containerUser?.actions.toggleModalLogin();
    }
  };

  const onContentClick = contentItem => {
    contentItem?.free || includes([EContentType.STUDY, EContentType.OLYMPIAD, EContentType.BOOK], contentType)
      ? history.push(CONTENT_ITEM(contentItem?.type, contentItem?.id))
      : validateSubscribe(() => history.push(CONTENT_ITEM(contentItem?.type, contentItem?.id)));
  };

  const onItemRecommendClick = id => {
    if (includes([EContentType.STUDY, EContentType.BOOK], contentType)) handleSelectSubcategory(id);
    else handleSelectSection(id);
  };

  const recommendsList = useMemo(() => sections?.filter(
    el => el.id !== Number(selectedSectionId())).map(({ name, preview, id }) => ({
    key: id,
    title: name,
    src: preview,
    onClick: e => {
      onItemRecommendClick(id);
    },
    badge: {
      visible: false
    },
    likes: {
      visible: false
    },
    button: {
      visible: false
    }
  })), [sections, isMobile, categoryId, selectedSectionId()]);

  const handleHeadTitle = () => {
    history.push(containerRouteData ?? '/');
  };

  return (
    <>
      <ViewBox className={ styles.root }>
        <HeadTitle callback={ handleHeadTitle }>
          <Header level={ 'h4' } fontWeight={ 400 }>{ category.name }</Header>
        </HeadTitle>
        <Wrapper>
          { !isSubcategoriesMenu && (
              <CategoryMenu
                data={ category?.subcategories }
                selectedKey={ String(selectedSubcategoryId()) }
                onSelect={ handleSelectSubcategory }
              />
          ) }

          { !isSectionLayoutNone && !isSectionListLoading && sections?.length !== 1 && isSubcategoriesMenu && (
            <CategoryMenu data={ sections } selectedKey={ String(selectedSectionId()) } onSelect={ handleSelectSection } />
          ) }
          { !isSectionLayoutNone && !isSectionListLoading && sections?.length !== 1 && !isSubcategoriesMenu && (
            <CategoryButtonsMenu
              buttons
              items={ sections }
              initialItemId={ String(selectedSectionId()) }
              onClickItem={ handleSelectSection }
              mobile={ isMobile }
            />
          ) }
          { !isSectionLayoutNone &&
            containerSectionItem.status === ELoadStatus.ready &&
            !isSectionListLoading &&
            subsections?.length > 0 && (
              <CategoryMenu data={ subsections } selectedKey={ String(selectedSubsectionId()) } onSelect={ handleSelectSubsection } />
            ) }
        </Wrapper>
          <Wrapper>
          <div
            className={ classnames(contentType, {
              [styles.contentList]: isContentLayoutList,
              [styles.contentSheet]: isContentLayoutSheet,
              [styles.contentGrid]: isContentLayoutGrid
            }) }
          >
            { map(contents, contentItem => {
              const style = {};
              const preview = { src: null };
              if (isContentLayoutList) {
                assign(style, {
                  width: isNotDesktop ? (isMobile ? 335 : 416) : 744
                });
                assign(preview, { src: contentItem.desktopImageUrl || contentItem.imageUrl });
              } else if (isContentLayoutSheet) {
                assign(preview, { src: contentItem.desktopImageUrl || contentItem.imageUrl });
              } else if (isContentLayoutGrid) {
                assign(style, {
                  width: isNotDesktop ? (isMobile ? '100%' : 'unset') : 'unset'
                });
                assign(preview, { src: contentItem.desktopImageUrl || contentItem.imageUrl });
              }
              return (
                <div
                  key={ contentItem?.id }
                  className={ classnames(contentType, {
                    [styles.contentItem]: isContentLayoutList,
                    [styles.contentCell]: isContentLayoutSheet,
                    [styles.contentGridItem]: isContentLayoutGrid
                  }) }
                >
                  <CardVertical
                    key={ contentItem?.id }
                    style={ style }
                    align="left"
                    type={ contentType === EContentType.ARTICLE && EContentType.ARTICLE }
                    size={ isNotDesktop ? ((isMobile && !isContentLayoutGrid) ? 'xs' : isMobile ? 'sm' : 'lg') : 'xl' }
                    badge={ {
                      visible: true,
                      type: contentItem?.free
                        ? 'free'
                        : props.containerSubscribe.subscriptions.find(sub => sub.id === subscribe?.id)?.status === ESubscriptionStatus.ACTIVE
                          ? 'available'
                          : 'paid'
                    } }
                    likes={ {
                      visible: true,
                      liked: likes.find(el => el.contentId === contentItem?.id)?.active,
                      count: likes.find(el => el.contentId === contentItem?.id)?.count,
                      onClick: () => {
                        handleClickLike(contentItem?.id, likes.find(el => el.contentId === contentItem?.id)?.active);
                      },
                      disabled: contentStatus?.likeStatus !== ELoadStatus.ready || isLikesLoading
                    } }
                    src={ preview?.src }
                    title={ contentItem?.name }
                    desc={ contentType === EContentType.ARTICLE
                      ? contentItem?.description.replace(/&nbsp;/g, '')
                      : contentItem?.description
                    }
                    button={ {
                      visible: true,
                      children: isContentLayoutGrid ? <>{ t('common.read') }</> : null
                    } }
                    onClick={ e => { onContentClick(contentItem); } }
                  />

                </div>
              );
            }) }

            { contentType && contentStatus.items !== ELoadStatus.ready && (
              <>
                { isContentLayoutList && (
                  <>
                    { [...Array(10).keys()].map(item => (
                      <div className={ classnames(styles.contentItem, contentType) } key={ item }>
                        <Skeleton
                          variant="rect"
                          width={ isNotDesktop ? (isMobile ? 335 : 416) : 744 }
                          height={ contentType === EContentType.AUDIO ? 136 : isNotDesktop ? (isMobile ? 302 : 357) : 525 }
                          animation="wave"
                        />
                      </div>
                    )) }
                  </>
                ) }
              </>
            ) }

            { isContentsMore && (
              <Button
                className={ styles.moreButton }
                type="primary"
                disabled={ contentStatus.items !== ELoadStatus.ready }
                onClick={ handleLoadContent }
                size={ isMobile ? 'md' : 'lg' }
                mobile={ isMobile }
              >
                Показать еще
              </Button>
            ) }
          </div>
          </Wrapper>

          { recommends?.length > 0 &&
            <Wrapper color="surface" className={ styles.recommendList }>
              <Header level={ 'h3' } className={ styles.recommendsTitle }>{ t('common.recommend') }</Header>
              { recommendsList &&
                <ListHorizontal
                  size={ isMobile ? (isNotDesktop ? 'xxs' : 'xs') : 'sm' }
                  cardAlign={ 'left' }
                  cardsPadding={ isNotDesktop ? 12 : 25 }
                  listItems={ recommendsList }
                />
              }
            </Wrapper>
          }
      </ViewBox>
    </>
  );
};
DesktopContentListPage.displayName = 'DesktopContentListPage';

export default flow([
  ContainerUser,
  ContainerSubscribe,
  CategoryContainer,
  SectionListContainer,
  ContainerSectionItem,
  ContainerContentList,
  ModalsContainer,
  ContainerLike,
  ContainerRoute
])(DesktopContentListPage);
