//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'a1s-zet-react-ui/src/config/config.theme';
//#endregion

export const useDesktopContentMediaPageStyles = makeStyles(
  createStyles({
    root: {
      zIndex: 1000,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      overflow: 'hidden',
      backgroundColor: configTheme.colors.background.dark.default
    },

    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      minHeight: '100%'
    },

    title: { position: 'relative' },
    titlePanel: {
      height: '100%',
      minHeight: 90,
      maxHeight: 90
    },
    titleLogo: {
      color: '#fff',
      fontSize: 22,
      lineHeight: '32px'
    },

    contentFrame: { position: 'relative' },

    player: {},
    iframe: {
      zIndex: 9,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      border: 0,
      backgroundColor: 'transparent'
    },

    closeBtn: {
      margin: 0,
      padding: 0,
      outline: 0,
      border: '0 none',
      backgroundColor: 'transparent',
      userSelect: 'none'
    },
    closeBtnIcon: {
      'i&': {
        padding: `0 ${configTheme.indents['indentX1'].margin * 0.67}px`,
        borderRadius: '50%',
        fontSize: 35,
        cursor: 'pointer'
      }
    }
  }),
  { name: 'DesktopContentMediaPage' }
);
