//#region imports
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'a1s-omobile-react-ui/src/components/shared/button/button.index';
import { IButtonProps } from 'a1s-omobile-react-ui/src/components/shared/button/button.models';

import { EDialogConfirmType } from 'app/models/enums';
import { IModalProps, Modal } from '../modal.index';
import modalStyles from '../modal.styles';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

interface IModalButton extends IButtonProps {
  title?: string;
}

export interface IModalConfirmProps extends IModalProps {
  confirmType: EDialogConfirmType;
  name?: string;
  isReverseButtons?: boolean;
  button: {
    cancel: IModalButton;
    confirm: IModalButton;
  };
  titleDesc?: ReactNode;
}

export const ModalConfirm: FC<IModalConfirmProps> = props => {
  const { isReverseButtons, name, titleDesc, titleHeader, button } = props;
  const { isMobile } = useBreakpoints();
  const classes = modalStyles({ mobile: isMobile });
  const { t } = useTranslation();
  const confirmType = props.confirmType.toLowerCase();
  return (
    <Modal
      { ...props }
      titleHeader={ titleHeader }
    >
      <div className={ classes.descr }>
        <span dangerouslySetInnerHTML={ { __html: (titleDesc as string) || t(`dialog.confirm.${confirmType}.text`, { name }) } } />
      </div>

      <div className={ classes.buttons }>
        { isReverseButtons ? (
          <>
            <Button block onClick={ button?.confirm?.onClick }>
              { button?.confirm?.title || t(`dialog.confirm.${confirmType}.deactivate`) }
            </Button>
            <Button block type={ 'secondary' } ghost onClick={ button?.cancel?.onClick }>
              { button?.cancel?.title || t(`dialog.confirm.${confirmType}.close`) }
            </Button>
          </>
        ) : (
          <>
            <Button
              size={ isMobile ? 'md' : 'lg' }
              mobile={ isMobile }
              block
              onClick={ button?.cancel?.onClick }
            >
              { button?.cancel?.title || t(`dialog.confirm.${confirmType}.close`) }
            </Button>
            <Button
              size={ isMobile ? 'md' : 'lg' }
              mobile={ isMobile }
              block
              type={ 'secondary' }
              ghost
              onClick={ button?.confirm?.onClick }
            >
              { button?.confirm?.title || t(`dialog.confirm.${confirmType}.deactivate`) }
            </Button>
          </>
        ) }
      </div>
    </Modal>
  );
};
