import React, { ReactNode, FC, useEffect, RefAttributes, forwardRef } from 'react';
import { StandardProps, Grid } from '@material-ui/core';
import cx from 'classnames';

import { HeadingLevel } from 'a1s-zet-react-ui/src/components/shared/typography/typography.models';
import { Typography } from 'a1s-zet-react-ui/src/components/shared/typography/typography.index';
import { useModalStyles } from 'app/components/modal/modal.styles';

import { Portal } from './modal.portal.ui';
import modalStyles from 'app/components/modal.new/modal.styles';
import useBreakpoints from 'app/hooks/use.breakpoints';

export interface IModalProps {
  open: boolean;
  title?: ReactNode;
  suptitle?: ReactNode;
  titleLevel?: number;
  withClose?: boolean;
  mobile?: boolean;
  width?: number | string;
  height?: number | string;
  onClose?: () => void;
  renderModal?: React.ReactNode;
}

export type ModalProps = StandardProps<IModalProps, never>;

export const Modal: FC<ModalProps> = forwardRef<RefAttributes<HTMLElement>, ModalProps>((props, ref) => {
  const {
    style,
    children,
    open,
    title,
    suptitle,
    titleLevel,
    withClose,
    mobile,
    width,
    height,
    onClose = () => {},
    renderModal
  } = props;

  const { isMobile } = useBreakpoints();
  const styles = useModalStyles({ mobile });
  const classes = modalStyles({ mobile: isMobile });

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : null;
    return () => (document.body.style.overflow = null);
  }, [open]);

  const onStopPropagation = e => e.stopPropagation();

  const renderHeader = () => {
    let innerHtml;
    if (mobile) {
      innerHtml = (
        <Grid container direction="row" wrap="nowrap" alignItems="center" justifyContent="space-between" spacing={ 0 }>
          <Grid item xs>
            { suptitle ? (
              suptitle
            ) : title ? (
              <Typography className={ styles.title } type="heading" level={ titleLevel as HeadingLevel } tag="div">
                { title }
              </Typography>
            ) : null }
          </Grid>
          <Grid item style={ { marginBottom: 'auto' } }>
            { withClose && (
              <span className={ styles.close } role="button" aria-hidden="true" aria-label="Close" onClick={ onClose }>
                { '\uE059' }
              </span>
            ) }
          </Grid>
        </Grid>
      );
    } else {
      innerHtml = (
        <>
          <Grid container direction="row" wrap="nowrap" alignItems="center" justifyContent="space-between" spacing={ 0 }>
            <Grid item xs>
              { suptitle ? (
                suptitle
              ) : title ? (
                <Typography className={ styles.title } type="heading" level={ titleLevel as HeadingLevel } tag="div">
                  { title }
                </Typography>
              ) : null }
            </Grid>
            <Grid item style={ { marginBottom: 'auto' } }>
              { withClose && (
                <span className={ styles.close } role="button" aria-hidden="true" aria-label="Close" onClick={ onClose }>
                  { '\uE059' }
                </span>
              ) }
            </Grid>
          </Grid>
          { title && suptitle && (
            <Typography className={ styles.title } type="heading" level={ titleLevel as HeadingLevel } tag="div" mobile={ mobile }>
              { title }
            </Typography>
          ) }
        </>
      );
    }
    return <div className={ styles.header }>{ innerHtml }</div>;
  };

  return (
    open && (
      <Portal>
        <div
          className={ cx(classes.root, {
            [styles.mobile]: mobile
          }) }
          onClick={ onClose }
        >
          <div className={ classes.backdrop }>
            { renderModal ? (
              <div onClick={ onStopPropagation } className={ classes.inner }>
                { renderModal }
              </div>
            ) : (
              <div className={ styles.dialog } style={ { width, height, ...style } } onClick={ onStopPropagation } >
                <div className={ styles.container } role="document" ref={ ref as any }>
                  <div className={ styles.content }>
                    { renderHeader() }
                    <div className={ styles.body }>{ children }</div>
                  </div>
                </div>
              </div>
            ) }
          </div>
        </div>
      </Portal>
    )
  );
});
Modal.displayName = 'Modal';

Modal.defaultProps = { withClose: false, mobile: false, width: null, height: null, titleLevel: 2 } as ModalProps;
