import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import isFunction from 'lodash/isFunction';

import { loginExternal } from 'app/utils/auth.external';
import { eventBus, OEventName } from 'app/utils/util.event';

import { ELoadStatus, EDistribution } from 'app/models/shared.model';
import { ESubscribeStatus, ESubscriptionStatus, ISubscription } from 'app/models/model.subscription';
import { ModalTypes } from 'app/entities/modals/modals.reducer';
import { TUserState } from 'app/entities/user/user.reducer';

interface IValidateSubscribeParams {
  user: TUserState;
  subscribe: ISubscription;
  actions?: {
    requireAuthentication?: Function;
    failInactive?: Function;
    failBroke?: Function;
  };
  callback?: Function;
}

// TODO: поправить, или удалить
export function validateSubscribeFn({ user, subscribe, actions, callback }: IValidateSubscribeParams) {
  const isUserAuthenticated = user?.data?.token && user?.data?.authenticated;
  if (user?.request?.status?.confirm === ELoadStatus.ready && !isUserAuthenticated) {
    if (isFunction(actions?.requireAuthentication)) actions.requireAuthentication();
    return false;
  }

  if (subscribe.status === ESubscriptionStatus.NOT_ACTIVE) {
    if (isFunction(actions?.failInactive)) actions.failInactive();
    return false;
  } else if (subscribe.status === ESubscriptionStatus.NO_MONEY) {
    if (isFunction(actions?.failBroke)) actions.failBroke();
    return false;
  } else if (isFunction(callback)) callback();
  return true;
}

export function useValidateSubscribeCallback({ user, subscribe }, deps = []) {
  const location = useLocation();
  const requireAuthentication = () => loginExternal(location.pathname);
  const failInactive = () => eventBus.dispatch(OEventName.ModalOpenSubscribe, { subscribeId: subscribe?.id });
  const failBroke = () => eventBus.dispatch(OEventName.ModalOpenNoMoney);
  return useCallback(
    (callback?: Function) => {
      const isValid = validateSubscribeFn({ user, subscribe, actions: { requireAuthentication, failInactive, failBroke }, callback });
      return isValid;
    },
    // @ts-ignore
    [user, subscribe, ...deps]
  );
}
