//#region imports
import React, { FC, useEffect, useMemo, useState } from 'react';
import flow from 'lodash/flow';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import HeadTitle from 'a1s-omobile-react-ui/src/components/layout/headTitle/head.title.index';
import Header from 'a1s-omobile-react-ui/src/components/layout/header/header.index';

import { ContainerGame, IContainerGameProps } from 'app/containers/container.game';
import { ContainerDietIndividualProgram, IContainerDietIndividualProgramProps } from 'app/containers/container.diet.program';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { IModalsContainer, ModalsContainer } from 'app/containers/modals.container';
import ProgramLayout from './program.layout';
import ProductsList from 'app/pages/shared/diet/products/products.list.index';
import Parameters from 'app/pages/shared/diet/parameters/parameters.index';
import { stylesIndividualProgram } from './individual.program.style';
import { DIET, DIET_PROMO, EDietPageSection } from 'app/configs/route.names';
import subscribeCheck from 'app/utils/util.subscribe.check';
import { ESubscriptionStatus } from 'app/models/model.subscription';
import { IUserAttributes } from 'app/entities/diet/program/diet.program.reducer';
import Wrapper from 'app/components/wrapper/wrapper.index';
import { CategoryMenu } from 'app/pages/desktop/main/components/category/category.menu';
import CategoryButtonsMenu from 'app/pages/desktop/main/components/category/category.buttons.menu';
import useBreakpoints from 'app/hooks/use.breakpoints';
import { ISectionItem } from 'app/models/model.section';
import { ELoadStatus } from 'app/models/shared.model';
//#endregion

interface IMyDietIndividualProgramDesktop {
  containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  containerUser: IUserContainerProps;
  containerSubscribe: IContainerSubscribeProps;
  modalsContainer: IModalsContainer;
  containerGame: IContainerGameProps;
}

const DietIndividualProgramDesktop: FC<IMyDietIndividualProgramDesktop> = props => {
  const { data: { authenticated }, request: { status: userStatus } } = props.containerUser;
  const {
    getDietIndividualProgram,
    getDietStatus,
    getDietIndividualProgramDayById,
    postNextWeek,
    saveDietItem,
    fetchDietItem
  } = props.containerDietIndividualProgram.actions;
  const {
    itemsDay,
    programStatus,
    dataDays,
    foodBasketText,
    itemUserAttributes,
    last,
    replacementText,
    progressStatus
  } = props.containerDietIndividualProgram;

  const { t } = useTranslation();
  const [dietDays, setDietDays] = useState([
    { id: 1, title: `${t('individualProgram.day')} 1`, eatings: [] },
    { id: 2, title: `${t('individualProgram.day')} 2`, eatings: [] },
    { id: 3, title: `${t('individualProgram.day')} 3`, eatings: [] },
    { id: 4, title: `${t('individualProgram.day')} 4`, eatings: [] },
    { id: 5, title: `${t('individualProgram.day')} 5`, eatings: [] },
    { id: 6, title: `${t('individualProgram.day')} 6`, eatings: [] },
    { id: 7, title: `${t('individualProgram.day')} 7`, eatings: [] }
  ]);
  const [activeDayId, setActiveDayId] = useState(dietDays[0].id);
  const [storageActiveDayId, setStorageActiveDayId] = useState(null);
  const { isMobile, isNotDesktop } = useBreakpoints();
  const styles = stylesIndividualProgram({ isMobile, isNotDesktop });
  const history = useHistory();
  const location = useLocation<any>();

  const activeSection = location?.state?.section || EDietPageSection.PROGRAM;

  const subscribe = props.containerSubscribe.subscriptions.find(sub => sub.id === programStatus?.id);

  const sectionList = [
    {
      name: t('diet.myProgram'),
      id: EDietPageSection.PROGRAM
    },
    {
      name: t('diet.listProduct'),
      id: EDietPageSection.PRODUCTS
    },
    {
      name: t('diet.myParameters'),
      id: EDietPageSection.PARAMETERS
    }
  ];

  useEffect(() => {
    return () => {
      if (localStorage.getItem('backUrl')) {
        localStorage.removeItem('backUrl');
      }
      setStorageActiveDayId(null);
      props.modalsContainer.actions.closeModal();
    };
  }, []);

  useEffect(() => {
    if (!authenticated && (userStatus.checkUser === ELoadStatus.ready || userStatus.confirm === ELoadStatus.ready) && !localStorage.getItem('individualParameters')) {
      history.push(DIET_PROMO);
    }
  }, [userStatus, authenticated]);

  const backUrl = localStorage.getItem('backUrl');

  const onCancel = () => {
    if (itemUserAttributes && Object.values(itemUserAttributes).some(value => value === null)) {
      return backUrl === '/' ? history.push(backUrl) : history.goBack();
    } else {
      if (subscribe.status === ESubscriptionStatus.NOT_ACTIVE) {
        history.push(DIET_PROMO);
      } else if (subscribe.status === ESubscriptionStatus.NO_MONEY) {
        if (backUrl) {
          return backUrl === '/' ? history.push(backUrl) : history.go(-2);
        } else {
          history.goBack();
        }
      } else {
        history.push(DIET_PROMO);
      }
    }
  };

  useEffect(() => {
    if (subscribe) {
      subscribeCheck({
        subscribe,
        callback: () => {
          getDietIndividualProgram();
        },
        callbackCancel: () => {
          onCancel();
        },
        containerUser: props.containerUser,
        containerModals: props.modalsContainer
      });
    }
  }, [authenticated, subscribe]);

  useEffect(() => {
    if (authenticated && subscribe?.status === ESubscriptionStatus.ACTIVE) {
      if (localStorage.getItem('activeDayId') || storageActiveDayId) {
        if (localStorage.getItem('activeDayId')) {
          const id = parseInt(localStorage.getItem('activeDayId'), 10);
          setStorageActiveDayId(id);
          getDietIndividualProgramDayById(id);
          localStorage.removeItem('activeDayId');
        } else {
          getDietIndividualProgramDayById(storageActiveDayId);
        }
      } else {
        if (itemsDay?.length) {
          getDietIndividualProgramDayById(itemsDay[0].id);
        }
      }
    }
  }, [itemsDay, authenticated, subscribe]);

  useEffect(() => {
    if (Object.keys(itemUserAttributes).length) {
      if (localStorage.getItem('individualParameters')) {
        const individualParameters = JSON.parse(localStorage.getItem('individualParameters'));
        const mergeUserAttributes = {} as IUserAttributes;
        Object.keys(itemUserAttributes).forEach(key => {
          mergeUserAttributes[key] = key === 'breastFeeding' ? individualParameters[key] || false : individualParameters[key] || itemUserAttributes[key];
        });
        saveDietItem(mergeUserAttributes);
        localStorage.removeItem('individualParameters');
      }
    }
  }, [itemUserAttributes]);

  const renderContent = useMemo(() => {
    switch (activeSection) {
      case EDietPageSection.PROGRAM:
        return (
          <ProgramLayout
            defaultDayId={ storageActiveDayId }
            authenticated={ authenticated }
            dataDays={ dataDays }
            itemsDay={ itemsDay }
            getDietIndividualProgram={ getDietIndividualProgram }
            getDietIndividualProgramDayById={ getDietIndividualProgramDayById }
            dietDays={ dietDays }
            setDietDays={ setDietDays }
            activeDayId={ activeDayId }
            setActiveDayId={ setActiveDayId }
            itemUserAttributes={ itemUserAttributes }
            postNextWeek={ postNextWeek }
            last={ last }
            saveUserParameters={ saveDietItem }
          />
        );
      case EDietPageSection.PRODUCTS:
        return <ProductsList foodBasketText={ foodBasketText } replacementText={ replacementText } />;
      case EDietPageSection.PARAMETERS:
        return (
          <div className={ styles.container }>
            <div className={ styles.descBlock }>
              { isMobile
                ? <span>{ t('diet.parameters.params') }<br /><br />{ t('diet.parameters.info') }</span>
                : t('diet.parameters.desc') }
            </div>
            <Parameters className={ styles.parameters } isNoParametersInfo />
          </div>
        );
      default:
        return (
          <ProgramLayout
            defaultDayId={ storageActiveDayId }
            authenticated={ authenticated }
            dataDays={ dataDays }
            itemsDay={ itemsDay }
            getDietIndividualProgram={ getDietIndividualProgram }
            getDietIndividualProgramDayById={ getDietIndividualProgramDayById }
            dietDays={ dietDays }
            setDietDays={ setDietDays }
            activeDayId={ activeDayId }
            setActiveDayId={ setActiveDayId }
            itemUserAttributes={ itemUserAttributes }
            postNextWeek={ postNextWeek }
            last={ last }
            saveUserParameters={ saveDietItem }
          />
        );
    }
  }, [activeSection, dataDays, itemsDay, getDietIndividualProgramDayById, foodBasketText, itemUserAttributes, last, isMobile]);

  const handleClickSectionItem = section => {
    history.replace(DIET(section), { section });
  };

  const handleClickActiveDay = id => {
    setActiveDayId(Number(id));
    getDietIndividualProgramDayById(Number(id));
  };

  const handleHeadTitle = () => {
    history.push('/');
  };

  return (
    <>
      <HeadTitle callback={ handleHeadTitle }>
        <Header level={ 'h4' } fontWeight={ 400 }>{ t('header.diet') }</Header>
      </HeadTitle>
        <Wrapper>
          <CategoryMenu
            data={ sectionList }
            selectedKey={ activeSection }
            onSelect={ handleClickSectionItem }
          />
        </Wrapper>
        <Wrapper>
          { activeSection === EDietPageSection.PROGRAM ? (
            <CategoryButtonsMenu
              buttons
              items={ dietDays as unknown as ISectionItem[] }
              initialItemId={ activeDayId.toString() }
              onClickItem={ handleClickActiveDay }
              mobile={ isMobile }
            />
          ) : null }
          { renderContent }
        </Wrapper>
    </>
  );
};

export default flow([ContainerUser, ContainerDietIndividualProgram, ContainerSubscribe, ModalsContainer, ContainerGame])(DietIndividualProgramDesktop);
