//#region imports
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Player from 'a1s-omobile-react-ui/src/components/shared/player/player.index';
import helperStyles from 'app/configs/theme/helper.styles';

import { EDistribution, ELoadStatus } from 'app/models/shared.model';

import { ContainerContentVideoItem } from 'app/containers/container.content.item.video';

import ViewBox from 'app/components/view.box/view.box.index';
import CircularLoader from 'app/components/loader/loader.circular';

import { IContentMediaVideoProps } from '../page.content.media.models';
import { useDesktopContentMediaPageStyles } from '../page.content.media.styles';
import { ESubscriptionStatus } from 'app/models/model.subscription';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

enum EAzureSkin {
  DEFAULT = 'amp-default',
  FLUSH = 'amp-flush'
}

const ContentMediaVideo: FC<IContentMediaVideoProps> = memo<IContentMediaVideoProps>(props => {
  const {
    containerUser,
    containerContentVideoItem,
    content: contentItem,
    contentId,
    sectionId,
    subsectionId,
    subscribe,
    history,
    src: srcProps,
    title,
    distribution,
    loading,
    ready,
    prevRoute
  } = props;
  const { src, token, progressStatus } = containerContentVideoItem;
  const isUserAuthenticated = containerUser?.data?.token && containerUser?.data?.authenticated;
  const classesHelper = helperStyles();
  const styles = useDesktopContentMediaPageStyles();
  const { t } = useTranslation();
  const { isMobile, isNotDesktop } = useBreakpoints();
  const [statusSrcProps, setStatusSrcProps] = useState(ELoadStatus.loading);

  useEffect(() => {
    if (!srcProps && contentId && subscribe && ready) {
      const callback = () => containerContentVideoItem.actions.fetchVideoItem(contentId);
      if (contentItem.free) {
        callback();
      } else if (subscribe?.status === ESubscriptionStatus.ACTIVE) {
        callback();
      }
    }
  }, [contentId, subscribe, ready, srcProps]);

  useEffect(() => {
    const handleSubscribeModalCancel = () => history.goBack();
    // eventBus.on(OEventName.ModalCancelSubscribe, handleSubscribeModalCancel);
    return () => {
      // eventBus.off(OEventName.ModalCancelSubscribe, handleSubscribeModalCancel);
      !srcProps && containerContentVideoItem.actions.resetVideoItem();
    };
  }, []);

  useEffect(() => {
    if (srcProps) {
      setTimeout(() => {
        setStatusSrcProps(ELoadStatus.ready);
      }, 300);
    }
  }, [srcProps]);

  const videoSrc = useMemo(() => {
    return srcProps || src;
  }, [src, srcProps]);

  const handleClose = () => {
    history.push(prevRoute ?? '/');
  };

  const maskedSrc = useMemo(() => videoSrc && videoSrc?.replace(`https:`, '')?.replace(`http:`, ''), [videoSrc]);
  // const maskedSrc = '//amssamples.streaming.mediaservices.windows.net/49b57c87-f5f3-48b3-ba22-c55cfdffa9cb/Sintel.ism/manifest(format=m3u8-aapl)?token=null';
  // .ism/manifest(format=mpd-time-csf)
  // .ism/manifest(format=mpd-time-csf,encryption=cenc)
  // .ism/manifest(format=m3u8-aapl)?token=null

  const loader = (
    <div className={ classesHelper.suspendLoader }>
      <CircularLoader />
    </div>
  );

  const PlayerComponent = () => {
    const streamFormats = ['HLS', 'HLS-V3', 'HLS-V4', 'SMOOTH', 'DASH'];

    let protectInfo = null;
    if (token) {
      protectInfo = [
        { type: 'Widevine', authenticationToken: token }, // azureHtml5JS
        { type: 'PlayReady', authenticationToken: token },
        // { type: 'FairPlay', certificateUrl: `//${location.host}/fairplay.cer` },
        { type: 'FairPlay', certificateUrl: '/fairplay.cer' },
        { type: 'AES', authenticationToken: token }
      ];
    }

    let mime;
    if (maskedSrc?.includes('.mp4')) mime = 'video/mp4';
    else if (maskedSrc?.includes('.ogv')) mime = 'video/ogg';
    else if (maskedSrc?.includes('format=m3u8-aapl')) mime = 'application/vnd.apple.mpegurl';
    else if (maskedSrc?.includes('format=mpd-csf-time')) mime = 'application/dash+xml';
    else if (maskedSrc?.includes('format=mpd-time-csf')) mime = 'application/dash+xml';
    else mime = 'application/vnd.ms-sstr+xml';

    const source = [
      {
        src: maskedSrc,
        type: mime,
        protectionInfo: protectInfo,
        streamingFormats: streamFormats
        // disableUrlRewriter: true,
      }
    ];

    return (
      <Player
        isDrm={ distribution === EDistribution.DRM }
        skin={ EAzureSkin.FLUSH }
        title={ title || contentItem?.name }
        onClose={ handleClose }
        token={ token }
        contendId={ contentId.toString() }
        src={ source }
        button={ { title: t('action.close') } }
        isMobile={ isMobile }
      />
    );
  };

  const renderItem = (s: ELoadStatus) => {
    switch (s) {
      case ELoadStatus.error:
        return <h3>Error happens, sorry...</h3>;
      case ELoadStatus.ready:
        return <PlayerComponent />;
      case ELoadStatus.loading:
      default:
        return (
          <div className={ styles.root }>
            <ViewBox>{ loader }</ViewBox>
          </div>
        );
    }
  };

  const status = loading || !ready ? ELoadStatus.loading : progressStatus;
  return <>{ srcProps ? renderItem(statusSrcProps) : renderItem(status) }</>;
});
ContentMediaVideo.displayName = 'ContentMediaVideo';

export default ContainerContentVideoItem(ContentMediaVideo);
