import { createStyles, makeStyles } from '@material-ui/core';

import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

import { IParametersStylesProps, ParametersClasses } from 'app/pages/shared/diet/parameters/parameters.index';

export const stylesParameters = makeStyles<ParametersClasses, IParametersStylesProps>(
  createStyles({
    wrapper: {
      width: '100%',
      margin: '36px auto 0px'
    },
    table: {
      padding: ({ mobile }) => mobile ? '27px 18px' : 36,
      borderRadius: ({ mobile }) => mobile ? '10px' : 0,
      boxShadow: 'none',
      boxSizing: 'border-box',
      '& .MuiTableCell-root': {
        fontFamily: `${ configTheme.fontFamily.PFBeauSansProRegular }!important`
      },
      '& .MuiTableCell-sizeSmall': {
        paddingLeft: 0
      },
      '& .MuiTableCell-sizeSmall:last-child': {
        paddingRight: 0
      }
    },
    title: {
      color: configTheme.colors.text.dark.default,
      display: 'inline-flex',
      marginBottom: ({ mobile }) => mobile ? 18 : 10,
      fontFamily: configTheme.fontFamily.PFBeauSansProRegular,
      fontSize: 24
    },
    desc: {
      marginTop: ({ mobile }) => mobile ? 18 : 36
    },
    text: {
      color: configTheme.colors.text.dark.default,
      fontSize: ({ mobile }) => mobile ? 14 : 19,
      lineHeight: ({ mobile }) => mobile ? '20px' : '30px',
      marginBottom: 15
    },
    icon: {
      marginRight: ({ mobile }) => mobile ? 15 : 19,
      width: ({ mobile }) => mobile ? 25 : 38,
      height: ({ mobile }) => mobile ? 25 : 38
    },
    list: {
      maxWidth: 660,
      paddingInlineStart: 0,
      marginBlockEnd: 0,
      marginLeft: -20,
      listStyleType: 'none',
      display: 'flex'
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 'calc(100%/6)'
    },
    itemTitle: {
      marginTop: 10,
      fontSize: 14,
      textAlign: 'center',
      color: configTheme.colors.text.dark.default
    },
    itemValue: {
      width: 65,
      height: 65,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      backgroundColor: configTheme.colors.basic.primary,
      color: configTheme.colors.background.light.default
    },
    actionButton: {
      margin: ({ mobile }) => mobile ? '36px auto 36px' : '36px auto 0px',
      display: 'flex'
    }
  })
);
