//#region imports
import React, { FC, forwardRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@material-ui/core';

import Typography from 'a1s-omobile-react-ui/src/components/theming/typography/typography.index';

import { stylesModalCalcProgram } from 'app/pages/desktop/diet/program/program.style';
import { DIET, EDietPageSection } from 'app/configs/route.names';
import calcProgramModalImg from 'app/assets/img/calc.program.modal.img.png';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

interface IProgramLoaderProps {
  setModalCalcProgram?: (flag: boolean) => void;
  handlePaymentEnd?: (flag: boolean) => void;
  modalClosePayment?: () => void;
  auth?: boolean;
  openLoginModal?: () => void;
}

export const ProgramLoader: FC<IProgramLoaderProps> = forwardRef(({ openLoginModal, modalClosePayment, auth, handlePaymentEnd }, ref) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const { isMobile } = useBreakpoints();
  const styles = stylesModalCalcProgram({ isMobile });

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 100;
        }
        return Math.min(oldProgress + 10, 100);
      });
    }, 450);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!auth) {
        localStorage.setItem('backUrl', '/');
        handlePaymentEnd(true);
        openLoginModal && openLoginModal();
      } else {
        localStorage.setItem('backUrl', '/diet');
        history.push(DIET(EDietPageSection.PROGRAM));
      }
    }, 5000);
    return () => {
      clearInterval(timeout);
      modalClosePayment();
    };
  }, []);

  return (
    <>
      <div className={ styles.paperLoader }>
        <div>
          <Typography className={ styles.title }>{ t('modalCalcProgram.WaitMinute') }!</Typography>
        </div>
        <div className={ styles.picture }>
          <img src={ calcProgramModalImg } alt="calcProgramModalImg" />
        </div>
        <div className={ styles.progress }>
          <LinearProgress variant="determinate" value={ progress } />
        </div>
        <div className={ styles.desc }>
          { t('modalCalcProgram.calcProgram') }
        </div>
      </div>
    </>
  );
});
