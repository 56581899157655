import { IRootState } from 'app/shared/reducers/root.reducer';
import { IContentItemState } from 'app/entities/content/content.item.reducer';

import { fetchContentItem, resetContentItem, updateRoteItem, IFetchContentItemProps } from 'app/entities/content/content.actions';
import { logView, logDownload } from 'app/entities/logging/logging.actions';

import { Dispatch, Action, AnyAction } from 'redux';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { connect } from 'app/containers/connect';

export type IContainerContentItemProps = IContentItemState & {
  actions: {
    fetchContentItem: typeof fetchContentItem;
    resetContentItem: typeof resetContentItem;
    updateRoteItem: typeof updateRoteItem;
  };
};

const mapStateToProps = ({ contentItem }: IRootState) => contentItem;
const mapDispatchToProps = (dispatch: ThunkDispatch<IContentItemState, undefined, any>): IContainerContentItemProps['actions'] => ({
  fetchContentItem: (...props) => dispatch(fetchContentItem(...props)),
  resetContentItem: () => dispatch(resetContentItem()),
  updateRoteItem: route => dispatch(updateRoteItem(route))
});

export const ContainerContentItem = connect<IContainerContentItemProps>('containerContentItem', mapStateToProps, mapDispatchToProps);
