//#region imports
import React, { FC, memo, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import Header from 'a1s-omobile-react-ui/src/components/layout/header/header.index';

import { SECTIONS } from 'app/configs/route.names';
import { HeadlineProps } from './headline.model';
import { stylesHeadline } from 'app/pages/desktop/main/components/headline/headline.styles';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

//#region Headline
const Headline: FC<HeadlineProps> = memo(
  forwardRef<HTMLDivElement, HeadlineProps>((props, ref) => {
    const { category, activeSubcategoryId } = props;
    const { t } = useTranslation();
    const { isNotDesktop } = useBreakpoints();
    const classes = stylesHeadline({ isNotDesktop });

    return (
      <Grid className={ classes.container } ref={ ref } container alignItems="baseline" justifyContent="space-between" spacing={ 1 }>
        <Grid item>
          <Header className={ classes.header } level={ 'h3' }> { category.name }</Header>
        </Grid>
        <Grid item>
          <Link className={ classes.title } to={ SECTIONS(category?.id, activeSubcategoryId) }>
            { t('common.showAll') }
          </Link>
        </Grid>
      </Grid>
    );
  })
);
Headline.displayName = 'HeadlineMain';
//#endregion

export default Headline;
