import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { createStyles, makeStyles } from '@material-ui/core';
import { isMobile } from 'mobile-device-detect';
import classnames from 'classnames';

import flow from 'lodash/fp/flow';

import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';

import { Typography } from 'app/components/desktop/typography/typography.index';

import { ESubscriptionStatus, ISubscription } from 'app/models/model.subscription';
import { Button } from 'app/components/button/button.ui';

interface IPromo {
  containerSubscribe: IContainerSubscribeProps;
  containerUser: IUserContainerProps;
  className?: string;
  onSubscribe: (sub: ISubscription, index: EPromo) => void;
  activeIndex?: EPromo;
}

enum EPromo {
  DIET = 0,
  DIRECTORY = 1
}

const usePromoStyles = makeStyles<unknown, { mobile?: boolean }>(
  createStyles({
    title: {
      marginBottom: 18
    },
    img: {
      display: 'flex',
      maxWidth: 652,
      width: '100%',
      margin: ({ mobile }) => (mobile ? '27px auto' : '36px auto')
    },
    button: {
      display: 'block',
      margin: 'auto'
    },
    bottomTextWrapper: {
      color: '#8A8F97',
      marginTop: 18,
      fontSize: 16,
      lineHeight: '22px'
    }
  }),
  { name: 'PromoStyles' }
);

function randomEnum<T>(anEnum: T): T[keyof T] {
  const enumValues = (Object.keys(anEnum)
    .map(n => Number.parseInt(n, 10))
    .filter(n => !Number.isNaN(n)) as unknown) as Array<T[keyof T]>;
  const randomIndex = Math.floor(Math.random() * enumValues.length);
  const randomEnumValue = enumValues[randomIndex];
  return randomEnumValue;
}

const Promo = memo<IPromo>(props => {
  const [prevSubscription, setPrevSubscription] = useState(null);
  const { subscriptions, actions: { fetchSubscription } } = props.containerSubscribe;
  const { containerUser, className, onSubscribe, activeIndex } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const styles = usePromoStyles({ mobile: isMobile });

  useEffect(() => {
    const currentSubscription = subscriptions.find(item => item.id === prevSubscription?.id);
    if (!!prevSubscription && currentSubscription.status !== prevSubscription.status) {
      const currentStatus = currentSubscription.status;

      if (currentStatus === ESubscriptionStatus.ACTIVE) {
        history.push('/');
      }
    }
  }, [prevSubscription, subscriptions]);

  useEffect(() => {
    if (!containerUser.data.authenticated) {
      fetchSubscription();
    }
  }, [containerUser.data.authenticated]);

  const onActivate = (index: EPromo) => {
    setPrevSubscription(subscriptions[index]);
    onSubscribe(subscriptions[index], index);
  };

  const renderPromo = (index: EPromo) => {
    const sub = subscriptions[index];

    return (
      <div className={ classnames(styles.root, className) }>
        <Typography type="heading" level={ isMobile ? 2 : 1 } className={ styles.title } mobile={ isMobile }>
          { t(`promo.${index}.title`) }
        </Typography>
        <Typography type="text" tag="p">
          { sub?.description }
        </Typography>
        <img src={ sub?.image } className={ styles.img } />

        <Button onClick={ () => onActivate(index) } size="lg" className={ styles.button }>
          { t('subscribe.modal.activate') }
        </Button>

        <div className={ styles.bottomTextWrapper }>
          Нажимая кнопку «{ t('subscribe.modal.activate') }»,&nbsp; вы подтверждаете заказ услуги «{ sub?.name }» на условиях&nbsp;
          <a style={ { color: '#8F9399' } } target={ '_blank' } href={ '/oferta.pdf' }>
            Пользовательского соглашения
          </a>
          . Стоимость { sub?.price } (с НДС).&nbsp; Оплата с контентного лицевого счета, а при его отсутствии — с основного лицевого счета
          Абонента.
        </div>
      </div>
    );
  };

  const subStatus = useMemo(() => {
    return subscriptions.map(sub => sub.status);
  }, [subscriptions]);

  const selectPromo = useCallback(() => {
    if (activeIndex) {
      return renderPromo(activeIndex);
    }
    if (subStatus.every(sub => sub === ESubscriptionStatus.NOT_ACTIVE) || !containerUser.data.authenticated) {
      return renderPromo(randomEnum(EPromo));
    } else {
      if (subStatus.every(sub => sub === ESubscriptionStatus.ACTIVE)) {
        return null;
      } else {
        const index = subStatus.findIndex(sub => sub === ESubscriptionStatus.NOT_ACTIVE);
        if (index !== -1) return renderPromo(index);
        else return null;
      }
    }
  }, [subStatus, containerUser, activeIndex]);

  return <>{ selectPromo() }</>;
});

export default flow([ContainerUser, ContainerSubscribe])(Promo);
