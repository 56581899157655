//#region imports
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';
//#endregion

interface IProps {
  isNotDesktop: boolean;
  isTabletMenu: boolean;
}

export const stylesLayout = makeStyles<Theme, IProps>(theme =>
  createStyles({
    menu: {
      '& a': {
        fontSize: ({ isTabletMenu }) => isTabletMenu ? 16 : 17
      }
    },
    footer: {
      padding: ({ isTabletMenu }) => isTabletMenu ? '15px 0' : '20px 0 28px 0',
      '& p': {
        fontSize: ({ isTabletMenu }) => isTabletMenu ? 14 : 17
      }
    },
    link: {
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 14 : 17,
      color: configTheme.colors.text.light.default
    }
  }), { name: 'Layout' });
