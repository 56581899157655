//#region imports
import { makeStyles, createStyles, Theme } from '@material-ui/core';
//#endregion

interface IProps {
  isNotDesktop?: boolean;
  isMobile?: boolean;
}

export const stylesCategoryPage = makeStyles<Theme, IProps>(theme =>
  createStyles({
    root: {
      paddingTop: ({ isNotDesktop }) => isNotDesktop ? 0 : 28,
      paddingBottom: ({ isNotDesktop }) => (isNotDesktop ? 50 : 66)
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      [theme.breakpoints.down(955)]: {
        flexDirection: 'column'
      }
    },
    cardItem: {
      maxWidth: ({ isMobile }) => (isMobile ? 335 : 424),
      marginTop: 30,
      marginRight: 34,
      [theme.breakpoints.up(1455)]: {
        '&:nth-child(3n)': {
          marginRight: 0
        }
      },
      [theme.breakpoints.down(1455)]: {
        marginLeft: 'calc((100% - 426px * 2)/4)',
        marginRight: 'calc((100% - 426px * 2)/4)'
      },
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        margin: '16px 0 0 0',
        '&:first-child': {
          marginTop: 30
        }
      }
    }
  }),
  { name: 'CategoryPage' }
);
