import React, { FC } from 'react';
import clsx from 'clsx';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { useStylesRadiosCheck } from './customized.radios.check.style';
import useBreakpoints from 'app/hooks/use.breakpoints';

interface IRadioProps extends RadioProps {
  isFirst?: boolean;
}
export const CustomizedRadiosCheck: FC<IRadioProps> = ({ isFirst, ...props }) => {
  const { isNotDesktop } = useBreakpoints();
  const classes = useStylesRadiosCheck({ isFirst, isNotDesktop });

  return (
    <Radio
      className={ classes.root }
      disableRipple
      color="default"
      checkedIcon={ <span className={ clsx(classes.icon, classes.checkedIcon) } /> }
      icon={ <span className={ classes.icon } /> }
      { ...props }
    />
  );
};
