import React, { FC, Suspense, useEffect, lazy } from 'react';
import { Switch } from 'react-router-dom';
import { useHistory } from 'react-router';
import map from 'lodash/map';
import flow from 'lodash/fp/flow';

import { uiSetup } from 'app/configs/theme';

import PublicRoute from 'app/routes/public.route';

import { ELoadStatus, IRouteProps } from 'app/models/shared.model';
import { EContentType } from 'app/models/model.content';

import { ContainerDietIndividualProgram, IContainerDietIndividualProgramProps } from 'app/containers/container.diet.program';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';
import { IContainerSubscribeProps, ContainerSubscribe } from 'app/containers/container.subscribe';
import { ErrorServerContainer, IServerErrorContainer } from 'app/containers/container.server.error';

import Layout from 'app/layouts/layout';

import CircularLoader from 'app/components/loader/loader.circular';

import {
  PROFILE,
  SECTIONS,
  CONTENT_LIST,
  CONTENT_ITEM,
  SUBCATEGORIES,
  ERROR,
  STUDY_RESULT,
  DIET,
  DIET_PROMO,
  CONTENT_ITEM_VIDEO,
  EDietPageSection
} from 'app/configs/route.names';

import DesktopGameResultPage from 'app/pages/desktop/game.result/page.game.result.index';
import DesktopMainPage from 'app/pages/desktop/main/page.main.index';
import Profile from 'app/pages/shared/profile/profile.index';
import DesktopContentListPage from 'app/pages/desktop/content/list/page.content.list.index';
import DesktopContentItemPage from 'app/pages/desktop/content/item/page.content.item.index';
import DesktopContentMediaPage from 'app/pages/desktop/content/media/page.content.media.index';
import DesktopErrorPage from 'app/pages/desktop/error/error.index';
import DesktopDietPage from 'app/pages/desktop/diet/diet.index';
import DesktopDietIndividualProgram from 'app/pages/desktop/diet/program/individual.program.index';
import DesktopCategoryPage from 'app/pages/desktop/category/page.category.index';
import helperStyles from 'app/configs/theme/helper.styles';

interface IAppRoutesProps {
  containerUser: IUserContainerProps;
  containerSubscribe: IContainerSubscribeProps;
  containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  errorServerContainer: IServerErrorContainer;
}

export interface IRootRouteProps {
  isAuthenticated: boolean;
  status: IUserContainerProps['request']['status'];
  token: boolean | null | string;
  location: IRouteProps['location'];
  history: IRouteProps['history'];
  isSubscribed: boolean;
  authExternal: () => void;
}

const Routes: FC<IAppRoutesProps> = props => {
  const { resetProp } = props.containerSubscribe.actions;
  const { fetchDietItem, getDietStatus, saveDietItem } = props.containerDietIndividualProgram.actions;
  const {
    status: userStatus,
    actions: { fetchSubscription }
  } = props.containerSubscribe;

  const history = useHistory();
  const isAuthenticated = props.containerUser?.data?.authenticated;
  const classesHelper = helperStyles();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return unlisten;
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchDietItem();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      if (userStatus.change === ELoadStatus.ready) {
        fetchSubscription();
        getDietStatus();
        resetProp('change');
        return;
      }
      if (![ELoadStatus.ready, ELoadStatus.loading].includes(userStatus.subscriptions)) {
        fetchSubscription();
        getDietStatus();
      }
    }
  }, [isAuthenticated, userStatus.change, userStatus.subscriptions]);

  useEffect(() => {
    props.errorServerContainer.errorCode && history.replace(ERROR);
  }, [props.errorServerContainer.errorCode]);

  return (
    <>
      <Suspense fallback={
        <div className={ classesHelper.suspendLoader }>
          <CircularLoader center/>
        </div>
        }
      >
        <Switch>
          <PublicRoute layout={ Layout } path="/" component={ DesktopMainPage } exact />
          <PublicRoute layout={ Layout } path={ DIET_PROMO } component={ DesktopDietPage } exact />
          { map(EDietPageSection, (section, index) => (
            <PublicRoute
              key={ index }
              layout={ Layout }
              path={ DIET(section) }
              component={ DesktopDietIndividualProgram }
              exact
            />
          )) }
          <PublicRoute
            layout={ Layout }
            component={ DesktopCategoryPage }
            path={ SECTIONS(':categoryId', ':subcategoryId') }
            exact
          />
          <PublicRoute layout={ Layout } component={ Profile } path={ PROFILE } exact />
          <PublicRoute
            layout={ Layout }
            component={ DesktopGameResultPage }
            path={ STUDY_RESULT(':contentType' as EContentType, ':contentId') }
            exact
          />
          <PublicRoute
            layout={ Layout }
            component={ DesktopContentListPage }
            path={ CONTENT_LIST(':categoryId', ':sectionId') }
            exact
          />
          {
            map([EContentType.VIDEO, EContentType.GAME_HTML5], type => (
              <PublicRoute
                key={ type }
                component={ DesktopContentMediaPage }
                path={ CONTENT_ITEM(type, ':contentId') }
                exact
              />
            ))
          }
          <PublicRoute
            layout={ Layout }
            component={ DesktopContentItemPage }
            path={ CONTENT_ITEM(':contentType' as EContentType, ':contentId') }
            exact
          />
          <PublicRoute
            component={ DesktopContentMediaPage }
            path={ CONTENT_ITEM_VIDEO(':contentType' as EContentType) }
            exact
          />
          <PublicRoute
            path={ ERROR }
            component={ DesktopErrorPage }
            layoutProps={ { isVisibleFooter: uiSetup.pages.error.isVisibleFooter } }
          />
          <PublicRoute
            path="/"
            component={ DesktopErrorPage }
            layoutProps={ { isVisibleFooter: uiSetup.pages.error.isVisibleFooter } }
          />
        </Switch>
      </Suspense>
    </>
  );
};

export default flow([ContainerDietIndividualProgram, ContainerSubscribe, ContainerUser, ErrorServerContainer])(Routes);
