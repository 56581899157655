//#region imports
import React, { FC, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { useHistory, useLocation } from 'react-router';
import flow from 'lodash/flow';

import Button from 'a1s-omobile-react-ui/src/components/shared/button/button.index';
import IconExclamation from 'a1s-omobile-react-ui/src/components/shared/icon/exclamation/icon.exclamation';
import ModalLoginUI from 'a1s-omobile-react-ui/src/components/shared/modal/login/modal.login';
import IconRefresh from 'a1s-omobile-react-ui/src/components/shared/icon/refresh/icon.refresh';

import { ELoadStatus } from 'app/models/shared.model';
import { EPostConfirmStatus } from 'app/entities/user/user.model';
import useBreakpoints from 'app/hooks/use.breakpoints';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';

import { validateProviderDefCode } from 'app/utils/util.auth';
import modalLoginStyles from './modal.login.styles';

import modalStyles from 'app/components/modal.new/modal.styles';
import { MAX_TIME_SMS_CODE } from 'app/configs/const';
import { getChangedPartMsisdn, getCleanNumbers, getMsisdn } from 'app/utils/msisdn.helper';
import { ContainerLogging, TContainerLoggingProps } from 'app/containers/container.logging';
//#endregion

export interface IModalLoginProps {
  containerUser: IUserContainerProps;
  containerLogging: TContainerLoggingProps;
}

const ModalLogin: FC<IModalLoginProps> = ({ containerUser, containerLogging }) => {
  const {
    data: { authenticated },
    meta: { modal: { login: isOpen, extended: isExtended }, status: smsCodeStatus },
    request: { status },
    actions: { request, confirm, resetFields, toggleModalLogin }
  } = containerUser;
  const { actions: { logLogin } } = containerLogging;

  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();

  const modalClasses = modalStyles({ mobile: isMobile });
  const classes = modalLoginStyles({ mobile: isMobile });

  const [value, setValue] = useState<string>('');
  const [smsCode, setSmsCode] = useState<string>('');
  const [isCodeExhausted, setIsCodeExhausted] = useState<boolean>(false);

  const [hasMsisdnInvalidError, setHasMsisdnInvalidError] = useState(false);
  const isSmsCode = useMemo(() => status.request === ELoadStatus.ready || Boolean(smsCodeStatus), [status, smsCodeStatus]);
  const msisdn = getMsisdn(value);

  useEffect(() => {
    if (!isOpen) {
      setHasMsisdnInvalidError(false);
      setSmsCode('');
      setValue('');
      resetFields(['request.status', 'request.error', 'meta.status']);
    }
  }, [isOpen]);

  useEffect(() => {
    if (authenticated && status.confirm === ELoadStatus.ready) {
      logLogin(pathname);
    }
  }, [authenticated, status.confirm]);

  useEffect(() => {
    if (status.request === ELoadStatus.ready) {
      resetFields(['meta.status']);
      setIsCodeExhausted(false);
    }
  }, [status.request]);

  useDebounce(() => {
    if (status.request === ELoadStatus.ready) {
      setIsCodeExhausted(true);
    }
  }, MAX_TIME_SMS_CODE, [status.request]);

  const handleSendCode = () => {
    if (value) {
      if (validateProviderDefCode(msisdn)) {
        setHasMsisdnInvalidError(false);
        request(msisdn);
      } else {
        setHasMsisdnInvalidError(true);
      }
    }
  };

  const handleResendCode = () => {
    if (smsCode) {
      setSmsCode('');
      request(msisdn);
    }
  };

  const handleSmsLoginPost = () => {
    if (smsCode) {
      confirm({ msisdn, smsCode });
    }
  };

  const handleCloseModal = () => {
    if (isExtended) {
      history.replace('/diet');
    }
    toggleModalLogin();
  };

  const handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isSmsCode) {
      setSmsCode(getCleanNumbers(e.target.value.replace(/\s/g, '')));
    } else {
      setValue(e.target.value.replace(/\s/g, ''));
    }
  };

  const requestStep = useMemo(() => (
    <>
      { hasMsisdnInvalidError && <div className={ classes.controlError }>{ t('auth.errors.msisdnInvalid') }</div> }
    </>
  ), [msisdn, hasMsisdnInvalidError, status, isExtended]);

  const confirmStep = useMemo(() => (
    <>
      { (!!smsCodeStatus && smsCodeStatus !== EPostConfirmStatus.OK || isCodeExhausted) && (
        <>
          { smsCodeStatus === EPostConfirmStatus.WRONG && !isCodeExhausted && <div className={ classes.controlError }>{ t('auth.codeStatus.wrong') }</div> }
          { (smsCodeStatus === EPostConfirmStatus.EXHAUSTED || isCodeExhausted) && <div className={ classes.controlError }>{ t('auth.codeStatus.exhausted') }</div> }
          <Button className={ classes.sendCodeAgain } onClick={ handleResendCode } type="secondary" size="xs">
            <IconRefresh />
            <span>{ t('auth.resendCode') }</span>
          </Button>
        </>
      ) }
    </>
  ), [smsCode, smsCodeStatus, isCodeExhausted, status]);

  const footer = useMemo(() => (
    <div className={ modalClasses.buttons }>
      <>
        <div className={ classes.warning }>
          <div className={ classes.icon }>
            <IconExclamation />
          </div>
          { t('auth.warning') }
        </div>
      </>
    </div>
  ), []);

  const inputOptions = {
    type: 'number',
    value: isSmsCode ? smsCode : value,
    name: 'login',
    placeholder: isSmsCode ? t('auth.enterCode') : t('auth.enterPhone'),
    mask: isSmsCode ? null : '+992 ## ###-####'
  };

  return (
    <ModalLoginUI
      open={ isOpen }
      title={ t('auth.title') }
      desc={ isSmsCode ? null : <span className={ classes.desc }>{ t('auth.descr') }</span> }
      onClick={ isSmsCode ? handleSmsLoginPost : handleSendCode }
      onClose={ handleCloseModal }
      onChange={ handleOnInputChange }
      size={ isMobile ? 'xs' : 'sm' }
      input={ inputOptions }
      renderInfo={ isSmsCode ? confirmStep : requestStep }
      buttons= { {
        submit: {
          title: isSmsCode ? t('auth.login') : t('auth.sendCode')
        },
        cancel: {
          title: t('common.cancel')
        }
      } }
      footer={ isExtended && footer }
    />
  );
};

export default flow([ContainerUser, ContainerLogging])(ModalLogin);
