import { makeStyles, createStyles } from '@material-ui/core/styles';

import { configTheme } from 'a1s-zet-react-ui/src/config/config.theme';

export const useModalStyles = makeStyles<unknown, { mobile?: boolean }>(
  createStyles({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1000,
      background: ({ mobile }) => (mobile ? 'white' : 'rgba(0, 0, 0, 0.5)'),
      overflowY: 'auto'
    },
    backdrop: {
      display: ({ mobile }) => (mobile ? 'block' : 'flex'),
      justifyContent: 'center',
      marginTop: ({ mobile }) => (mobile ? 0 : '10vh'),
      marginBottom: ({ mobile }) => mobile ? 0 : 10
    },
    dialog: {
      boxSizing: 'border-box',
      overflowY: 'auto',
      background: 'white',
      color: '#1F2229',
      width: ({ mobile }) => (mobile ? '100%' : 732),
      padding: ({ mobile }) =>
        mobile
          ? `${configTheme.indents['indentX2'].margin}px ${configTheme.indents['indentX1'].margin}px`
          : `${configTheme.indents['indentX2'].margin + 4}px`,
      border: ({ mobile }) => (mobile ? 'none' : '2px solid #1F2229')
    },
    content: {},
    header: {},
    body: { marginTop: configTheme.indents['indentX1.5'].margin },
    close: {
      cursor: 'pointer',
      display: 'inline-block',
      fontFamily: 'icons',
      fontSize: 32,
      '&:hover': { opacity: '0.5' }
    },
    title: {
      fontFamily: `${ configTheme.fontFamily.AvertaCyRegular }!important`
    },
    mobile: {}
  }),
  { name: 'Modal' }
);

export const useModalPackStyles = makeStyles<unknown, { mobile?: boolean }>(
  createStyles({
    text: {
      display: 'block',
      whiteSpace: 'pre-wrap',
      fontSize: 16,
      lineHeight: '22px',
      '& strong': {
        whiteSpace: 'nowrap'
      }
    },
    title: {
      fontFamily: `${ configTheme.fontFamily.AvertaCyRegular }!important`
    },
    buttonWrap: {
      marginTop: 36
    },
    subscribeButton: {
      display: 'block',
      width: ({ mobile }) => (mobile ? 163 : 183),
      height: ({ mobile }) => (mobile ? 48 : 60)
    },
    notificationButton: {
      display: 'block',
      width: ({ mobile }) => (mobile ? 163 : 183),
      height: ({ mobile }) => (mobile ? 48 : 60),
      margin: '36px auto 0'
    },
    confirmButton: {
      display: 'block',
      width: ({ mobile }) => (mobile ? 163 : 183),
      height: ({ mobile }) => (mobile ? 48 : 60)
    },
    subscribeButtonsWrapper: {
      marginTop: 36,
      display: 'flex',
      alignItems: 'center',
      flexDirection: ({ mobile }) => (mobile ? 'column' : 'row'),
      '& :first-child': {
        marginRight: ({ mobile }) => (mobile ? 0 : 36),
        marginBottom: ({ mobile }) => (mobile ? 18 : 0)
      }
    },
    bottomTextWrapper: {
      color: '#8A8F97',
      marginTop: 36,
      minHeight: 18,
      fontSize: ({ mobile }) => (mobile ? 14 : 16),
      lineHeight: ({ mobile }) => (mobile ? '18px' : '22px')
    },
    maxNotificationText: {
      color: configTheme.colors.notification.invalid.default,
      fontSize: 14,
      display: 'block',
      marginTop: 16,
      letterSpacing: '-0.5px',
      marginBottom: -3
    },
    modalConfirmText: {
      color: configTheme.colors.text.light.neutral,
      display: 'flex',
      minHeight: 18,
      lineHeight: '18px',
      marginTop: 36,
      fontSize: 14
    }
  }),
  { name: 'ModalPack' }
);

interface IPropsModalStyles {
  mobile: boolean;
}

export const useModalGameStyles = makeStyles(
  createStyles<any, IPropsModalStyles>({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    title: {
      width: '100%'
    },
    answer: {
      width: ({ mobile }) => (mobile ? 'calc(100% - 36px)' : '548px')
    },
    answerFirst: {
      marginTop: '36px!important'
    },
    answerText: {
      height: ({ mobile }) => (mobile ? 58 : 72),
      padding: ({ mobile }) => (mobile ? '18px' : '18px 27px !important')
    },
    text: {
      fontWeight: 400
    },
    textStart: {
      width: '100%',
      textAlign: 'start'
    },
    answerContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    answerPadding: {
      padding: '9px 0 18px 0'
    },
    image: {
      width: '205px',
      height: '205px',
      marginTop: '9px'
    }
  }),
  { name: 'GameAnswer' }
);
