//#region imports
import React, { FC, memo, useEffect, useMemo, useCallback, useState, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useLastLocation } from 'react-router-last-location';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import find from 'lodash/find';
import flow from 'lodash/fp/flow';

import { CONTENT_LIST } from 'app/configs/route.names';

import { ILikeItem } from 'app/entities/like/like.model';
import { ESubscriptionStatus } from 'app/models/model.subscription';
import { ContainerContentItem } from 'app/containers/container.content.item';
import { ContainerLike } from 'app/containers/like.container';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';

import { AnnounceProps } from 'app/models/models.announce';
import { IContentItemProps } from '../item.models';
import { Quiz } from 'app/components/desktop/quiz/quiz';
import AudioPlayer from 'app/components/desktop/audio-player/audio-player.index';

import Actions from './book.actions';
import Recommends from './book.recommends';
import Wrapper from 'app/components/wrapper/wrapper.index';
import ViewBox from 'app/components/view.box/view.box.index';
import { stylesDesktopContentItemPage } from '../page.content.item.styles';
import { loginExternal } from 'app/utils/auth.external';
//#endregion

import Cookies from 'universal-cookie';
import CircularLoader from 'app/components/loader/loader.circular';
const cookies = new Cookies();

const Announce: FC<AnnounceProps> = lazy(() => import('app/components/desktop/announce/announce.index'));

const ContentItemBook: FC<IContentItemProps> = memo(props => {
  const { containerUser, containerContentItem, containerLike, containerSubscribe } = props;
  const { t } = useTranslation();
  const lastLocation = useLastLocation();
  const history = useHistory();
  const params = useParams<any>();
  const location = useLocation<any>();
  const styles = stylesDesktopContentItemPage({});

  const [quizOpened, setQuizOpened] = useState(false);
  const [audioOpened, setOpenAudio] = useState(false);

  const accessible = containerUser?.data?.authenticated;
  const content = containerContentItem.item;
  const sectionDetail = containerContentItem.section;
  const section = sectionDetail?.section;
  const subscribe = sectionDetail?.subscribe;

  const subcategoryId = sectionDetail?.subcategory?.id;
  const categoryId = sectionDetail?.subcategory?.categoryId;
  const sectionId = section?.parentId ?? section?.id;
  const subsectionId = section?.parentId ? section?.id : undefined;
  const contentId = params?.contentId || 0;

  useEffect(() => {
    cookies.remove(`prev-route-content-item`);

    if (contentId) {
      containerContentItem.actions.fetchContentItem(contentId, { cancellable: true });
      containerLike.actions.fetchLikeList([contentId]);

      cookies.set(`prev-route-content-item`, { pathname: location?.pathname, state: location?.state ?? {} }, { path: '/academy' });
      if (lastLocation && !cookies.get('prev-route-content-list')?.pathname) {
        cookies.set(
            `prev-route-content-list`,
            { pathname: lastLocation?.pathname, state: lastLocation?.state ?? {} },
            { path: '/academy' }
        );
      }
    }
    return () => {
      containerContentItem.actions.resetContentItem();
      containerLike.actions.resetLikeItems();
    };
  }, [contentId, containerSubscribe.subscriptions]);

  const heart = useMemo(() => {
    return find(containerLike.list, h => h.contentId === contentId);
  }, [contentId, containerLike.list]);

  const handleLike = useCallback((cid: ILikeItem['contentId'], liked: boolean) => {
    if (liked) containerLike.actions.createLikeItem(cid);
    else containerLike.actions.removeLikeItem(cid);
  }, []);

  const handleDecline = useCallback(() => {
    loginExternal(history.location.pathname);
  }, []);

  useEffect(() => {
    let prevRoute = '';
    if (lastLocation) {
      if (lastLocation.pathname === '/' || lastLocation.pathname.includes('content/list')) {
        prevRoute = lastLocation.pathname;
      }
    }

    if (prevRoute === '') {
      if (cookies.get('prev-route-content-list')?.pathname) {
        prevRoute = cookies.get('prev-route-content-list')?.pathname;
      }
    }

    containerContentItem.actions.updateRoteItem(prevRoute);
  }, [lastLocation]);

  const lastUrl = containerContentItem.route?.length > 0 ? containerContentItem.route : undefined;
  const backUrl = lastUrl ?? (categoryId && subcategoryId && sectionId ? CONTENT_LIST(categoryId, sectionId) : '/');
  const goBack = () => {
    history.push(backUrl, { contentId, sectionId, subsectionId });
    cookies.remove(`prev-route-content-list`);
    cookies.remove(`prev-route-content-item`);
  };
  return (
    <ViewBox className={ styles.root }>
      <Wrapper>
        { content && (
          <Suspense fallback={ <CircularLoader /> }>
            <Announce
              className={ styles.announce }
              contentId={ content?.id }
              contentType={ content?.type }
              header={ content?.name }
              subheader={ content?.data?.artist }
              description={ content?.description }
              imageUrl={ content?.imageUrl }
              indicator={
                !content?.free
                  ? subscribe?.status === ESubscriptionStatus.ACTIVE
                    ? t('content.subscribed')
                    : t('content.bySubscription')
                  : undefined
              }
              indicatorIcon={ !content?.free ? (subscribe?.status === ESubscriptionStatus.ACTIVE ? 'success-large' : 'balance') : undefined }
              newness={ content?.new }
              free={ content?.free }
              likes={ heart?.count ?? 0 }
              liked={ heart?.active ?? false }
              declinedLike={ !accessible }
              onSuccessLike={ handleLike }
              onDeclineLike={ handleDecline }
            />
          </Suspense>
        ) }

        <Actions
          backUrl={ backUrl }
          contentId={ contentId }
          fetchContentItem={ () => {
            containerContentItem.actions.fetchContentItem(contentId, { cancellable: true });
          } }
          onOpenQuiz={ () => setQuizOpened(true) }
          onOpenAudio={ () => setOpenAudio(true) }
          onCloseAudio={ () => setOpenAudio(false) }
          isAudioClose={ !audioOpened }
        />

        <Recommends contentId={ contentId } />

        <Quiz id={ contentId as any } open={ quizOpened } onClose={ () => setQuizOpened(false) } />

        {
          audioOpened &&
            <AudioPlayer
              id={ String(contentId) }
              src={ content?.url && `${content?.url}&mimeType=audio/mpeg3` }
              preload="auto"
              title={ content?.name }
              className={ styles.audioBlock }
              autoplay
              withClose
              onClose={ () => setOpenAudio(false) }
            />
        }
      </Wrapper>
    </ViewBox>
  );
});

export default flow([ContainerUser, ContainerContentItem, ContainerLike, ContainerSubscribe])(ContentItemBook);
