//#region imports
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';
//#endregion

interface IProps {
  isNotDesktop?: boolean;
  isMobile?: boolean;
}

export const stylesContentListPage = makeStyles<Theme, IProps>(theme =>
  createStyles({
    root: {},

    title: {
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 19 : 24,
      color: configTheme.colors.text.light.default
    },

    caption: {},

    contentList: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      marginTop: ({ isNotDesktop }) => isNotDesktop ? 20 : 40,
      marginBottom: ({ isNotDesktop, isMobile }) => isNotDesktop ? 30 : 45
    },
    contentItem: {
      margin: '0 auto',
      marginTop: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 15 : 20) : 30,
      '&:first-child': { marginTop: 0 },
      '& img': {
        borderRadius: 4,
        height: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 227 : 282) : 416
      }
    },

    contentSheet: {
      display: 'flex',
      flexFlow: 'row wrap',
      marginTop: 10
    },
    contentCell: {
      margin: `10px 30px 0 0`,
      flex: '1 1 calc((100% / 5) - 30px)',
      maxWidth: 'calc((100% / 5) - 30px + (30px / 5))',
      width: 'calc((100% / 5) - 30px + (30px / 5))',
      '&:nth-child(-n + 5)': { marginTop: 0 },
      '&:nth-child(5n)': { marginRight: 0 }
    },
    contentGrid: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginTop: ({ isMobile }) => isMobile ? 20 : 45,
      paddingBottom: ({ isMobile }) => isMobile ? 30 : 50,
      [theme.breakpoints.down(870)]: {
        flexWrap: 'nowrap',
        flexDirection: 'column'
      }
    },
    contentGridItem: {
      flex: '0 calc(50% - 15px)',
      minWidth: 280,
      margin: '0 25px 30px 0',
      '&:nth-child(2n)': { marginRight: 0 },
      [theme.breakpoints.down(870)]: {
        flex: '100%',
        marginRight: 0
      }
    },

    moreButton: {
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 16 : 17,
      cursor: 'pointer',
      margin: '0 auto',
      marginTop: ({ isNotDesktop, isMobile }) => isNotDesktop ? 30 : 45,
      padding: ({ isMobile }) => isMobile ? '0 40px' : '0 30px'
    },

    recommendPack: {
      marginTop: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 30 : 35) : 40,
      paddingBottom: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 40 : 50) : 60
    },
    recommendsCard: {
      height: '100%',
      border: 'none'
    },
    recommendList: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      marginTop: 10,
      paddingBottom: 30
    },
    recommendItem: {
      flex: '1 1 calc((100% / 3) - 15px)',
      maxWidth: 'calc((100% / 3) - 15px + (15px / 3))',
      width: 'calc((100% / 3) - 15px + (15px / 3))',
      margin: `10px 15px 0 0`,
      '&:nth-child(-n + 3)': { marginTop: 0 },
      '&:nth-child(3n)': { marginRight: 0 }
    },
    recommendsTitle: {
      margin: ({ isMobile }) => isMobile ? '30px 0 20px 0' : '30px 0 20px 0',
      fontFamily: configTheme.fontFamily.PFBeauSansProSemiBold,
      fontSize: 24
    },
    categoryName: {
      margin: 0,
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 18 : 19,
      cursor: 'pointer'
    },
    categoryImg: {
      width: ({ isNotDesktop }) => isNotDesktop ? 280 : 339
    }
  })
);
