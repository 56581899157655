//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

import {
  ECategoryClassKey,
  ECategoryBlocksClassKey,
  ECategoryMenuClassKey,
  CategoryClasses,
  CategoryBlocksClasses,
  CategoryMenuClasses
} from './category.models';
//#endregion

interface IProps {
  isNotDesktop: boolean;
}

export const stylesCategory = makeStyles(
  createStyles<CategoryClasses, IProps>({
    [ECategoryClassKey.root]: {
      position: 'relative',
      paddingTop: `36px`
    },
    [ECategoryClassKey.badge]: {
      fontSize: 16
    },
    wrapper: {
      paddingBottom: 45
    },
    categoryName: {
      margin: `0 18px`,
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 16 : 17,
      cursor: 'pointer',
      color: configTheme.colors.text.dark.neutral
    },
    categoryImg: {
      width: ({ isNotDesktop }) => isNotDesktop ? 278 : 360
    },
    listWrapper: {
      marginTop: 30
    }
  }),
  { name: 'CategoryMain' }
);

export const useCategoryBlocksStyles = makeStyles(
  createStyles<CategoryBlocksClasses, {}>({
    [ECategoryBlocksClassKey.root]: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: `36px`
    },

    [ECategoryBlocksClassKey.box]: { flexFlow: 'row wrap' },

    [ECategoryBlocksClassKey.cell]: { flexFlow: 'row nowrap' },

    [ECategoryBlocksClassKey.item]: {
      [`$${ECategoryBlocksClassKey.box} &`]: {
        cursor: 'pointer',
        flex: '1 1 calc((100% / 3) - 15px)',
        maxWidth: 'calc((100% / 3) - 15px + (15px / 3))',
        width: 'calc((100% / 3) - 15px + (15px / 3))',
        margin: `15px 15px 0 0`,
        '&:first-child': {
          flex: '2 2 calc(100% - (100% / 3) - 15px)',
          maxWidth: 'calc(100% - (100% / 3) - 15px + (30px / 3))',
          width: 'calc(100% - (100% / 3) - 15px + (30px / 3))'
        },
        '&:nth-child(-n + 2)': { marginTop: 0 },
        '&:nth-child(3n - 1)': { marginRight: 0 }
      },
      [`$${ECategoryBlocksClassKey.cell} &`]: {
        cursor: 'pointer',
        flex: '1 1 calc((100% / 5) - 30px)',
        maxWidth: 'calc((100% / 5) - 30px + (30px / 5))',
        width: 'calc((100% / 5) - 30px + (30px / 5))',
        margin: `15px 30px 0 0`,
        '&:nth-child(-n + 5)': { marginTop: 0 },
        '&:nth-child(5n)': { marginRight: 0 }
      }
    },

    [ECategoryBlocksClassKey.iconFree]: {
      marginBottom: '5%',
      marginLeft: '5%',
      padding: '2px 2px',
      borderRadius: '50%',
      backgroundColor: configTheme.colors.background.dark.default
    },

    [ECategoryBlocksClassKey.itemTitle]: {
      [`$${ECategoryBlocksClassKey.item} & span`]: { cursor: 'pointer' }
    },
    [ECategoryBlocksClassKey.itemRoot]: {
      height: '298px!important',
      cursor: 'pointer'
    },
    [ECategoryBlocksClassKey.itemPicture]: {
      width: '100%!important'
    },
    [ECategoryBlocksClassKey.itemDescr]: {
      justifyContent: 'center!important',
      paddingLeft: '18px!important'
    },
    [ECategoryBlocksClassKey.pictureFigure]: {
      width: '100%!important'
    }

  }),
  { name: 'CategoryBlocksMain' }
);

export const stylesCategoryMenu = makeStyles(
  createStyles<CategoryMenuClasses, IProps>({
    [ECategoryMenuClassKey.root]: {
      marginBottom: ({ isNotDesktop }) => isNotDesktop ? 5 : 15,
      paddingTop: ({ isNotDesktop }) => isNotDesktop ? 15 : 25
    },

    [ECategoryMenuClassKey.menu]: {},

    [ECategoryMenuClassKey.arrow]: {
      zIndex: 1,
      opacity: 0.25,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '50%',
      willChange: 'opacity, backgroundColor',
      transition: 'all .3s ease-in-out',
      backgroundColor: configTheme.colors.background.light.translucent,
      color: configTheme.colors.text.light.neutral,
      cursor: 'pointer',
      pointerEvents: 'none',

      '&:hover': { color: configTheme.colors.text.dark.default },
      '&:first-child': { transform: `translateX(-${32 / 4}px)` },
      '&:last-child': { transform: `translateX(${32 / 4}px)` },
      '& svg': { fontSize: 32 },

      [`&$${ECategoryMenuClassKey.arrowDisabled} + $${ECategoryMenuClassKey.container}`]: {
        transform: `translateX(-${32}px)`
      },

      [`$${ECategoryMenuClassKey.prevActive} &:first-child:not($${ECategoryMenuClassKey.arrowDisabled})`]: {
        opacity: 1,
        pointerEvents: 'initial'
      },
      [`$${ECategoryMenuClassKey.nextActive} &:last-child:not($${ECategoryMenuClassKey.arrowDisabled})`]: {
        opacity: 1,
        pointerEvents: 'initial'
      }
    },

    [ECategoryMenuClassKey.arrowDisabled]: { zIndex: 0, opacity: 0, backgroundColor: 'transparent' },

    [ECategoryMenuClassKey.container]: { flex: '1 1 auto' },

    [ECategoryMenuClassKey.list]: {
      borderBottom: `2px solid ${ configTheme.colors.text.dark.neutral }`
    },

    [ECategoryMenuClassKey.listItem]: {},

    [ECategoryMenuClassKey.item]: {
      position: 'relative',
      margin: ({ isNotDesktop }) => isNotDesktop ? '0 26px 15px 0' : '0 28px 15px 0',
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 14 : 17,
      lineHeight: '18px',
      cursor: 'pointer',
      color: configTheme.colors.text.dark.neutral,
      [`$${ECategoryMenuClassKey.listItem}:last-child &`]: { marginRight: 0 },
      [`&$${ECategoryMenuClassKey.selected}`]: {
        position: 'relative',
        color: configTheme.colors.text.dark.default,
        pointerEvents: 'none',
        '&:before': {
          content: '""',
          position: 'absolute',
          bottom: -17,
          left: 0,
          right: 0,
          height: 2,
          backgroundColor: configTheme.colors.background.dark.default
        }
      }
    },

    [ECategoryMenuClassKey.loading]: {},
    [ECategoryMenuClassKey.prevActive]: {},
    [ECategoryMenuClassKey.nextActive]: {},
    [ECategoryMenuClassKey.selected]: { pointerEvents: 'none' }
  }),
  { name: 'CategoryMenuMain' }
);

export const stylesCategoryButtonsMenu = makeStyles<unknown, IProps>(
  createStyles({
    root: {
      position: 'relative'
    },

    line: {},

    scroll: {
      zIndex: 2,
      position: 'relative',
      overflowX: 'auto',
      '&::-webkit-scrollbar': { display: 'none' }
    },

    container: { minWidth: '100%' },

    list: {
      margin: 0,
      padding: 0,
      display: 'flex'
    },
    listItem: {
      display: 'block',
      marginRight: ({ isNotDesktop }) => isNotDesktop ? 9 : 18,
      border: `1px solid ${configTheme.colors.text.dark.default}`,
      borderRadius: 50,
      backgroundColor: 'transparent',
      color: configTheme.colors.text.dark.default
    },
    listItemActive: {
      display: 'block',
      marginRight: ({ isNotDesktop }) => isNotDesktop ? 9 : 18,
      border: `1px solid ${configTheme.colors.basic.primary}`,
      borderRadius: 50,
      backgroundColor: configTheme.colors.basic.primary,
      color: configTheme.colors.text.light.default,
      cursor: 'initial',
      pointerEvents: 'none'
    },
    customLi: { display: 'block' },

    item: {
      cursor: 'pointer',
      display: 'block',
      position: 'relative',
      boxSizing: 'border-box',
      minWidth: ({ isNotDesktop }) => isNotDesktop ? 65 : 125,
      margin: -1,
      padding: ({ isNotDesktop }) => isNotDesktop ? '6px 12px' : '8px 16px',
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 12 : 17,
      textAlign: 'center',
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      '&.exact': {
        cursor: 'initial',
        'pointer-events': 'none',
        color: configTheme.colors.background.light.default,
        backgroundColor: configTheme.colors.basic.primary
      },
      '&.exactCustom': {},
      '&::selection': { background: 'transparent' }
    }
  }),
  { name: 'NavBarTabs' }
);
