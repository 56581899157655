//#region imports
import React, { FC, memo } from 'react';
import flow from 'lodash/flow';

import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';
import { ErrorServerContainer, IServerErrorContainer } from 'app/containers/container.server.error';

import { ComponentError } from 'app/components/error/component.error';
import { Logger } from 'app/entities/logging/logger';
//#endregion

// tslint:disable-next-line: no-empty-interface
export interface IDesktopErrorPageProps {
  containerUser?: IUserContainerProps;
  errorServerContainer: IServerErrorContainer;
}

const DesktopErrorPage: FC<IDesktopErrorPageProps> = memo(props => {
  const { errorServerContainer } = props;
  const { errorCode, actions } = errorServerContainer;
  return (
    <>
      <Logger canSend/>
      <ComponentError code={ errorCode } callback={ actions.resetError } />
    </>
  );
});
DesktopErrorPage.displayName = 'DesktopErrorPage';

export default flow([ContainerUser, ErrorServerContainer])(DesktopErrorPage);
