//#region imports
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { IButtonProps } from 'a1s-omobile-react-ui/src/components/shared/button/button.models';
import Button from 'a1s-omobile-react-ui/src/components/shared/button/button.index';
import IconExclamation from 'a1s-omobile-react-ui/src/components/shared/icon/exclamation/icon.exclamation';

import { IModalProps, Modal } from '../modal.index';
import modalStyles from '../modal.styles';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

export interface IModalButton extends IButtonProps {
  title?: string;
}

export interface IModalSubscribeProps extends IModalProps {
  subscribeCondition: string;
  phone: string;
  button: {
    cancel: IModalButton;
    subscribe: IModalButton;
  };
  titleDesc?: ReactNode;
}

export const ModalSubscribe: FC<IModalSubscribeProps> = props => {
  const { onClose, titleHeader, phone, subscribeCondition, titleDesc, button } = props;
  const { isMobile } = useBreakpoints();
  const classes = modalStyles({ mobile: isMobile });
  const { t } = useTranslation();
  return (
    <Modal { ...props } titleHeader={ titleHeader } onClose={ onClose } withClose>
      <div className={ classes.descr }>
        <div dangerouslySetInnerHTML={ { __html: subscribeCondition } } />
      </div>

      <div className={ classes.descr }>
        { titleDesc || t('subscribe.modal.paymentPhone') }
        <strong className={ classes.phone }>{ phone }</strong>
      </div>

      <div className={ classes.buttons }>
        <Button block onClick={ button?.subscribe?.onClick } size={ isMobile ? 'sm' : 'xl' }>
          { button?.subscribe?.title || t('subscribe.modal.activate') }
        </Button>
      </div>
    </Modal>
  );
};
