import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'mobile-device-detect';

import { IButtonProps } from 'a1s-omobile-react-ui/src/components/shared/button/button.models';
import Button from 'a1s-omobile-react-ui/src/components/shared/button/button.index';

import { ESubscribeStatus, ESubscriptionStatus } from '../subscribe/modal.subscribe.model';
import { IModalProps, Modal } from '../modal.index';
import modalStyles from '../modal.styles';

export interface IModalButton extends IButtonProps {
  title?: string;
}
export interface IModalNotificationProps extends IModalProps {
  type: ESubscribeStatus | ESubscriptionStatus;
  button: {
    cancel: IModalButton;
  };
  titleDesc?: ReactNode;
}

export const ModalNotification: FC<IModalNotificationProps> = props => {
  const { type, titleDesc, titleHeader, button, onClose } = props;
  const classes = modalStyles({ mobile: isMobile });
  const { t } = useTranslation();
  return (
    <Modal
      { ...props }
      titleHeader={
        <span dangerouslySetInnerHTML={ { __html: (titleHeader as string) || t(`subscribe.error.${type && type.toLowerCase()}.header`) } } />
      }
      onClose={ onClose }
      withClose
    >
      <div className={ classes.descr }>
        <div dangerouslySetInnerHTML={ { __html: (titleDesc as string) || t(`subscribe.error.${type && type.toLowerCase()}.text`) } } />
      </div>

      <div className={ classes.buttons }>
        <Button
          size={ isMobile ? 'md' : 'lg' }
          mobile={ isMobile }
          block
          onClick={ button?.cancel?.onClick }
        >
          { button?.cancel?.title || t('subscribe.error.close') }
        </Button>
      </div>
    </Modal>
  );
};
