//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'a1s-zet-react-ui/src/config/config.theme';

import { EContentCardClassKey, ContentCardClasses, IContentCardStylesProps } from './content.card.models';
//#endregion

export const useContentCardStyles = makeStyles(theme =>
  createStyles<ContentCardClasses, IContentCardStylesProps>({
    [EContentCardClassKey.root]: {
      boxSizing: 'border-box',
      background: configTheme.colors.background.white.default
    },

    [EContentCardClassKey.inner]: {
      minHeight: '100%',
      cursor: ({ isAllClickable }) => isAllClickable ? 'pointer' : 'initial',
      background: configTheme.colors.background.white.default
    },

    [EContentCardClassKey.title]: {
      fontSize: ({ mobile }) => (mobile ? 16 : 22),
      lineHeight: 1.273,
      color: configTheme.colors.text.dark.default,
      cursor: ({ isTitleClickable, isAllClickable }) => isTitleClickable || isAllClickable ? 'pointer' : 'initial'
    },

    [EContentCardClassKey.descr]: {
      padding: ({ mobile, origin }) => (mobile ? '12px 8px' : origin === 'category' ? '24px 12px' : '0 0 0 30px'),
      justifyContent: 'space-between'
    },

    [EContentCardClassKey.picture]: {
      maxWidth: '100%',
      margin: '0 auto !important',
      cursor: ({ isPictureClickable }) => isPictureClickable ? 'pointer' : 'initial'
    },

    [EContentCardClassKey.pictureFigure]: { maxWidth: '100%' },
    [EContentCardClassKey.freeBlock]: {
      display: 'flex',
      marginTop: ({ mobile }) => mobile ? 10 : 15,
      '& img': {
        marginRight: '10px'
      },
      '& svg': {
        fill: 'none',
        [theme.breakpoints.down(380)]: {
          width: 15,
          height: 15
        }
      }
    },

    [EContentCardClassKey.imgLike]: { cursor: 'pointer', marginRight: '12px' },
    disabled: {
      '& span': {
        cursor: 'default'
      }
    },

    [EContentCardClassKey.button]: { margin: 'auto 0 0 0' }

  }),
  { name: 'ContentCard' }
);
