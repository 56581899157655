import { makeStyles, createStyles } from '@material-ui/core';
import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

interface IProps {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const stylesDietBanner = makeStyles(
  createStyles<any, IProps>({
    banner: {
      maxWidth: ({ isDesktop }) => isDesktop ? 1340 : 'unset',
      margin: ({ isDesktop }) => isDesktop ? '30px auto 60px auto' : 0,
      padding: ({ isDesktop }) => isDesktop ? '0 50px' : 0
    },
    container: {
      margin: ({ isTablet }) => !isTablet ? '40px 0' : '40px 0 0 0',
      borderRadius: 5,
      '& img': {
        width: ({ isTablet }) => isTablet ? 245 : 475,
        height: ({ isTablet }) => isTablet ? 347 : 382,
        borderRight: `1px solid ${ configTheme.colors.border.primary.light}`,
        borderRadius: '5px 0px 0px 5px',
        ['@media(max-width: 1280px) and (min-width: 1024px)']: {
          height: '420px!important',
          width: '300px!important'
        }
      }
    },
    mobileContainer: {
      paddingTop: 35,
      maxWidth: 'calc(100vw - 40px)'
    },
    mobile: {
      border: `1px solid ${ configTheme.colors.border.primary.light}`,
      borderRadius: 5,
      backgroundColor: configTheme.colors.background.light.default
    },
    imageContainer: {
      marginRight: '0!important'
    },
    image: {
      marginRight: ({ isMobile }) => (!isMobile ? 30 : 0),
      width: ({ isMobile }) => isMobile ? '100%' : 'none',
      borderRadius: 5
    },
    header: {
      margin: ({ isMobile, isTablet }) => isMobile ? '0 0 27px 0' : (isTablet ? '15px 0' : '28px 0 15px 0'),
      fontFamily: configTheme.fontFamily.PFBeauSansProSemiBold,
      color: configTheme.colors.text.dark.default
    },
    desc: {
      marginRight: ({ isMobile }) => isMobile ? '0px' : '30px',
      color: configTheme.colors.text.dark.default,
      letterSpacing: '-0.3px'
    },
    textBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      alignItems: ({ isMobile }) => isMobile ? 'center' : 'flex-start',
      margin: ({ isMobile }) => isMobile ? '5px 15px 0px 15px' : '0px'
    },
    textContent: ({ isMobile }) => (!isMobile ?
      {
        display: 'flex',
        flexDirection: 'column'
      }
      : {}),
    button: ({ isMobile, isTablet }) => (isMobile ?
      {
        margin: '18px 0 25px 0',
        padding: '12px 38px',
        fontSize: 16
      }
      : {
        padding: '12px 34px',
        margin: isTablet ? '18px 0 30px 0' : '18px 0 40px 0',
        fontSize: isTablet ? 16 : 17
      }),
    steps: {
      position: 'relative',
      maxWidth: 750,
      margin: ({ isTablet }) => isTablet ? '0 10px 0 -10px' : '10px 20px 0 -35px',
      display: 'flex',
      justifyContent: 'space-between',
      listStyleType: 'none',
      paddingInlineStart: 0,
      '&:after': {
        content: '""',
        height: 5,
        position: 'absolute',
        top: ({ isTablet }) => isTablet ? 22 : 26,
        left: '7%',
        right: '7%',
        backgroundColor: configTheme.colors.border.primary.light
      }
    },
    step: {
      width: 'calc(100%/5)',
      display: 'flex',
      flexDirection: 'column'
    },
    stepIcon: {
      position: 'relative',
      zIndex: 2,
      width: ({ isTablet }) => isTablet ? 47 : 55,
      height: ({ isTablet }) => isTablet ? 47 : 55,
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: configTheme.colors.background.light.default,
      border: `1px solid ${configTheme.colors.basic.primary}`,
      borderRadius: '50%',
      '& svg': {
        width: 'auto',
        height: 'auto',
        transform: ({ isTablet }) => isTablet ? 'scale(0.9)' : 'scale(1)'
      }
    },
    stepTitle: {
      marginTop: 10,
      fontSize: ({ isTablet }) => isTablet ? 12 : 14,
      textAlign: 'center',
      color: configTheme.colors.text.dark.default
    }
  })
);
