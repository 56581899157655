import { createStyles, Theme, makeStyles } from '@material-ui/core';

import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

export const stylesProgramDay = makeStyles<unknown, { isMobile?: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: configTheme.colors.background.light.default,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: ({ isMobile }) => isMobile ? 0 : 10
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    title: {
      fontSize: ({ isMobile }) => isMobile ? 16 : 24
    },
    listItemActive: {
      fontSize: ({ isMobile }) => isMobile ? 14 : 19,
      fontFamily: configTheme.fontFamily.PFBeauSansProRegular,
      color: configTheme.colors.basic.primary
    },
    listItemDashed: {
      width: 'fit-content',
      fontSize: ({ isMobile }) => isMobile ? 14 : 19,
      fontFamily: configTheme.fontFamily.PFBeauSansProRegular,
      color: configTheme.colors.basic.primary,
      borderBottom: `1px dashed ${ configTheme.colors.basic.primary }`
    },
    listItem: {
      padding: '0px!important'
    },
    nested: {
      paddingLeft: theme.spacing(4)
    },
    block: {
      marginTop: ({ isMobile }) => isMobile ? '25px' : '30px',
      width: '100%',
      padding: ({ isMobile }) => isMobile ? '5px 15px 15px 15px' : '20px 40px 40px 40px',
      border: `1px solid ${ configTheme.colors.border.primary.light }`,
      borderRadius: 5,
      boxSizing: 'border-box',
      boxShadow: 'none'
    },
    iconMenu: {
      width: ({ isMobile }) => isMobile ? 25 : 35,
      height: ({ isMobile }) => isMobile ? 25 : 35,
      marginRight: ({ isMobile }) => isMobile ? 10 : 15
    },
    displayDescription: {
      padding: ({ isMobile }) => isMobile ? '0 5px' : 'none',
      fontFamily: configTheme.fontFamily.PFBeauSansProRegular,
      fontSize: ({ isMobile }) => isMobile ? 14 : 19,
      lineHeight: ({ isMobile }) => isMobile ? '24px' : '30px',
      color: configTheme.colors.text.dark.default,
      marginBottom: ({ isMobile }) => isMobile ? 10 : 0,
      '& strong': {
        fontFamily: configTheme.fontFamily.PFBeauSansProRegular
      }
    },
    pictureContainer: {
      position: 'relative',
      margin: ({ isMobile }) => isMobile ? '5px auto 0 auto' : '20px auto 0 auto'
    },
    picture: {
      width: '100%',
      marginBottom: '0!important',
      borderRadius: 5
    },
    videoIcon: {
      display: 'inline-block',
      position: 'absolute',
      height: ({ isMobile }) => isMobile ? 59 : 80,
      width: ({ isMobile }) => isMobile ? 59 : 80,
      borderRadius: 50,
      color: configTheme.colors.text.dark.default,
      zIndex: 9,
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    toggleText: {
      margin: '0 auto',
      fontSize: ({ isMobile }) => isMobile ? 14 : 19,
      cursor: 'pointer',
      color: configTheme.colors.basic.primary,
      borderBottom: `1px dashed ${ configTheme.colors.basic.primary }`,
      width: 'max-content'
    },
    toggleBlock: {
      marginBottom: 20
    }
  })
);
