import React, { useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';

import AppRoutes from 'app/routes/root.routes';
import Modals from './modals/modals.index';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';

import 'a1s-zet-react-ui/src/assets/font.styles.css';
import 'app/assets/global.styles.css';
import 'no-darkreader';

interface IAppProps {
  readonly containerUser: IUserContainerProps;
}

const App: FC <IAppProps> = ({ containerUser }) => {
  const { t } = useTranslation();
  const { actions: userActions } = containerUser;

  useEffect(() => {
    userActions.checkUser();
    document.title = t('common.titleApp');
  }, []);

  return (
    <>
      <Modals />
      <AppRoutes />
    </>
  );
};

export default ContainerUser(App);
