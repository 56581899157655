//#region imports
import React, { FC, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Grid } from '@material-ui/core';
import cn from 'classnames';
import flow from 'lodash/flow';

import Typography from 'a1s-omobile-react-ui/src/components/theming/typography/typography.index';
import Button from 'a1s-omobile-react-ui/src/components/shared/button/button.index';
import Wrapper from 'a1s-omobile-react-ui/src/components/utility/wrapper/wrapper.index';
import HeadTitle from 'a1s-omobile-react-ui/src/components/layout/headTitle/head.title.index';
import Header from 'a1s-omobile-react-ui/src/components/layout/header/header.index';
import CardSubscriptionDesktop from 'a1s-omobile-react-ui/src/components/desktop/card/subscription/card.subscription.desktop.index';
import CardSubscriptionMobile from 'a1s-omobile-react-ui/src/components/mobile/card/subscription/card.subscription.mobile.index';

import helperStyles from 'app/configs/theme/helper.styles';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';
import { ContainerPrize, IContainerPrizeProps } from 'app/containers/container.prize';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { ModalsContainer, IModalsContainer } from 'app/containers/modals.container';
import { ContainerLogging, TContainerLoggingProps } from 'app/containers/container.logging';
import { ELoadStatus, IRouteProps } from 'app/models/shared.model';

import CircularLoader from 'app/components/loader/loader.circular';
import Parameters from 'app/pages/shared/diet/parameters/parameters.index';
import useBreakpoints from 'app/hooks/use.breakpoints';

import stylesProfile from './profile.styles';
import { SUBSCRIBE_STATUS } from 'app/configs/const';
//#endregion

export interface IProfileProps extends IRouteProps {
  containerUser: IUserContainerProps;
  containerPrize: IContainerPrizeProps;
  containerSubscribe: IContainerSubscribeProps;
  modalsContainer: IModalsContainer;
  containerLogging: TContainerLoggingProps;
}

const Profile: FC<IProfileProps> = ({ containerUser, containerPrize, containerSubscribe, containerLogging, modalsContainer }) => {
  const { actions: { unSubscribeModal, closeModal } } = modalsContainer;
  const { data: { authenticated }, actions: userActions, request: { status: userStatus } } = containerUser;
  const { actions: subscribeActions, subscriptions } = containerSubscribe;
  const { actions: { logSubscriptionChange } } = containerLogging;

  const { isMobile, isTablet, isNotDesktop } = useBreakpoints();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();

  const classesHelper = helperStyles();
  const classes = stylesProfile({ isMobile, isNotDesktop });

  useEffect(() => {
    if (userStatus.checkUser === ELoadStatus.ready || userStatus.confirm === ELoadStatus.ready) {
      authenticated ? containerPrize.actions.fetchPersonal() : history.push('/');
    }

    return () => {
      containerPrize.actions.resetPersonal();
    };
  }, [userStatus, authenticated]);

  const handleSubscribeCard = (isSubscribe, data) => {
    if (isSubscribe) {
      unSubscribeModal(data.id);
    } else {
      logSubscriptionChange(true, data.id, pathname);
      subscribeActions.setSubscription(true, data.id);
    }
  };

  const loader = (
    <div className={ classesHelper.suspendLoader }>
      <CircularLoader />
    </div>
  );

  const renderParameters = useMemo(() => (
    <Parameters
      classes={ {
        wrapper: classes.parametersWrapper,
        table: classes.parametersTable,
        actionButton: classes.parametersBtn
      } }
      isNoIcon
      isNoCard
      isNoParametersInfo
    />
  ), []);

  const renderSubscribeDesktopList = useMemo(() => (
    <Grid container>
      {
        subscriptions.map(({ name, price, description, status, id }) => (
          <Grid key={ id } item className={ classes.subscriptionContainer }>
            <CardSubscriptionDesktop
              className={ classes.subscriptionDesktop }
              toggleSubscribe={ () => handleSubscribeCard(status !== SUBSCRIBE_STATUS.NOT_ACTIVE, { id, name }) }
              title={ name }
              price={ price }
              desc={ description }
              isSubscribe={ status !== SUBSCRIBE_STATUS.NOT_ACTIVE }
            />
          </Grid>
        ))
      }
    </Grid>
  ), [subscriptions, isTablet]);

  const renderSubscribeMobileList = useMemo(() => (
    <>
      {
        subscriptions.map(({ name, price, description, status, id }) => (
          <CardSubscriptionMobile
            className={ classes.subscriptionMobile }
            key={ id }
            toggleSubscribe={ () => handleSubscribeCard(status !== SUBSCRIBE_STATUS.NOT_ACTIVE, { id, name }) }
            title={ name }
            price={ price }
            desc={ description }
            isSubscribe={ status !== SUBSCRIBE_STATUS.NOT_ACTIVE }
          />
        ))
      }
    </>
  ), [subscriptions]);

  const content = (
    <>
      <Wrapper>
      <section className={ classes.primarySection }>
        <Typography className={ classes.header }>
          { t('me.myData') }
        </Typography>
        <div className={ classes.data }>
          <span>{ t('me.number') }:&nbsp;</span>
          <b>{ containerUser?.data?.msisdn }</b>
        </div>
      </section>
        { !isMobile &&
          <Button
            className={ cn(classes.exitButton) }
            type="secondary"
            size={ isMobile ? 'xl' : 'sm' }
            mobile={ isMobile }
            onClick={ userActions.toggleModalLogout }
            ghost
          >
            Выйти
          </Button>
        }
      { renderParameters }
      </Wrapper>
    <Wrapper>
      <section className={ `${classes.subscriptions}` }>
        <Typography className={ classes.header }>
          { t('me.subscriptions') }
        </Typography>
        { isMobile ? renderSubscribeMobileList : renderSubscribeDesktopList }
      </section>
      { isMobile &&
        <div className={ classes.buttonContainer }>
          <Button
            className={ classes.exitButton }
            type="secondary"
            size={ isMobile ? 'xl' : 'sm' }
            mobile={ isMobile }
            onClick={ userActions.toggleModalLogout }
            ghost
          >
            Выйти
          </Button>
        </div>
      }
    </Wrapper>
    </>
  );

  const handleHeadTitle = () => {
    history.push('/');
  };

  return (
    <div className={ classes.root }>
      <HeadTitle callback={ handleHeadTitle }>
        <Header level={ 'h4' } fontWeight={ 400 }>{ t('common.profile') }</Header>
      </HeadTitle>
      { authenticated ? content : loader }
    </div>
  );
};

Profile.displayName = 'Profile';

export default flow([ContainerLogging, ContainerUser, ContainerPrize, ContainerSubscribe, ModalsContainer])(Profile);
