import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

export default makeStyles(theme =>
  createStyles({
    link: {
      color: configTheme.colors.text.light.default,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontFamily: configTheme.fontFamily.PFBeauSansProRegular,
      fontSize: 17,
      [theme.breakpoints.up(1190)]: {
        color: configTheme.colors.text.dark.default,
        padding: '10px 15px',
        height: 45,
        backgroundColor: configTheme.colors.background.light.default,
        borderRadius: 50,
        '& svg': {
          color: configTheme.colors.text.red.default
        }
      },
      '& span': {
        marginRight: 15,
        lineHeight: 1
      }
    },
    iconUser: {
      width: 28,
      height: 28
    }
  })
);
