//#region imports
import { useEffect } from 'react';
import { useLastLocation } from 'react-router-last-location';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router';

import { IRouteActions } from 'app/entities/route/route.actions';
//#endregion

const cookies = new Cookies();

interface IPrevRootProps {
  containerRouteActions: IRouteActions;
  isGames?: boolean;
}

const usePrevRoute = ({ containerRouteActions, isGames }: IPrevRootProps) => {
  const lastLocation = useLastLocation();
  const history = useHistory();
  const currentPath = history?.location?.pathname;

  useEffect(() => {
    if (currentPath !== lastLocation?.pathname) {
      if (lastLocation) {
        if (lastLocation.pathname.includes('section') && !isGames) {
          containerRouteActions.updateRoute(lastLocation.pathname);
          cookies.set(`contentListPrevRoute`, lastLocation.pathname, { path: '/' });
        } else if (lastLocation.pathname.includes('content/list') && !currentPath.includes('content/list')) {
          containerRouteActions.updateRoute(lastLocation.pathname);
        } else if (lastLocation.pathname.includes('diet/program') && currentPath.includes('content/VIDEO')) {
          containerRouteActions.updateRoute(lastLocation.pathname);
        } else if ((lastLocation.pathname.includes('content') && !lastLocation.pathname.includes('content/list') && !isGames)
        || (lastLocation.pathname.includes('content/list') && currentPath.includes('content/list') && !isGames)) {
          if (
            (cookies.get(`contentListPrevRoute`) !== undefined && cookies.get(`contentListPrevRoute`).includes('section'))
          ) {
            containerRouteActions.updateRoute(cookies.get(`contentListPrevRoute`));
          } else {
            containerRouteActions.updateRoute('/');
          }
        } else {
          containerRouteActions.updateRoute('/');
          cookies.set(`contentListPrevRoute`, '', { path: '/' });
        }
      } else containerRouteActions.updateRoute('/');
    }
  }, [lastLocation]);
};

export default usePrevRoute;
