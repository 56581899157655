//#region imports
import React, { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-use';

import { ContainerMenu, IContainerMenu } from 'app/containers/menu.container';
import MenuMobile from 'a1s-omobile-react-ui/src/components/mobile/menu/menu.mobile';
import MenuDesktop from 'a1s-omobile-react-ui/src/components/desktop/menu/menu.desktop';

import useBreakpoints from 'app/hooks/use.breakpoints';
import { useTranslation } from 'react-i18next';
import flow from 'lodash/fp/flow';

import LayoutBase from 'a1s-omobile-react-ui//src/components/layout/layout/layout.index';
import Logo from 'a1s-omobile-react-ui/src/components/shared/logo/logo.index';
import Footer from 'a1s-omobile-react-ui/src/components/layout/footer/footer.index';

import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';
import { ContainerContentItem, IContainerContentItemProps } from 'app/containers/container.content.item';
import { ContainerSectionItem, IContainerSectionItemProps } from 'app/containers/container.section.item';

import Toolbar from 'app/components/toolbar/toolbar';
import { getMenuItem } from 'app/utils/create.menu.helper';
import { stylesLayout } from 'app/layouts/layout.styles';
import { Logger } from 'app/entities/logging/logger';
//#endregion

interface ILayoutProps {
  containerMenu: IContainerMenu;
  containerUser: IUserContainerProps;
  containerContentItem: IContainerContentItemProps;
  containerSectionItem: IContainerSectionItemProps;
}

const Layout: FC<ILayoutProps> = ({ containerMenu, containerUser, children }) => {
  const { actions: menuActions, items: menuData } = containerMenu;
  const { actions: userActions, data: { authenticated } } = containerUser;

  const { pathname, hash } = useLocation();
  const { isTabletMenu, isDesktop } = useBreakpoints();
  const { t } = useTranslation();
  const { isNotDesktop } = useBreakpoints();
  const styles = stylesLayout({ isNotDesktop, isTabletMenu });

  const CurrentMenu = isTabletMenu ? MenuMobile : MenuDesktop;
  const menuItems = useMemo(() => menuData?.length > 0 && menuData.map(item => getMenuItem(item, pathname, hash)), [menuData, pathname, hash]);

  useEffect(() => { menuActions.itemsFetch(); }, []);

  const onLogin = () => {
    userActions.toggleModalLogin();
  };

  const renderMenu = useMemo(() => (
      <CurrentMenu
        className={ styles.menu }
        logo={
          <Logo
            description={ t('menu.logo') }
            fontSize={ isDesktop ? 21 : 17 }
            width={ isDesktop ? 152 : 135 }
          />
        }
        toolbar={ <Toolbar isAuthenticate={ authenticated } handleClick={ onLogin } /> }
        items={ menuItems }
        logoRedirectUrl={ '/' }
      />
  ), [menuItems, CurrentMenu, authenticated, isTabletMenu]);

  const renderFooter = useMemo(() =>
    <Footer className={ styles.footer }>
      <p>{ t('footer.company') }</p>
      <a href={ '/oferta.pdf' } target="_blank" className={ styles.link }>
        { t('footer.terms') }
      </a>
    </Footer>
  , []);

  return (
    <LayoutBase renderHeader={ renderMenu } renderFooter={ renderFooter }>
      <Logger canSend/>
      { children }
    </LayoutBase>
  );
};

export default flow([ContainerUser, ContainerMenu, ContainerContentItem, ContainerSectionItem])(Layout);
