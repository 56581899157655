import { createStyles, Theme, makeStyles } from '@material-ui/core';

import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

interface IProps {
  isMobile?: boolean;
  isError?: boolean;
}

export const stylesModalNotifications = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minWidth: 300,
      transform: 'translateZ(0)'
    },
    loading: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    paper: {
      width: ({ isMobile }) => isMobile ? 335 : 373,
      maxHeight: '100vh',
      backgroundColor: configTheme.colors.background.light.default,
      boxSizing: 'border-box',
      borderRadius: 5
    },
    container: {
      padding: ({ isMobile }) => isMobile ? '0 20px' : '0 30px'
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: ({ isMobile }) => isMobile ? '20px 20px 25px 20px' : '23px 30px 30px 30px'
    },
    title: {
      fontSize: 24,
      lineHeight: ({ isMobile }) => isMobile ? '17px' : '30px',
      fontFamily: configTheme.fontFamily.PFBeauSansProSemiBold,
      borderRadius: '5px 5px 0 0',
      color: configTheme.colors.basic.primary
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      color: configTheme.colors.text.dark.default
    },
    timeContainer: {
      width: 185,
      display: 'flex',
      justifyContent: 'space-between',
      margin: '15px 0',
      '& .MuiFormHelperText-root.Mui-error': {
        marginLeft: -60
      }
    },
    timeInput: {
      width: 48
    },
    timeLabel: {
      marginTop: 12
    },
    wrapperRadioGroup: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: ({ isMobile }) => isMobile ? '8px 0 30px 0' : '0 0 25px 0',
      '& .MuiTypography-body1': {
        fontFamily: 'inherit'
      }
    },
    radioTitle: {
      marginTop: 35
    },
    containerRadio: {
      position: 'relative',
      width: ({ isMobile }) => isMobile ? 140 : 155,
      height: 35,
      margin: 0,
      '& .MuiFormControlLabel-label': {
        position: 'absolute',
        left: 0,
        right: 0,
        fontSize: ({ isMobile }) => isMobile ? 14 : 16,
        letterSpacing: '-0.3px',
        textAlign: 'center',
        fontFamily: configTheme.fontFamily.PFBeauSansProRegular
      }
    },
    buttons: {
      margin: ({ isMobile }) => isMobile ? '30px 0 40px 0' : '30px 0 50px 0',
      fontSize: ({ isMobile }) => isMobile ? 16 : 17,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& button:nth-child(2n)': { marginTop: 20 }
    },
    notificationTime: {
      marginTop: 5,
      fontSize: ({ isMobile }) => isMobile ? 14 : 17,
      color: configTheme.colors.text.dark.neutral
    },
    actionsContainer: {
      display: 'flex',
      margin: ({ isMobile }) => isMobile ? '5px 0 15px 0' : '10px 0 30px 0'
    },
    actionsTextButton: {
      borderBottom: `1px dashed ${configTheme.colors.basic.primary}`
    },
    actionsItem: {
      marginRight: 25,
      display: 'flex',
      alignItems: 'center',
      fontSize: ({ isMobile }) => isMobile ? 14 : 17,
      color: configTheme.colors.basic.primary,
      cursor: 'pointer'
    },
    actionsIcon: {
      width: 15,
      height: 15,
      marginRight: 5
    },
    actionsIconBig: {
      width: 20,
      height: 20,
      marginRight: 5
    }
  })
);
