//#region imports
import React, { FC, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import flow from 'lodash/flow';
import map from 'lodash/map';

import CardVertical from 'a1s-omobile-react-ui/src/components/shared/card/vertical/card.vertical.index';
import HeadTitle from 'a1s-omobile-react-ui/src/components/layout/headTitle/head.title.index';
import Header from 'a1s-omobile-react-ui/src/components/layout/header/header.index';

import { CONTENT_LIST } from 'app/configs/route.names';
import { IRouteProps } from 'app/models/shared.model';
import { ISectionListContainerProps, SectionListContainer } from 'app/containers/section.list.container';
import { CategoryContainer, ICategoryContainerProps } from 'app/containers/category.container';
import { IModalsContainer, ModalsContainer } from 'app/containers/modals.container';
import Wrapper from 'app/components/wrapper/wrapper.index';
import { stylesCategoryPage } from './page.category.styles';
import useBreakpoints from 'app/hooks/use.breakpoints';
import FollowLink from 'app/components/follow-link/follow-link.index';
//#endregion

export interface IDesktopCategoryPageProps extends IRouteProps {
  containerCategory: ICategoryContainerProps;
  sectionListContainer: ISectionListContainerProps;
  modalsContainer: IModalsContainer;
}

const DesktopCategoryPage: FC<IDesktopCategoryPageProps> = props => {
  const {
    containerCategory,
    sectionListContainer
  } = props;

  const history = useHistory();
  const { isNotDesktop, isMobile } = useBreakpoints();
  const styles = stylesCategoryPage({ isNotDesktop, isMobile });

  const [categoryId, setCategoryId] = useState<ICategoryContainerProps['item']['id']>();

  const category = containerCategory?.item;
  const sectionList = sectionListContainer?.list;

  useEffect(() => {
    return () => {
      containerCategory.actions.resetCategoryItem();
      sectionListContainer.actions.resetSectionItems();
    };
  }, []);

  useEffect(() => {
    if (props.computedMatch.params) {
      setCategoryId(Number(props.computedMatch.params.categoryId));
    }
  }, [props.computedMatch.params]);

  useEffect(() => {
    props.modalsContainer.actions.closeModal();
  }, [history.location]);

  useEffect(() => {
    if (categoryId) {
      containerCategory.actions.fetchCategoryItem(categoryId);
    }
    return () => {
      sectionListContainer.actions.resetSectionItems();
    };
  }, [categoryId]);

  useEffect(() => {
    if (category?.subcategories?.length) {
      sectionListContainer.actions.fetchAllSectionItems({
        allId: category.subcategories?.map(el => el.id),
        pager: { limit: 100, offset: 0 }
      });
    }
  }, [category]);

  const handleHeadTitle = () => {
    history.push('/');
  };

  const handleCard = useCallback(id => {
    history.push(CONTENT_LIST(categoryId, id));
  }, []);

  return (
    <div>
      <HeadTitle callback={ handleHeadTitle }>
        <Header level={ 'h4' } fontWeight={ 400 }>{ category.name }</Header>
      </HeadTitle>
      <Wrapper className={ styles.root }>
        <div className={ styles.container }>
          { sectionList[0]?.items.length ?
            map(sectionList[0]?.items, section => (
              <FollowLink
                key={ section?.id }
                anchor={ false }
                node={
                  <div className={ styles.cardItem }>
                    <CardVertical
                      size={ isMobile ? 'xs' : 'md' }
                      align="left"
                      likes={ { visible: false } }
                      badge={ { visible: false } }
                      button={ { visible: false } }
                      src={ section?.preview }
                      title={ section?.name }
                      onClick={ e => handleCard(section?.id) }
                    />
                  </div>
                }
                to={ CONTENT_LIST(categoryId, section.id) }
              />
            )) : null }
        </div>
      </Wrapper>
    </div>
  );
};
DesktopCategoryPage.displayName = 'DesktopCategoryPage';

export default flow([
  CategoryContainer,
  SectionListContainer,
  ModalsContainer
])(DesktopCategoryPage);
