//#region imports
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';
//#endregion

interface IProps {
  isNotDesktop: boolean;
}

export const stylesHeadline = makeStyles<Theme, IProps>(theme =>
  createStyles({
    container: {
      marginBottom: ({ isNotDesktop }) => isNotDesktop ? 0 : 12
    },
    header: {
      fontSize: 24
    },
    title: {
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 14 : 17,
      color: configTheme.colors.text.dark.default,
      borderBottom: `1px dashed ${configTheme.colors.text.dark.default}`,
      textDecoration: 'none',
      cursor: 'pointer'
    }
  }),
  { name: 'Headline' }
);
