import { makeStyles } from '@material-ui/core/styles';

import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

interface IProps {
  isFirst?: boolean;
  isNotDesktop?: boolean;
}

export const useStylesRadiosCheck = makeStyles<{}, IProps>({
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiTypography-body1': {
      fontFamily: 'inherit'
    }
  },
  icon: {
    width: 155,
    height: 35,
    backgroundColor: configTheme.colors.background.light.default,
    border: `1px solid ${ configTheme.colors.basic.primary }`,
    borderRadius: ({ isFirst }) => isFirst ? '50px 0 0 50px' : '0 50px 50px 0',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    }
  },
  checkedIcon: {
    backgroundColor: configTheme.colors.basic.primary,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      content: '""'
    }
  }
});
