import { makeStyles, createStyles } from '@material-ui/core';
import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

interface IProps {
  isMobile?: boolean;
  isNotDesktop?: boolean;
}

export const stylesDesktopDiet = makeStyles<{}, IProps>(
  createStyles({
    root: {
      margin: '0 auto',
      padding: ({ isMobile }) => isMobile ? '30px 0 45px 0' : '55px 0 0 0'
    },
    title: {
      margin: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? '20px 0px' : '25px 30px') : '30px 50px',
      fontSize: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 16 : 19) : 24,
      lineHeight: ({ isNotDesktop }) => isNotDesktop ? '23px' : '30px',
      color: configTheme.colors.text.dark.default,
      fontFamily: configTheme.fontFamily.PFBeauSansProRegular
    },
    banner: {
      '& img': {
        width: '100%',
        height: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 227 : 262) : 416,
        objectFit: 'cover',
        borderRadius: 4
      }
    },
    btn: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      marginBottom: ({ isMobile }) => isMobile ? 0 : 50
    },
    steps: {
      margin: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 0 : '0 30px') : '0 50px',
      listStyleType: 'none',
      paddingInlineStart: 0
    },
    step: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: ({ isNotDesktop, isMobile }) => isNotDesktop ? 20 : 32
    },
    stepIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: ({ isMobile }) => isMobile ? 47 : 55,
      height: ({ isMobile }) => isMobile ? 47 : 55,
      backgroundColor: configTheme.colors.background.light.default,
      border: `1px solid ${configTheme.colors.basic.primary}`,
      borderRadius: '50%',
      '& svg': {
        width: 'auto',
        height: 'auto'
      }
    },
    stepNumber: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 45,
      height: 43,
      paddingTop: 2,
      borderRadius: '50%',
      backgroundColor: configTheme.colors.basic.primary,
      color: configTheme.colors.text.light.default,
      fontSize: 24,
      lineHeight: 30
    },
    stepTitle: {
      marginLeft: ({ isNotDesktop }) => isNotDesktop ? 20 : 25,
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 16 : 19,
      color: configTheme.colors.text.dark.default
    }
  })
);
