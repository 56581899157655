//#region imports
import React from 'react';
import isFunction from 'lodash/isFunction';
import flow from 'lodash/fp/flow';
import { useLocation } from 'react-router';

import { useTranslation } from 'react-i18next';
import { ModalsContainer, IModalsContainer } from 'app/containers/modals.container';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';

import { ModalSubscribe } from 'app/components/modal.new/subscribe/modal.subscribe.index';
import { ModalNotification } from 'app/components/modal.new/notification/modal.notification.index';
import { ModalConfirm } from 'app/components/modal.new/confirm/modal.confirm.index';

import { ModalTypes } from 'app/entities/modals/modals.reducer';

import { ESubscribeStatus } from 'app/models/model.subscription';
import { EDialogConfirmType } from 'app/models/enums';
import { ContainerLogging, TContainerLoggingProps } from 'app/containers/container.logging';
//#endregion

export interface IModalsProps {
  mobile?: boolean;
  modalsContainer?: IModalsContainer;
  containerSubscribe?: IContainerSubscribeProps;
  containerUser?: IUserContainerProps;
  containerLogging?: TContainerLoggingProps;
}

const Modals = (props: IModalsProps) => {
  const { actions, payload, show, type } = props.modalsContainer;
  const { subscriptions, actions: { setSubscription, resetProp }, status, error } = props.containerSubscribe;
  const { data: { msisdn } } = props.containerUser;
  const { actions: { logSubscriptionChange } } = props.containerLogging;

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { subscribeId, handleClose, handleCancel } = payload ?? {};
  const currentSubscription = subscribeId && subscriptions?.length && subscriptions.find(s => s.id === subscribeId);

  const handleModalCancel = () => {
    if (isFunction(handleCancel)) handleCancel();
    actions.closeModal();
  };

  const handleModalSuccess = () => {
    if (isFunction(handleClose)) handleClose();
    actions.closeModal();
  };

  const onActivateSubscribe = () => {
    logSubscriptionChange(true, currentSubscription.id, pathname);
    setSubscription(true, currentSubscription.id);
    handleModalSuccess();
    actions.successActivateModal(currentSubscription.id);
  };

  const onDeactivateSubscribe = () => {
    logSubscriptionChange(false, currentSubscription.id, pathname);
    setSubscription(false, currentSubscription.id);
    handleModalSuccess();
  };

  const modalUnsubscribeButtons = {
    cancel: { onClick: handleModalCancel, title: t('dialog.confirm.unsubscribe.close') },
    confirm: { onClick: onDeactivateSubscribe, title: t('dialog.confirm.unsubscribe.deactivate') }
  };

  return (
    <>
      <ModalSubscribe
        open={ (error.change !== ESubscribeStatus.SUCCESS) && type === ModalTypes.subscribe && show }
        onClose={ handleModalCancel }
        titleHeader={ currentSubscription?.name }
        phone={ msisdn }
        subscribeCondition={ currentSubscription?.subscribeCondition }
        button={ {
          cancel: {
            onClick: handleModalCancel
          },
          subscribe: {
            onClick: onActivateSubscribe
          }
        } }
      />
      <ModalNotification
        type={ ESubscribeStatus.NO_MONEY }
        open={ type === ModalTypes.no_money && show }
        onClose={ handleModalCancel }
        button={ {
          cancel: { onClick: handleModalCancel }
        } }
      />
      <ModalNotification
        type={ ESubscribeStatus.FAIL }
        open={ error.change === ESubscribeStatus.FAIL }
        onClose={ () => resetProp('change') }
        button={ {
          cancel: { onClick: handleModalCancel }
        } }
      />
      <ModalConfirm
        open={ show && type === ModalTypes.unsubscribe }
        titleHeader={ t('dialog.confirm.unsubscribe.header') }
        name={ currentSubscription?.name }
        button={ modalUnsubscribeButtons }
        confirmType={ EDialogConfirmType.UNSUBSCRIBE }
        onClose={ handleModalCancel }
      />
    </>
  );
};

export default flow([ContainerUser, ContainerSubscribe, ModalsContainer, ContainerLogging])(Modals);
