//#region imports
import React, { FC, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, useForkRef } from '@material-ui/core';
import classnames from 'classnames';

import isFunction from 'lodash/isFunction';

import { IContainerContentListProps, ContainerContentList } from 'app/containers/container.content.list';
import { ImageFallback, EPostcardClassKey, EPostcardLayout } from 'a1s-zet-react-ui/src/components/shared/postcard/postcard.models';
import { Postcard } from 'a1s-zet-react-ui/src/components/shared/postcard/postcard.index';
import stylesEllipsis, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';
import { IconText } from 'a1s-zet-react-ui/src/components/shared/icontext/icontext.index';
import { Likes } from 'a1s-zet-react-ui/src/components/shared/likes/likes.index';
import { IconFree } from 'a1s-zet-react-ui/src/components/shared/icons/free/icon.free';
import { IconAvailable } from 'a1s-zet-react-ui/src/components/shared/icons/available/icon.available';
import { IconPaid } from 'a1s-zet-react-ui/src/components/shared/icons/paid/icon.paid';
import { Button } from 'a1s-zet-react-ui/src/components/shared/button/button.index';

import { EContentCardClassKey, ContentCardProps } from './content.card.models';
import { useContentCardStyles } from './content.card.styles';
import { ESubscriptionStatus } from 'app/models/model.subscription';
import { useTranslation } from 'react-i18next';
import { ContainerLike, IContainerLikeProps } from 'app/containers/like.container';
import { loginExternal } from 'app/utils/auth.external';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

type ContentCardAndLikeProps = ContentCardProps & {
  containerContentList: IContainerContentListProps;
  containerLike: IContainerLikeProps;
  free: boolean;
  itemCardId: string;
  contentItemId?: string;
  authStatus: boolean;
  liked: boolean;
  subscribeStatus: ESubscriptionStatus;
  withIndicator: boolean;
  handleClickLike: (contentId: string, liked) => void;
  withButton: boolean;
  isLikesDisabled?: boolean;
};

const ContentCard: FC<ContentCardAndLikeProps> = forwardRef<HTMLDivElement, ContentCardAndLikeProps>((props, ref) => {
  const {
    className,
    classes,
    style,
    origin,
    layout,
    preview,
    extraMiddle,
    extraTop,
    title,
    titleClamp,
    descr,
    free,
    subscribeStatus,
    itemCardId,
    contentItemId,
    liked,
    handleClickLike,
    containerLike,
    authStatus,
    onClick,
    onButtonClick,
    onPictureClick,
    onTitleClick,
    withIndicator,
    withButton,
    isLikesDisabled
  } = props;
  const isTitleClickable = isFunction(onTitleClick);
  const isPictureClickable = isFunction(onPictureClick);
  const isAllClickable = isFunction(onClick);

  const { t } = useTranslation();
  const { isNotDesktop } = useBreakpoints();
  const ellipsisStyles = stylesEllipsis({ lineClamp: titleClamp });
  const styles = useContentCardStyles({ classes, origin, mobile: isNotDesktop, isTitleClickable, isPictureClickable, isAllClickable });
  const [isAddLike, setAddLike] = useState(null);

  const history = useHistory();
  const rootRef = useRef(null);
  const handleRef = useForkRef(rootRef, ref);

  const indicator = useMemo(() => {
    if (!free) {
      if ([ESubscriptionStatus.ACTIVE, ESubscriptionStatus.NO_MONEY].includes(subscribeStatus)) {
        return (
          <IconText
            align="left"
            color="default"
            iconComponent={ IconAvailable }
            iconOnly={ false }
            size={ isNotDesktop ? 'small' : 'default' }
            text={ t('content.subscribed') }
          />
        );
      } else {
        return (
          <IconText
            align="left"
            color="default"
            iconComponent={ IconPaid }
            iconOnly={ false }
            size={ isNotDesktop ? 'small' : 'default' }
            text={ t('content.bySubscription') }
          />
        );
      }
    } else {
      return (
        <>
          <IconText text={ t('content.free') } iconComponent={ IconFree } size={ isNotDesktop ? 'small' : 'default' } />
        </>
      );
    }
  }, [free, subscribeStatus, isNotDesktop]);

  return (
    <div ref={ handleRef } className={ classnames(className, styles[EContentCardClassKey.root]) } style={ style }>
      <Postcard
        classes={ {
          [EPostcardClassKey.root]: styles[EContentCardClassKey.inner],
          [EPostcardClassKey.descr]: styles[EContentCardClassKey.descr],
          [EPostcardClassKey.picture]: styles[EContentCardClassKey.picture],
          [EPostcardClassKey.pictureFigure]: styles[EContentCardClassKey.pictureFigure]
        } }
        layout={ layout }
        width={ preview?.width ?? 251 }
        height={ preview?.height ?? 251 }
        src={ preview?.src }
        fallback={ ImageFallback }
        extraMiddle={ extraMiddle }
        extraTop={ extraTop }
        onPictureClick={ onPictureClick }
      >
        <div>
          <div
            className={ classnames(styles[EContentCardClassKey.title], {
              [ellipsisStyles[EEllipsisClassKey.line]]: !titleClamp || titleClamp <= 1,
              [ellipsisStyles[EEllipsisClassKey.multiline]]: titleClamp && titleClamp > 1
            }) }
            onClick={ onTitleClick }
          >
            { title }
          </div>

          { descr }
        </div>
        { withButton && (
          <Button type="primary" size={ isNotDesktop ? 'sm' : 'xl' } block className={ styles.button } onClick={ onButtonClick }>
            { t('common.read') }
          </Button>
        ) }
        { withIndicator && (
          <Grid container direction="row" justifyContent="space-between">
            <div className={ styles[EContentCardClassKey.freeBlock] }>{ indicator }</div>

            <div
              className={ `${styles[EContentCardClassKey.freeBlock]} ${isLikesDisabled ? styles.disabled : ''}` }
              onClick={ () => handleClickLike(contentItemId, liked) }
            >
              <Likes size={ isNotDesktop ? 'small' : 'default' } count={ Number(itemCardId) || 0 } declined={ !authStatus } liked={ liked } />
            </div>
          </Grid>
        ) }
      </Postcard>
    </div>
  );
});
ContentCard.displayName = 'ContentCard';

ContentCard.defaultProps = { layout: EPostcardLayout.vertical, origin: null, withIndicator: true } as ContentCardAndLikeProps;

export default ContainerContentList(ContainerLike(ContentCard));
