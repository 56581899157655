import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

interface IProps {
  isMobile?: boolean;
  isNotDesktop?: boolean;
}

export const stylesIndividualProgram = makeStyles<{}, IProps>((theme: Theme) =>
  createStyles({
    container: {
      padding: ({ isMobile }) => isMobile ? '35px 0' : '40px 0',
      minHeight: ({ isMobile, isNotDesktop }) =>
        isMobile ? 'calc(100vh - 307px)' : isNotDesktop ? 'calc(100vh - 317px)' : 'calc(100vh - 324px)'
    },
    parameters: {
      width: ({ isNotDesktop }) => isNotDesktop ? 'unset' : 653,
      margin: ({ isNotDesktop }) => isNotDesktop ? '25px auto 30px auto' : '40px auto 50px auto',
      color: configTheme.colors.text.dark.neutral,
      padding: ({ isMobile }) => isMobile ? 0 : 36,
      backgroundColor: configTheme.colors.background.light.default,
      border: `1px solid ${ configTheme.colors.border.primary.light }`,
      borderRadius: 5
    },
    descBlock: {
      width: '100%',
      padding: ({ isMobile }) => isMobile ? '20px 15px' : '36px 40px',
      border: `1px solid ${configTheme.colors.border.primary.light}`,
      borderRadius: 5,
      boxSizing: 'border-box',
      boxShadow: 'none',
      backgroundColor: configTheme.colors.background.light.default,
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 16 : 19,
      lineHeight: ({ isNotDesktop }) => isNotDesktop ? '20px' : '24px',
      color: configTheme.colors.text.dark.default
    }

  })
);
