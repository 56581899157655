//#region imports
import { FC, MutableRefObject, ReactElement } from 'react';
import { StandardProps } from '@material-ui/core';
//#endregion

export interface IVirtualFallbackProps {
  width: number;
  height: number;
  component?: Function & FC<IVirtualFallbackProps> & ReactElement;
}

export interface IVirtualItemProps {
  index: number;
  width?: number;
}

export interface IVirtualDotsProps {
  length: number;
  index: number;
  carousel?: boolean;
  setCurrentDot?: (i: number) => void;
}

export interface IVirtualListProps<T> {
  parentRef: MutableRefObject<T>;
  size: number;
  width?: number;
  height?: number;
  spacing?: number;
  overscan?: number;
  estimateWidth?: (index: number) => number;
  paddingStart?: number;
  paddingEnd?: number;
  fallbackHeight?: number;
  autoHeight?: boolean;
  infinite?: boolean;
  loading?: boolean;
  onLoad?: Function;
  renderDots?: Function & FC<IVirtualDotsProps>;
  renderItem?: Function & FC<IVirtualItemProps>;
  renderFallback?: Function & FC<IVirtualFallbackProps>;
}

export const EVirtualListClassKey = {
  root: 'root',
  item: 'item',
  container: 'container',
  dragged: 'dragged'
} as const;

// const assertions (TS 3.4+)
export type VirtualListClasses = typeof EVirtualListClassKey[keyof typeof EVirtualListClassKey];

export type VirtualListProps<T> = StandardProps<IVirtualListProps<T>, VirtualListClasses>;

export const EVirtualDotsClassKey = {
  root: 'root',
  dots: 'dots',
  dot: 'dot',
  dotActive: 'dot_active'
} as const;

// const assertions (TS 3.4+)
export type VirtualDotsClasses = typeof EVirtualDotsClassKey[keyof typeof EVirtualDotsClassKey];

export type VirtualDots = StandardProps<IVirtualDotsProps, VirtualDotsClasses>;

export interface IVirtualDotsStylesProps {
  mobile?: boolean;
  isEmpty?: boolean;

}
