import { START_PHONE_NUMBER } from 'app/configs/const';

export const getMsisdn = (value = ''): string => START_PHONE_NUMBER + value;
export const getCleanNumbers = (string = '') => string.replace(/[^0-9]/g, '');

export const getChangedPartMsisdn = (string = ''): string => {
  const changedPart = string.split(START_PHONE_NUMBER)[1];
  if (!changedPart) return '';
  return changedPart.replace(/[^0-9 ]/g, '');
};
