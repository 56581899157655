//#region imports
import React, { FC, memo, useEffect } from 'react';
import { useLastLocation } from 'react-router-last-location';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import toUpper from 'lodash/toUpper';
import flow from 'lodash/fp/flow';

import { ELoadStatus } from 'app/models/shared.model';
import { EContentType } from 'app/models/model.content';

import { ContainerSectionItem } from 'app/containers/container.section.item';
import { ContainerContentList } from 'app/containers/container.content.list';
import { ContainerContentItem } from 'app/containers/container.content.item';
import { ContainerUser } from 'app/containers/container.user';
import { ContainerSubscribe } from 'app/containers/container.subscribe';
import { ContainerLike } from 'app/containers/like.container';
import { ModalsContainer } from 'app/containers/modals.container';
import { ContainerGame } from 'app/containers/container.game';

import ContentItemArticle from './article/content.item.article.index';
import ContentItemStudy from './study/content.item.study.index';
import ContentItemBook from './book/content.item.book.index';

import { IDesktopContentItemPageProps } from './page.content.item.models';
import { stylesDesktopContentItemPage } from './page.content.item.styles';
import { CONTENT_ITEM, PROFILE } from 'app/configs/route.names';
import includes from 'lodash/includes';
import trimStart from 'lodash/trimStart';

import subscribeCheck from 'app/utils/util.subscribe.check';

import Cookies from 'universal-cookie';
import axios from 'axios';
import { ESubscriptionStatus } from 'app/models/model.subscription';
import { ContainerRoute } from 'app/containers/container.route';
import usePrevRoute from 'app/hooks/use.prev.route';

const cookies = new Cookies();
//#endregion

const DesktopContentItemPage: FC<IDesktopContentItemPageProps> = memo<IDesktopContentItemPageProps>(props => {
  const {
    containerUser,
    containerContentItem,
    containerSectionItem,
    containerSubscribe,
    containerContentList,
    containerLike,
    modalsContainer,
    containerGame,
    containerRoute
  } = props;

  const lastLocation = useLastLocation();
  const location = useLocation<any>();
  const history = useHistory();
  const params = useParams<any>();
  const styles = stylesDesktopContentItemPage({});

  const content = containerContentItem.item;
  const section = containerContentItem.section?.section;
  const subscribe = containerContentItem.section?.subscribe;
  const accessible = containerUser?.data?.authenticated;

  const contentId = params?.contentId || 0;
  const contentType = toUpper(content?.type) as EContentType;
  const distribution = containerContentItem.section?.distribution;
  const sectionId = section?.parentId ?? section?.id;
  const subsectionId = section?.parentId ? section?.id : undefined;
  const categoryId = containerContentItem.section?.subcategory?.categoryId ?? containerSectionItem.subcategory?.categoryId;
  const subcategoryId = containerContentItem.section?.subcategory?.id ?? containerSectionItem.subcategory?.id;
  const cookiePrevRoute = contentType && cookies.get(`contentItemPrevRoute${contentType}`);
  const { actions: containerRouteActions, route: containerRouteData } = containerRoute;

  usePrevRoute({ containerRouteActions });

  useEffect(() => {
    if (contentId) {
      containerContentItem.actions.fetchContentItem(contentId, { cancellable: true });
    }
    return () => {
      containerContentItem.actions.resetContentItem();
    };
  }, [contentId, containerSubscribe.status.change]);

  useEffect(() => {
    modalsContainer.actions.closeModal();
  }, [history.location]);

  useEffect(() => {
    if (lastLocation && toUpper(lastLocation?.pathname)?.includes(toUpper(PROFILE))) {
      props.containerContentItem.actions.updateRoteItem(PROFILE);
    } else if (
      lastLocation &&
      includes([EContentType.BOOK, EContentType.GAME_HTML5, EContentType.VIDEO], contentType) &&
      toUpper(lastLocation?.pathname)?.includes(EContentType.OLYMPIAD) &&
      lastLocation.search?.includes('passingTest=Y')
    ) {
      props.containerContentItem.actions.updateRoteItem(
        `${lastLocation.pathname}${lastLocation.search?.length > 0 ? '?' + trimStart(lastLocation.search, '?') : ''}`
      );
    } else if (props.location.search.includes('login=success') && cookiePrevRoute) {
      props.containerContentItem.actions.updateRoteItem(cookiePrevRoute);
      cookies.set(`contentItemPrevRoute${contentType}`, '', { path: '/' });
    } else if (lastLocation && (lastLocation.pathname === '/' || lastLocation.pathname.includes('content/list'))) {
      props.containerContentItem.actions.updateRoteItem(
        `${lastLocation.pathname}${lastLocation.search?.length > 0 ? '?' + trimStart(lastLocation.search, '?') : ''}`
      );
    }
  }, [history, lastLocation]);

  const loading = containerContentItem.status.item === ELoadStatus.loading;
  const ready = containerContentItem.status.item === ELoadStatus.ready;

  const onArticleLinkClick = async e => {
    e.preventDefault();
    if (e.target.tagName === 'A') {
      const arr = e.target.href.split('/');
      const id = arr[arr.length - 1];
      if (subscribe.status === ESubscriptionStatus.ACTIVE) {
        history.push(CONTENT_ITEM(EContentType.ARTICLE, id));
      } else {
        const payload = await axios.get(`/api/content/get?contentId=${id}`);
        if (payload?.data?.content?.free) {
          history.push(CONTENT_ITEM(EContentType.ARTICLE, id));
        } else {
          subscribeCheck({
            subscribe,
            callback: () => {
              history.push(CONTENT_ITEM(EContentType.ARTICLE, id));
            },
            history,
            containerUser,
            containerModals: modalsContainer
          });
        }
      }
    }
  };

  switch (contentType) {
    case EContentType.ARTICLE:
      return (
        <ContentItemArticle
          containerUser={ containerUser }
          containerContentList={ containerContentList }
          containerLike={ containerLike }
          location={ location }
          history={ history }
          subscribe={ subscribe }
          contentId={ contentId }
          sectionId={ sectionId }
          subsectionId={ subsectionId }
          content={ content }
          section={ section }
          contentType={ contentType }
          accessible={ accessible }
          loading={ loading }
          ready={ ready }
          onArticleLinkClick={ onArticleLinkClick }
          prevRoute={ containerRouteData }
        />
      );
    case EContentType.STUDY:
      return (
        <ContentItemStudy
          containerGame={ containerGame }
          containerUser={ containerUser }
          containerContentList={ containerContentList }
          location={ location }
          history={ history }
          subscribe={ subscribe }
          contentId={ contentId }
          sectionId={ sectionId }
          subsectionId={ subsectionId }
          content={ content }
          section={ section }
          contentType={ contentType }
          accessible={ accessible }
          loading={ loading }
          ready={ ready }
          prevRoute={ containerRouteData }
        />
      );
    case EContentType.BOOK:
      return (
        <ContentItemBook
          containerUser={ containerUser }
          containerContentList={ containerContentList }
          location={ location }
          history={ history }
          subscribe={ subscribe }
          contentId={ contentId }
          sectionId={ sectionId }
          subsectionId={ subsectionId }
          content={ content }
          section={ section }
          contentType={ contentType }
          accessible={ accessible }
          loading={ loading }
          ready={ ready }
          prevRoute={ containerRouteData }
        />
      );
    default:
      return <div className={ styles.root } />;
  }
});
DesktopContentItemPage.displayName = 'DesktopContentItemPage';

export default flow([
  ModalsContainer,
  ContainerUser,
  ContainerContentItem,
  ContainerSectionItem,
  ContainerSubscribe,
  ContainerContentList,
  ContainerLike,
  ContainerGame,
  ContainerRoute
])(DesktopContentItemPage);
