//#region imports
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core';
import { useToggle } from 'react-use';

import truncate from 'lodash/truncate';

import IconBasket from 'a1s-omobile-react-ui/src/components/shared/icon/basket/icon.basket';
import { configTheme } from 'a1s-omobile-react-ui/src/config/config.theme';

import { Typography } from 'app/components/desktop/typography/typography.index';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

interface IProductsList {
  foodBasketText?: string;
  replacementText?: string;
}

export const stylesProductsList = makeStyles<unknown, { isMobile?: boolean, isNotDesktop?: boolean }>(
  createStyles({
    wrapper: {
      padding: ({ isMobile }) => isMobile ? '25px 0' : '30px 0',
      minHeight: ({ isMobile }) => isMobile ? 'calc(100vh - 275px)' : 'calc(100vh - 327px)'
    },
    descBlock: {
      width: '100%',
      padding: ({ isMobile }) => isMobile ? '20px 15px' : '36px 40px',
      border: `1px solid ${ configTheme.colors.border.primary.light }`,
      borderRadius: 5,
      boxSizing: 'border-box',
      boxShadow: 'none',
      backgroundColor: configTheme.colors.background.light.default,
      '& p': {
        fontFamily: configTheme.fontFamily.PFBeauSansProRegular,
        fontSize: ({ isNotDesktop }) => isNotDesktop ? 16 : 19,
        lineHeight: ({ isNotDesktop }) => isNotDesktop ? '20px' : '24px',
        color: configTheme.colors.text.dark.default
      },
      '& strong': {
        fontFamily: configTheme.fontFamily.PFBeauSansProSemiBold,
        color: configTheme.colors.text.dark.default
      }
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: 20
    },
    title: {
      fontSize: ({ isMobile }) => isMobile ? 16 : 24,
      color: configTheme.colors.text.dark.default
    },
    icon: {
      width: ({ isMobile }) => isMobile ? 25 : 35,
      height: ({ isMobile }) => isMobile ? 35 : 35,
      marginRight: ({ isMobile }) => isMobile ? 15 : 20
    },
    foodBasketBlock: {
      width: ({ isNotDesktop }) => isNotDesktop ? 'unset' : 580,
      margin: ({ isNotDesktop }) => isNotDesktop ? '25px auto 30px auto' : '40px auto 50px auto' ,
      color: configTheme.colors.text.dark.default,
      padding: ({ isMobile }) => isMobile ? 20 : 36,
      backgroundColor: configTheme.colors.background.light.default,
      border: `1px solid ${ configTheme.colors.border.primary.light }`,
      borderRadius: 5,
      '& table': {
        width: '100%',
        borderCollapse: 'collapse',
        '& tr': {
          '& td': {
            fontSize: ({ isMobile, isNotDesktop }) => isMobile ? 14 : (isNotDesktop ? 16 : 19),
            padding: '7px 0',
            borderBottom: `1px solid ${ configTheme.colors.border.primary.light }`,
            '&:last-child': {
              textAlign: 'end'
            }
          }
        }
      }
    },
    replacementText: {
      maxWidth: 653,
      margin: '0 auto',
      padding: ({ isMobile }) => isMobile ? '30px 0 0 0' : '36px 0 0 0',
      height: 'auto',
      backgroundColor: configTheme.colors.background.light.default,
      color: configTheme.colors.text.dark.default,
      fontSize: ({ isMobile }) => isMobile ? 14 : 19,
      lineHeight: ({ isMobile }) => isMobile ? '19px' : '24px',
      '& h1': {
        fontFamily: configTheme.fontFamily.PFBeauSansProRegular,
        fontSize: ({ isMobile }) => isMobile ? 16 : 24,
        marginTop: 0,
        marginBottom: ({ isMobile }) => isMobile ? 18 : 28
      }
    },
    toggleText: {
      width: 'fit-content',
      margin: '0 auto',
      fontSize: ({ isMobile }) => isMobile ? 14 : 19,
      cursor: 'pointer',
      color: configTheme.colors.basic.primary,
      borderBottom: `1px dashed ${ configTheme.colors.basic.primary }`
    }
  }));

const ProductsList = memo<IProductsList>(props => {
  const { t } = useTranslation();
  const { foodBasketText, replacementText } = props;
  const [on, toggle] = useToggle(false);
  const { isMobile, isNotDesktop } = useBreakpoints();
  const styles = stylesProductsList({ isMobile, isNotDesktop });

  const returnTruncateText = useMemo(() => {
    const text = replacementText;
    if (!on) {
      return truncate(text, {
        length: isMobile ? 400 : 850,
        omission: '...'
      });
    } else return text;
  }, [replacementText, isMobile, on]);

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.descBlock }>
        <Typography type="text" tag="p">
          { t('diet.products.desc.1') }
        </Typography>
        <Typography type="text" tag="p">
          { t('diet.products.desc.2') }
        </Typography>
        <Typography type="text" tag="p">
          <span dangerouslySetInnerHTML={ { __html: t('diet.products.desc.3') } }/>
        </Typography>
      </div>
      <div className={ styles.foodBasketBlock }>
        <div className={ styles.titleContainer }>
          <IconBasket className={ styles.icon } />
          <div className={ styles.title }>
            { t('diet.products.title') }
          </div>
        </div>
        <div dangerouslySetInnerHTML={ { __html: foodBasketText } }/>
        <div dangerouslySetInnerHTML={ { __html: returnTruncateText } } className={ styles.replacementText }/>
        <p className={ styles.toggleText } onClick={ toggle }>{ on ? t('diet.products.collapse') : t('diet.products.expand') }</p>
      </div>
    </div>
  );
});
export default ProductsList;
