import React, { MouseEvent, FC, memo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import cx from 'classnames';

import { configTheme } from 'a1s-zet-react-ui/src/config/config.theme';
import { IAnswer } from 'app/entities/game/game.reducer';

//#region GameAnswer
export interface IGameAnswerProps extends IAnswer {
  className?: string;
  type?: 'button' | 'radio';
  disabled?: boolean;
  selected?: boolean;
  success?: boolean;
  failed?: boolean;
  children?: React.ReactNode;
  onSelect?(i: IAnswer['index'], e?: MouseEvent<HTMLButtonElement>): void;
}

export const useGameAnswerStyles = makeStyles(
  createStyles({
    root: {
      display: 'block',
      backgroundColor: `${configTheme.colors.background.white.default}`,
      boxShadow: `0px 2px 5px 1px ${configTheme.colors.shadow.translucent}`,
      marginBottom: `${configTheme.indents['indentX1.5'].margin}px`,
      padding: '18px 18px',
      borderRadius: 6,
      color: `${configTheme.colors.text.dark.default}`,
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.375,
      textAlign: 'left',
      '&:last-child': { marginBottom: 5 },
      '&:first-child': { marginTop: 36 }
    },
    success: {
      boxShadow: `0px 2px 5px 1px ${configTheme.colors.notification.valid.default}`
    },
    failed: {
      boxShadow: `0px 2px 5px 1px ${configTheme.colors.notification.invalid.default}`
    },
    selected: {},
    disabled: {},
    dot: {
      display: 'inline-block',
      boxSizing: 'border-box',
      width: 24,
      height: 24,
      marginRight: 18,
      border: `1.5px solid ${configTheme.colors.background.dark.neutral}`,
      borderRadius: '50%',
      verticalAlign: 'middle',

      '$selected &': { borderWidth: 8.5, borderColor: configTheme.colors.background.dark.default },
      '$disabled &': { backgroundColor: configTheme.colors.background.dashboard.neutral }
    }
  }),
  { name: 'GameAnswer' }
);

const GameAnswer: FC<IGameAnswerProps> = memo<IGameAnswerProps>(props => {
  const { className, type, index, text, image, disabled, selected, success, failed, children, onSelect } = props;
  const styles = useGameAnswerStyles();
  return (
    <ButtonBase
      className={ cx(className, { [styles.selected]: selected, [styles.success]: success && !failed, [styles.failed]: failed && !success }) }
      classes={ { root: styles.root, disabled: styles.disabled } }
      component="button"
      type="button"
      disabled={ disabled }
      onClick={ e => onSelect && onSelect(index, e) }
    >
      { type === 'radio' && <span className={ styles.dot } /> } { children ? children : text }
    </ButtonBase>
  );
});
GameAnswer.displayName = 'GameAnswer';

GameAnswer.defaultProps = { type: 'button' } as IGameAnswerProps;

export default GameAnswer;
//#endregion GameAnswer
